import { Injectable } from '@angular/core'
import { XHRService } from '../../../../shared/services/xhr.service'
import { ReviewPaginationSchema } from './review.model'
import { ReviewPagination, ReviewRequestParams } from './review.types'

@Injectable({
  providedIn: 'root',
})
export class ReviewService {

  constructor(
    private xhr: XHRService,
  ) { }

  async getReviews(businessId: string, params: ReviewRequestParams): Promise<ReviewPagination> {
    try {
      const response = await this.xhr.authRequest<ReviewPagination>({
        url: `bff/tow-destinations/aars/ratings/${businessId}/reviews`,
        params,
      },
        // TODO check CORS error
        // {overrideBaseURL: 'https://gatewayers-dev.national.aaa.com'}
      )
      await ReviewPaginationSchema.validate(response)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }

}
