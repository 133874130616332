import { Injectable } from '@angular/core'
import {
  selectMemberActiveVehicle,
  selectMemberVehicles,
} from '../../../../modules/member/member.selectors'
import { Store } from '@ngrx/store'
import { AAAStore } from '../../../../store/root-reducer'
import { distinctUntilChanged, take } from 'rxjs/operators'
import { isDeepCopy } from '../../../utils/isDeepCopy'
import {
  requestVehicleLoad,
  setEligibilityVehicle,
} from '../../../../modules/member/member.actions'
import { LibRequestService } from '../../lib-request.service'
import { LoadVehiclesParams } from './lib-vehicle.model'
import { Vehicle, VehicleData } from '../../../../modules/member/member.types'

@Injectable({
  providedIn: 'root',
})
export class LibVehicleService {
  vehicleList$ = this.store$
    .select(selectMemberVehicles)
    .pipe(distinctUntilChanged((prev, curr) => isDeepCopy(prev, curr)))

  constructor(
    private store$: Store<AAAStore>,
    private libRequestService: LibRequestService
  ) {}

  loadVehicles(params?: LoadVehiclesParams) {
    this.store$
      .select(selectMemberActiveVehicle)
      .pipe(take(1))
      .subscribe((vehicle) => {
        this.store$.dispatch(requestVehicleLoad())

        if (vehicle && params?.hasDirtyItems === true) {
          this.libRequestService.cancelPartialRequest()
        }
      })
  }

  selectVehicle(vehicle: Vehicle | VehicleData) {
    this.store$.dispatch(setEligibilityVehicle({ payload: vehicle }))
  }
}
