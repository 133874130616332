<ion-grid class="ion-margin">
  <ion-row class="ion-margin">
    <ion-col>
      <aaa-text i18n color="text" size="title" weight="bold">It seems we found a problem.</aaa-text>
    </ion-col>
  </ion-row>
  <ion-row class="ion-margin">
    <ion-col>
      <aaa-text i18n color="text" size="headline" weight="medium">Sorry, we can't display our Service Tracker on this page.</aaa-text>
    </ion-col>
  </ion-row>
  <ion-row class="ion-margin">
    <ion-col>
      <aaa-button (click)="navigateToExternalLink()" variant="primary" expand="block" class="ion-margin-vertical">
        <ng-container i18n>Go To Service Tracker</ng-container> <aaa-icon name="arrow-right"></aaa-icon>
      </aaa-button>
    </ion-col>
  </ion-row>
</ion-grid>
