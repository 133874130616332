import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { cancelEditingRequest, cancelRequest } from '../actions/shared.actions'
import { selectHasAvailableCall } from '../../modules/dashboard/calls-statuses/call-status.selectors'
import { AdobeEventTypes } from '../../modules/tagging/tagging.types'
import { AdobeEventService } from '../../modules/tagging/adobe/event-adobe.service'
import events from '../../modules/tagging/events'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class LibRequestService {
  hasAvailableCall$: Observable<boolean> = this.store$.pipe(
    select(selectHasAvailableCall)
  )

  constructor(
    private store$: Store,
    private adobeEventService: AdobeEventService
  ) {}

  cancelRequest() {
    this.hasAvailableCall$.pipe(take(1)).subscribe((hasAvailableCall) => {
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: hasAvailableCall
          ? events.dashboard.CANCEL_CALL_SERVICE_REQUEST
          : events.dashboard.PARTIAL_CALL_SERVICE_CANCELED,
      })

      this.store$.dispatch(cancelRequest())
    })
  }

  cancelPartialRequest() {
    this.hasAvailableCall$.pipe(take(1)).subscribe((hasAvailableCall) => {
      if (hasAvailableCall) return

      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.dashboard.PARTIAL_CALL_SERVICE_CANCELED,
      })
      this.store$.dispatch(cancelEditingRequest())
    })
  }
}
