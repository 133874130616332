import { Injectable } from '@angular/core'
import { DateTime } from 'luxon'
import { XHRService } from '../../shared/services/xhr.service'
import { AdvisoriesSchema } from './advisories.model'
import { Advisories } from './advisories.types'

@Injectable({
  providedIn: 'root',
})
export class AdvisoriesService {
  constructor(private xhr: XHRService) {}

  async getAdvisories(association: string, club: string, zipCode: string = '') {
    try {
      const response = await this.xhr.authRequest<Advisories>({
        url: `bff/club/${club}/advisories/${association}`,
        params: { zipCode }
      })

      await AdvisoriesSchema.validate(response)

      const advisories = response?.advisories || []

      return advisories.filter((advisory) => {
        if (advisory.terminationTime) {
          const now = DateTime.local()
          let advisoryTermination = DateTime.fromSQL(advisory.terminationTime)
          if (!advisoryTermination.isValid) {
            advisoryTermination = DateTime.fromISO(advisory.terminationTime)
          }

          return now < advisoryTermination
        } else {

          return true
        }
      })
    } catch (error) {
      throw error
    }
  }
}
