import { PayloadedAction } from '../../shared/types'
import { CALL_RESET, CALL_STATUS_ERROR, RESET_CALL_STATUS_ERROR, SET_TOWING, } from './calls.actions'
import { BreakdownLocation, TowLocation } from '../location/location.types'

export interface AAACallState {
  breakdownLocationAddress: string
  breakdownLocation: BreakdownLocation
  towing: TowLocation
  callWithError?: boolean
}

export const initialState: AAACallState = {
  breakdownLocationAddress: '',
  breakdownLocation: null,
  towing: null,
  callWithError: false,
}

export function reducer(
  state: AAACallState | undefined = initialState,
  action: PayloadedAction | any
): AAACallState {
  switch (action.type) {
    case CALL_RESET:
      return { ...initialState }
    case SET_TOWING:
      let towing = null
      if (action.payload) {
        towing = {
          ...action.payload,
          // simplify the location/name to present towing information
          name: action.payload.name || action.payload.location,
        }

        // TODO move to a selector.
        const streetNumber = towing.streetNumber
        if (towing.streetName && towing.streetName.indexOf(streetNumber) > -1) {
          towing.streetNumber = ''
        }
      }

      return {
        ...state,
        towing,
      }
    case CALL_STATUS_ERROR:
      return {
        ...state,
        callWithError: true,
      }
    case RESET_CALL_STATUS_ERROR:
      return {
        ...state,
        callWithError: false,
      }
    default:
      return state
  }
}
