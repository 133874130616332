import { Injectable } from '@angular/core'
import { DestinationType, IndexedCollection } from '../../../shared/types'
import {
  AARData,
  AarDetailsResponse,
  MAKES_FIELD,
  RATING_SUMMARY_FIELD,
  SERVICES_FIELD,
  TowDestinationResponse
} from './aar.types'
import { XHRService } from '../../../shared/services/xhr.service'
import { indexCollection } from '../../../shared/utils'
import { RapService } from '../../rap/rap.service'
import { EvStationService } from './evstation.service';
import { AARDataAdapter } from "../../../shared/adapters/AARDataAdapter";

@Injectable({
  providedIn: 'root',
})
export class AarService {

  constructor(
    private xhr: XHRService,
    private rapService: RapService,
    private evStationService: EvStationService,
    private adapter: AARDataAdapter,
  ) { }

  async getAARs({
    latitude,
    longitude,
    make,
    radius,
    limit,
    country,
    type,
  }: {
    latitude,
    longitude,
    make,
    radius?,
    limit?,
    country?,
    type?,
  }, destinationType: DestinationType): Promise<IndexedCollection<AARData>> {
    switch (destinationType) {
      case DestinationType.DEALER:
        return this.rapService.getAarsFromTowDestination({
          latitude,
          longitude,
          make,
          country,
          type
        })

      case DestinationType.EV_STATION:
        return this.evStationService.search(latitude, longitude, this.getEvSearchLimit(limit), radius)

      case DestinationType.AAR:
      default:
        return this.search(latitude, longitude, make, limit)

    }
  }

  private async search(latitude, longitude, make, limit) {
    try {
      const response = await this.xhr.authRequest<TowDestinationResponse<AarDetailsResponse>>({
        url: 'bff/tow-destinations/aars',
        params: {
          requireResult: true,
          latitude,
          longitude,
          make,
          fields: `${MAKES_FIELD},${SERVICES_FIELD},${RATING_SUMMARY_FIELD}`,
          ...(limit ? { limit } : {}),
        },
      })
      // TODO enable the validation below after Jan 15th and use only "results".
      // await TowDestinationResponseSchema.validate(response)
      const res = response.results || response.result;
      const aars = res.map((aar) => this.adapter.adaptTowDestination<AarDetailsResponse>(aar))
      return indexCollection<AARData, 'id'>(aars, 'id')
    } catch (error) {
      throw error
    }
  }

  private getEvSearchLimit(limit) {
    return this.rapService.isRapUser() ? this.rapService.getLimitWithinRadius() : limit
  }
}
