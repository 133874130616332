import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../../../store/root-reducer'
import { LocationUtils } from '../../../../location/location.utils'
import { TaggingService } from '../../../../tagging/tagging.service';
import { AdobeEventService } from '../../../../tagging/adobe/event-adobe.service'
import { GenericCoordinates } from '../../../../location/location.types'
import { AARData } from '../../../../location/aar/aar.types'
import { combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { selectIsEVstation } from '../../../../location/aar/aar.selectors'
import { selectRoutesDistance } from '../../../../route-distance/route-distance.selector'
import { RouteDistance } from '../../../../route-distance/route-distance.reducer'
import { CLOSED_LABEL } from '../../../../constants/shared.constants'
import { VendorConfigurationMode } from '../../../../auth/mode-configuration.types'
import { selectIsRapUser, selectMaxTowMileLimit, selectModeConfiguration } from '../../../../auth/auth.selectors'
import { selectDefaultPhoneNumber } from '../../../../servicing-club/servicing-club.selectors'
import events from '../../../../tagging/events'
import {
  RAP_EV_TOW_LIMITATION_FLEX,
  RAP_EV_TOW_LIMITATION_STRICT,
  RAP_TOW_LIMITATION_FLEX,
  RAP_TOW_LIMITATION_STRICT
} from '../../../../../shared/utils/messages'
import { areStringsEquivalent } from '../../../../../shared/utils/areStringsEquivalent'
import { AdobeEventTypes } from '../../../../tagging/tagging.types'
import { AbstractResponsiveComponent } from '../../../../../shared/abstract-responsive.component'
import { requestRouteDistance } from "../../../../route-distance/route-distance.actions";
import { DatetimeUtils } from "../../../../../shared/utils/datetime";

@Component({
  selector: 'app-shop-details-modal',
  templateUrl: './shop-details-modal.component.html',
  styleUrls: ['./shop-details-modal.component.scss'],
})
export class ShopDetailsModalComponent extends AbstractResponsiveComponent implements OnInit, OnChanges {
  @Output() onCloseModal: EventEmitter<void> = new EventEmitter()

  @Input() isOpen = true
  @Input() aarData: AARData
  @Input() breakdownCoordinates: GenericCoordinates

  ngOnChanges(changes: SimpleChanges) {
    if (changes['aarData']) {
      this.distanceTo = null;
      this.compressedHours = this.calculateHours(this.aarData)
      this.store$.dispatch(requestRouteDistance({
        payload: {
          origin: this.breakdownCoordinates,
          destination: {
            id: this.aarData.id,
            latitude: this.aarData.latitude,
            longitude: this.aarData.longitude
          }
        }
      }))
    }
  }

  configData$: Observable<VendorConfigurationMode> = this.store$.pipe(
    select(selectModeConfiguration)
  )

  routeDistance$: Observable<number | null> = combineLatest([
    this.store$.pipe(select(selectRoutesDistance)),
  ]).pipe(
    map(([routesDistance]: [RouteDistance[]]) => this.locationUtils.getDistance(
      routesDistance,
      this.breakdownCoordinates,
      this.aarData
    ))
  )

  isRapUser$: Observable<boolean> = this.store$.pipe(select(selectIsRapUser))

  compressedHours
  callCenterTowMileage
  distanceTo
  maxTowMileLimit
  flexTowLimitMessage: string
  strictTowLimitMessage: string

  isEvStation$: Observable<boolean> = this.store$.pipe(select(selectIsEVstation))
  contactPhone$ = this.store$.pipe(select(selectDefaultPhoneNumber))

  showReviews = false

  constructor(
    protected store$: Store<AAAStore>,
    protected locationUtils: LocationUtils,
    protected taggingService: TaggingService,
    protected adobeEventService: AdobeEventService,
    protected datetimeUtils: DatetimeUtils,
  ) {
    super()
  }

  ngOnInit() {
    this.subscriptions.push(
      this.isEvStation$.subscribe((isEvStation) => {
        this.flexTowLimitMessage = isEvStation ? RAP_EV_TOW_LIMITATION_FLEX() : RAP_TOW_LIMITATION_FLEX()
        this.strictTowLimitMessage = isEvStation ? RAP_EV_TOW_LIMITATION_STRICT() : RAP_TOW_LIMITATION_STRICT()
      }),
      this.store$.pipe(select(selectMaxTowMileLimit)).subscribe((maxTowMileLimit) => {
        this.maxTowMileLimit = maxTowMileLimit
      }),
      this.configData$.subscribe((configData) => {
        this.callCenterTowMileage = configData?.callCenterTowMileage
      }),
      this.routeDistance$.subscribe((distanceTo) => {
        this.distanceTo = distanceTo
      }),
    )
    this.taggingService.setPageLoadEvent({ pageType: events.towTo.PAGE_TYPE, pageName: events.towTo.PAGE_NAME_TOW_TO_DETAIL })
  }

  calculateHours(details: AARData) {
    if (!details.operatingDays) {
      return []
    }
    const days = [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
      'SUNDAY',
    ]
    let lastOpen = ''
    let lastClose = ''
    return days.reduce((output, day) => {
      const currentDay = details.operatingDays.find((operatingDay) =>
        areStringsEquivalent(operatingDay.day, day)
      )

      if (!currentDay) {
        lastOpen = ''
        lastClose = ''
        output.push({
          days: day,
          hours: CLOSED_LABEL(),
        })
      } else {
        lastOpen = this.datetimeUtils.normalizeTime(currentDay.open)
        lastClose = this.datetimeUtils.normalizeTime(currentDay.close)
        output.push({
          days: day,
          hours: `${lastOpen} - ${lastClose}`,
        })
      }

      return output
    }, [])
  }

  showReviewsList(show = true) {
    this.showReviews = show
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.towTo.DESTINATION_OPEN_FACILITY_REVIEWS
    })
  }

  handleCloseModal() {
    this.onCloseModal.emit()
  }

  shouldShowFlexTowLimit() {
    return this.distanceTo > this.maxTowMileLimit && this.distanceTo <= this.callCenterTowMileage
  }

  shouldShowStrictTowLimit() {
    return this.distanceTo > this.callCenterTowMileage
  }
}
