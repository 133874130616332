export const US_PHONE_NUMBER_MASK = [
  '(', /[2-9]/, /\d/, /\d/, ')', ' ',
  /\d/, /\d/, /\d/, '-',
  /\d/, /\d/, /\d/, /\d/
]
export const US_ZIP_CODE_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/]
export const CA_POSTAL_CODE_MASK = [/[A-Za-z]/, /\d/, /[A-Za-z]/, ' ', /\d/, /[A-Za-z]/, /\d/]
export const MEMBERSHIP_NUMBER_MASK = [
  /\d/, /\d/, /\d/, ' ',
  /\d/, /\d/, /\d/, ' ',
  /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ',
  /\d/
]
export const US_PHONE_NUMBER_COUNTRY_CODE_MASK = ['+', '1', ' '].concat(US_PHONE_NUMBER_MASK as any)
export const CREDIT_CARD_MASK = [
  ...Array(4).fill(/\d/),
  ' ',
  ...Array(4).fill(/\d/),
  ' ',
  ...Array(4).fill(/\d/),
  ' ',
  ...Array(4).fill(/\d/),
]
