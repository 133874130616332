import { Component, } from '@angular/core'
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CustomMarkerInfo } from '../map/map.component';
import {
  selectActiveBreakdownMarkerData,
  selectActiveCallStatus,
  selectCallTowLocation,
  selectDriverLocation
} from '../../dashboard/calls-statuses/call-status.selectors';
import { AAACallStatus, CALL_STATUS_CODES } from '../../dashboard/calls.types';
import { GoogleLocationMarker, TowLocation } from '../location.types';
import { getTowDestinationCoordinates } from '../tow-location/tow-location.actions';
import { GoogleCoordinates } from '../google-geocode/types';
import {
  DEFAULT_BREAKDOWN_MARKER,
  DEFAULT_TOW_MARKER,
  DEFAULT_TRUCK_MARKER,
  MarkerDetails,
  MarkerTypes
} from '../../ui/ui.types';
import { AAAStore } from '../../../store/root-reducer';

interface ViewModel {
  markers: CustomMarkerInfo[]
  breakdownLocation: GoogleLocationMarker
}

@Component({
  selector: 'app-map-service-tracking',
  templateUrl: './map-service-tracking.component.html',
})
export class MapServiceTrackingComponent {

  viewModel$: Observable<ViewModel> = combineLatest([
    this.store$.select(selectActiveCallStatus),
    this.store$.select(selectActiveBreakdownMarkerData),
    this.store$.select(selectCallTowLocation),
    this.store$.select(selectDriverLocation),
  ]).pipe(
    map(([call, breakdownLocation, towLocation, driverLocation]:
    [AAACallStatus, GoogleLocationMarker, TowLocation, GoogleCoordinates]) => {
      const markers: CustomMarkerInfo[] = [{
        id: 'breakdown-pin',
        location: breakdownLocation,
        markerDetails: {
          ...DEFAULT_BREAKDOWN_MARKER,
          type: MarkerTypes.CAR
        },
        tabIndex: 2,
      }]
      const driverMarker = this.getDriverMarker(call)
      if (driverLocation && driverMarker) {
        markers.push({
          id: 'driver-pin',
          location: driverLocation,
          markerDetails: driverMarker,
          tabIndex: 2,
        })
      }
      if (towLocation) {
        if (towLocation.address && (!towLocation.latitude || !towLocation.longitude)) {
          this.store$.dispatch(getTowDestinationCoordinates({
            payload: towLocation.address
          }))
        } else {
          markers.push({
            id: 'tow-pin',
            location: {
              lat: Number(towLocation.latitude),
              lng: Number(towLocation.longitude),
            },
            markerDetails: {
              ...DEFAULT_TOW_MARKER,
              type: MarkerTypes.CUSTOM
            },
            tabIndex: 2,
          })
        }
      }

      return {
        markers,
        breakdownLocation,
      }
    })
  )

  getDriverMarker = (call): MarkerDetails => {
    const callStatus = call?.callStatus || null
    switch (callStatus) {
      case CALL_STATUS_CODES.OL:
      case CALL_STATUS_CODES.OS:
      case CALL_STATUS_CODES.TW:
      case CALL_STATUS_CODES.ER:
        return {
          ...DEFAULT_TRUCK_MARKER,
          type: MarkerTypes.TOW_TRUCK
        }
      default:
        return null
    }
  }

  constructor(
    private store$: Store<AAAStore>,
  ) {}

}
