import { of, from } from 'rxjs'
import { Injectable } from '@angular/core'
import { GenericCoordinates } from '../../../location/location.types'

declare let google: any

export const RADIUS = 16090 // 10 miles

@Injectable({ providedIn: 'root' })
export class GooglePlacesService {
  search(term: string, location?: GenericCoordinates) {
    if (term === '') {
      return of([])
    }
    // when location and radius are passed google places will prioritize the results in the range
    // see https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
    let extraParams = {}
    if (location && location.latitude && location.longitude) {
      extraParams = {
        location: new google.maps.LatLng(location.latitude, location.longitude),
        radius: RADIUS,
      }
    }

    return from(
      new Promise((fulfill, reject) => {
        if (term.length <= 2) {
          fulfill([])
          return
        }
        const queryPredictService = new google.maps.places.AutocompleteService()
        queryPredictService.getPlacePredictions(
          {
            input: term,
            componentRestrictions: { country: ['us', 'ca', 'pr', 'vi'] },
            ...extraParams,
          },
          (predictions, status) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              reject(status)
              return
            }
            fulfill(
              predictions.map((pred) => ({
                description: pred.description,
                main_text: pred.structured_formatting.main_text,
                secondary_text: pred.structured_formatting.secondary_text,
                landmark:
                  pred?.types?.indexOf('point_of_interest') !== -1
                    ? pred?.structured_formatting?.main_text
                    : null,
              }))
            )
          }
        )
      })
    )
  }
}
