<aaa-modal contentMode="scroll"
  [padding]="false"
  [isOpen]="isModalOpen && !disabled"
  [initialBreakpoint]="1"
  (aaaModalDidDismiss)="setOpen(false)"
  [breakpoints]="[0, 1]">
  <ion-header>
    <ion-toolbar>
      <ion-title>Select Color</ion-title>
      <ion-buttons slot="end">
        <aaa-button (click)="setOpen(false)"
          class="close-button"
          size="small"
          variant="secondary">
          <aaa-icon name="close-outline"></aaa-icon>
        </aaa-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-top">
    <aaa-vehicle-color-list [color]="selectedColor"
      class="ion-padding-top"
      (selectedColor)="onSelectedColor($event)"
      [isLoading]="isLoading$ | async">
    </aaa-vehicle-color-list>

  </ion-content>
</aaa-modal>
@if (showInput) {
  <aaa-input label="Color"
    i18n-label
    [disabled]="disabled"
    [variant]="error ||
      (form$ | async)?.controls?.state?.isTouched &&
      (form$ | async)?.controls?.state?.errors?.required ? 'danger': 'secondary'"
    [value]="selectedColor"
    (click)="setOpen(true)">
  </aaa-input>
}
