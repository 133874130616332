<aaa-text
  display="flex"
  align="center"
  role="navigation"
  size="caption"
  weight="light"
  class="captcha-terms ion-margin-top"
>
  <aaa-text i18n>reCaptcha by Google.</aaa-text>
  <aria-link-button
    class="captcha-privacy-link"
    (clickLink)="openPrivacy()"
  >
    <aaa-text i18n color="primary"> Privacy</aaa-text>
  </aria-link-button>
  <aaa-text i18n> and </aaa-text>
  <aria-link-button
    class="captcha-terms-link"
    (clickLink)="openTerms()"
  >
    <aaa-text i18n color="primary">Terms</aaa-text>
  </aria-link-button>.
</aaa-text>
