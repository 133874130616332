import { Injectable } from '@angular/core'

import { AddVehicleResponse, MemberInfo, MemberVehicles, SearchMemberResult, Vehicle, VehicleData, } from './member.types'
import { MemberBasicSchema, MemberSchema, MemberVehiclesSchema, SearchMemberResultSchema, } from './members.model'
import { XHRService } from '../../shared/services/xhr.service'
import { BaseAuthRequestParams, NameAuthRequestParams, } from '../auth/auth.types'
import { RapService } from '../rap/rap.service'
import { v4 as uuidv4 } from 'uuid';
import { VehicleAdapter } from "../../shared/adapters/VehicleAdapter";

const MEMBER_RETRIES = 3
const MEMBER_RETRY_INTERVAL = 15000

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  constructor(
    private xhr: XHRService,
    private rapService: RapService,
    private vehicleAdapter: VehicleAdapter,
  ) {}

  async getInfo(): Promise<MemberInfo> {
    try {
      const response = await this.xhr.clubRequest<MemberInfo>(
        {
          url: 'v2/member',
          timeout: MEMBER_RETRY_INTERVAL
        },
        {
          retryAll: MEMBER_RETRIES
        })
      const validData = await MemberSchema.validate(response)

      return validData
    } catch (error) {
      throw error
    }
  }

  async getBasicInfo(): Promise<MemberInfo> {
    try {
      const response = await this.xhr.clubRequest<MemberInfo>(
        {
          url: 'v2/member',
          timeout: MEMBER_RETRY_INTERVAL
        },
        {
          retryAll: MEMBER_RETRIES
        })
      const validData = await MemberBasicSchema.validate(response)

      return validData
    } catch (error) {
      throw error
    }
  }

  async getVehicles(): Promise<MemberVehicles> {
    try {
      const response = await this.xhr.clubRequest<MemberVehicles>(
        'v2/member/profile/vehicles'
      )
      const validData = await MemberVehiclesSchema.validate(response)

      return validData
    } catch (error) {
      throw error
    }
  }

  async addVehicle(data: VehicleData): Promise<AddVehicleResponse> {
    if(this.rapService.isRapUser()) { // RAP's backend doesn't have capability to store a vehicle.
      return { id: uuidv4() }
    }

    try {
      const response = await this.xhr.clubRequest<AddVehicleResponse>({
        url: 'v2/member/profile/vehicles',
        method: 'POST',
        data: this.vehicleAdapter.adaptMemberVehicle(data),
      })
      return response
    } catch (error) {
      throw error
    }
  }

  async updateVehicle(data: Vehicle): Promise<void> {
    const response = await this.xhr.clubRequest<void>({
      url: `v2/member/profile/vehicles/${data.id}`,
      method: 'PUT',
      data: this.vehicleAdapter.adaptMemberVehicle(data),
    })
    return response
  }

  async deleteVehicle(id: string): Promise<void> {
    const response = await this.xhr.clubRequest<void>({
      url: `v2/member/profile/vehicles/${id}`,
      method: 'DELETE',
    })
    return response
  }

  async memberSearch({
    captchaVersion,
    captchaToken,
    firstName,
    lastName,
    zipCode,
  }: BaseAuthRequestParams & NameAuthRequestParams) {
    const response = await this.xhr.request<SearchMemberResult>({
      url: 'v2/member/search',
      data: {
        captchaVersion,
        captchaToken,
        firstName,
        lastName,
        zipCode,
      },
      method: 'POST',
    })
    await SearchMemberResultSchema.validate(response)
    return response
  }
}
