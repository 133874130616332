import { Injectable } from '@angular/core'
import { AdobeTargetProposition } from '../../modules/ui/ui.types';

@Injectable({
  providedIn: 'root',
})
export class WindowObject {

  getHost = () => window.location.host

  getHref = () => window.location.href

  navigateTo = (url: string) => window.location.href = url

  reload = () => window.location.reload();

  getWindow = () => window

  addWindowPropositions = (propositions: AdobeTargetProposition) => {
    window['aa'] = window['aa'] ?? {}
    window['aa']['drr'] = window['aa']['drr'] ?? {}
    window['aa']['drr']['propositions'] = [...(window['aa']['drr']['propositions'] ?? []), propositions]
  }

}
