import { Component, OnInit } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { AbstractComponent } from '../../../shared/abstract.component'
import { selectVehicleByPlate, selectVehiclesByPlate } from '../vehicle.selectors'
import { EditService } from '../../../shared/services/edit.service'
import { setVehicle } from '../../member/member.actions'
import { Make, Vehicle } from '../vehicle.types'
import { VehicleSections } from '../../ui/ui.types'
import { AdobeEventTypes } from '../../tagging/tagging.types'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import events from '../../tagging/events'
import { Observable } from 'rxjs'
import { VehicleUtils } from '../vehicle.utils'
import { setResetPlateToVinForm } from '../../ui/forms/forms.reducer'

@Component({
  selector: 'drr-vehicle-details-confirm',
  templateUrl: './vehicle-details-confirm.component.html',
  styleUrls: ['./vehicle-details-confirm.component.scss'],
})
export class VehicleDetailsConfirmComponent extends AbstractComponent implements OnInit {

  vehicles$: Observable<Vehicle[]> = this.store$.pipe(select(selectVehiclesByPlate))
  vehicle$: Observable<Vehicle> = this.store$.pipe(select(selectVehicleByPlate))
  vehicle: Vehicle

  isColorPickerOpen = false
  isAddVehicleOptionsOpen = false

  previousStep = VehicleSections.PLATE

  constructor(
    private store$: Store<AAAStore>,
    public editService: EditService,
    private adobeEventService: AdobeEventService,
    private vehicleUtils: VehicleUtils
  ) {
    super()
  }


  ngOnInit(): void {
    this.subscriptions.push(
      this.vehicle$.subscribe((vehicle) => {
        this.vehicle = vehicle
      }),
      this.vehicles$.subscribe((vehicles) => {
        if (vehicles.length > 1) {
          this.previousStep = VehicleSections.CONFIRM_OPTIONS
        }
      })
    )
  }

  handleConfirmVehicle() {
    this.store$.dispatch(setVehicle({
      payload: {
        make: this.vehicle.make,
        model: this.vehicle.model,
        year: this.vehicle.year,
        color: this.vehicle.color,
        vin: this.vehicle.vin,
        tag: this.vehicle.tag,
        state: this.vehicle.state,
        trim: this.vehicle.trim,
        vehicleType: this.vehicle.vehicleType,
        fuelType: this.vehicle.fuelType,
        driveType: this.vehicle.driveType,
      }
    }))
    this.store$.dispatch(setResetPlateToVinForm())
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.vehicle.PLATE_TO_VIN_CONFIRM_VEHICLE
    })
  }

  handleVehicleNotListed() {
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.vehicle.PLATE_TO_VIN_VEHICLE_NOT_LISTED
    })
    this.isAddVehicleOptionsOpen = true
  }

  handleChangeColor() {
    this.isColorPickerOpen = true
  }

  setColor(color: string) {
    this.vehicle.color = color
    this.isColorPickerOpen = false
  }

  setModalOpen(isOpen: boolean = false) {
    this.isColorPickerOpen = isOpen
  }

  handleBack() {
    this.editService.editVehicleSection(false, '', this.previousStep)
  }

  buildMake(makeLabel: string): Make {
    return this.vehicleUtils.buildMake(makeLabel)
  }

}
