<ng-container [ngSwitch]="markerDetails.type">
  <icon-breakdown-location-pin alt="breakdown location pin" i18n-alt *ngSwitchCase="'BREAKDOWN'"></icon-breakdown-location-pin>
  <icon-tow-location-pin alt="tow location pin" i18n-alt *ngSwitchCase="'TOW_LOCATION'"></icon-tow-location-pin>
  <icon-truck-location-pin alt="tow truck pin" i18n-alt *ngSwitchCase="'TRUCK'" [type]="markerDetails.truckType"></icon-truck-location-pin>
  <aaa-icon *ngSwitchCase="MarkerTypes.CAR" alt="car location pin" i18n-alt name="drr-car-pin" [color]="markerDetails.active ? 'tertiary' : 'dark'" size="x-large"></aaa-icon>
  <aaa-icon *ngSwitchCase="MarkerTypes.AAA_OWNED" alt="AAA owned facility pin" i18n-alt name="drr-aaa-pin" [ngClass]="{'pin-selected': markerDetails.active}" [color]="markerDetails.active ? 'tertiary' : 'primary'" size="x-large"></aaa-icon>
  <aaa-icon *ngSwitchCase="MarkerTypes.AAA_APPROVED" alt="AAA approved facility pin" i18n-alt name="drr-wrench-pin" [ngClass]="{'pin-selected': markerDetails.active}" [color]="markerDetails.active ? 'tertiary' : 'dark'" size="x-large"></aaa-icon>
  <aaa-icon *ngSwitchCase="MarkerTypes.EV_STATION" name="drr-bolt-pin" alt="EV Station pin" i18n-alt [ngClass]="{'pin-selected': markerDetails.active}" [color]="markerDetails.active ? 'tertiary' : 'dark'" size="x-large"></aaa-icon>
  <aaa-icon *ngSwitchCase="MarkerTypes.CUSTOM" name="drr-place-pin" alt="custom destination pin" i18n-alt [color]="markerDetails.active ? 'tertiary' : 'dark'" size="x-large"></aaa-icon>
  <aaa-icon *ngSwitchCase="MarkerTypes.TOW_TRUCK" alt="tow truck pin" i18n-alt name="drr-truck-pin" color="tertiary" size="x-large"></aaa-icon>
</ng-container>
