export const environment = {
  clientId: 'drr-web.qa',
  additionalCookieDomain: true,
  configTools: true,
  description: 'Dev build',
  displayVersion: true,
  optimize: false,
  log: true,
  baseURL: 'https://gatewayers-qa.national.aaa.com',
  openAARsURL: 'https://tdr.aaa.com/tdrl/search.jsp',
  errorReportingID: 'APPLICATION-D3E325AB5F8AE93A',
  errorReportingSource: 'DRR Web Dev',
  errorReportingToken: 'uEsmJJ0YTnGChqK9cmyKO',
  errorReportingURL: 'https://gzz47282.live.dynatrace.com',
  ipInterrogateURL:
    'https://certservices.national.aaa.com/RESTWS/aaa/services/geo/locate',
  aaaQuoteServicesUrl: 'https://services.aaa.com/RESTWS/aaa/services',
  aaaConfigurationServicesUrl:
    'https://certservices.national.aaa.com/RESTWS/aaa/services/configuration',
  rapConfigurationBaseUrl:
    'https://certc.national.aaa.com/AAA/Common/drr/rap/config',
  association: 'AAA',
  rapGoogleMapsApiKey: process.env["RAP_GOOGLE_MAPS_API_KEY"],
  googleMapsApiKey: process.env["GOOGLE_MAPS_API_KEY"],
  googleCaptchaKey: process.env["GOOGLE_RECAPTCHA_API_KEY"],
  googleCaptchaV3Key: process.env["GOOGLE_RECAPTCHA_V3_API_KEY"],
  unsecureClub: 999,
  hotjar: '2789441',
  thirdPartyCookiesUrl: 'https://dfkfmvngo6zht.cloudfront.net/v1.html',
  nativeAppView: false,
  homeBackButtonRedirectTo: '/home',
  datastreamId: "e9415361-7c0b-4f4e-bd22-50b944ba09b5",
  orgId: "720B215F637299A10A495F9A@AdobeOrg",
  awsLocationApiKey: process.env["AWS_LOCATION_API_KEY"]
}
