import { array, boolean, number, object, string } from 'yup'
import { MembersResult, SearchMemberResult } from './member.types'

export const MemberVehiclesSchema = object({
  vehicles: array().of(
    object({
      make: string(),
      model: string(),
      year: number(),
      vin: string(),
      id: string(),
    })
  ),
})

export const MemberSchema = object({
  basicAddress: string(),
  city: string(),
  countryCode: string().nullable(),
  eligible: boolean(),
  ersAbuser: boolean(),
  expirationDate: string(),
  firstName: string().required(),
  lastName: string().required(),
  memberVehicles: array().of(MemberVehiclesSchema),
  membershipNumber: string(),
  noteText: string().nullable(),
  plusIndicator: string(),
  postalCode: string(),
  preferredAARs: string().nullable(),
  stateProvince: string(),
  statusCode: string(),
  supplementalAddress: string().nullable(),
  supplementalTelephoneNumber: string().nullable(),
  telephoneNumber: string().nullable(),
})

export const MemberBasicSchema = object({
  basicAddress: string().nullable(),
  city: string().nullable(),
  countryCode: string().nullable(),
  eligible: boolean(),
  ersAbuser: boolean(),
  expirationDate: string().nullable(),
  firstName: string().required(),
  lastName: string().required(),
  memberVehicles: array().of(MemberVehiclesSchema).nullable(),
  membershipNumber: string(),
  noteText: string().nullable(),
  plusIndicator: string(),
  postalCode: string().nullable(),
  preferredAARs: string().nullable(),
  stateProvince: string().nullable(),
  statusCode: string(),
  supplementalAddress: string().nullable(),
  supplementalTelephoneNumber: string().nullable(),
  telephoneNumber: string().nullable(),
})

const MembersResultSchema = object<MembersResult>().shape({
  resultId: string(),
  streetNumber: string(),
})

export const SearchMemberResultSchema = object<SearchMemberResult>().shape({
  searchId: string(),
  members: array().of(MembersResultSchema),
})
