import { IndexedCollection } from '../../shared/types'
import { IssueType, PACE_SETTER_SITUATION_ADDITIONAL_REQUIREMENTS_TYPES, priorityCodeType } from '../issue/issue.types'

export function generateCallId(callId: string, callDate: string) {
  return `${callId}-${callDate}`
}

export function generateCallKey(servicingClub: string, callDate: string, callId: string) {
  return `${servicingClub}-${callDate.replace(/-/g, '')}-${callId}`
}

export function indexCalls<T>(data: Array<T>): IndexedCollection<T> {
  const filteredData = data.filter((activeCall: any) => activeCall?.callId && activeCall?.callDate)
  if (!filteredData || filteredData.length === 0) {
    return {}
  }
  return filteredData.reduce(
    (indexedObj: IndexedCollection<T>, item: T) => ({
      ...indexedObj,
      [generateCallId(String(item['callId']), String(item['callDate']))]: item,
    }),
    {}
  )
}
//Todo: Once we removed the paceSetter, we can only use the issueTypes
export const mapPaceSetterToIssueType: Record<string, IssueType> = {
  "Battery Test or Replace": IssueType.BATTERY,
  "Accident": IssueType.ACCIDENT,
  "Yes, my vehicle will need to be towed to another location.": IssueType.STUCK,
  "No, I don't need a tow": IssueType.EXTRICATE_ONLY,
  "Battery issue": IssueType.BATTERY,
  "Yes": IssueType.FLAT_TIRE_WITH_SPARE,
  "No": IssueType.FLAT_TIRE_NO_SPARE,
  "Locked in the vehicle": IssueType.LOCKED_OUT_KEY_INSIDE,
  "My key is broken or lost": IssueType.LOCKED_OUT_KEY_LOST,
  "My key won't turn in the ignition": IssueType.LOCKED_OUT_KEY_NO_TURN,
  "My key won't unlock the door": IssueType.LOCKED_OUT_KEY_NO_WORK,
  "I don't know": IssueType.LOCKED_OUT_UNSURE,
  "Gasoline": IssueType.FUEL_GAS,
  "Diesel Fuel": IssueType.FUEL_DIESEL,
  "Electric Vehicle": IssueType.FUEL_ELECTRIC,
  "Need a tow": IssueType.NEED_A_TOW,
  "My Car Won't Start": IssueType.CAR_WONT_START,
  "I turn the key and hear a clicking sound": IssueType.CAR_WONT_START_CLICK,
  "Vehicle died while driving": IssueType.CAR_WONT_START_DIED,
  "I turn the key and nothing happens": IssueType.CAR_WONT_START_BATTERY,
  "I turn the key and my car cranks, but does not start": IssueType.CAR_WONT_START_CRANK,
}

export const mappingPriorityCodeType = {
  [PACE_SETTER_SITUATION_ADDITIONAL_REQUIREMENTS_TYPES.LOVED_ONE_LOCKED_IN_CAR]: priorityCodeType.LOCKOUT_LOVE,
  [PACE_SETTER_SITUATION_ADDITIONAL_REQUIREMENTS_TYPES.MEDICATION_LOCKED_IN_CAR]: priorityCodeType.LOCKOUT_MEDS,
  [priorityCodeType.HIGHWAY]: priorityCodeType.HIGHWAY,
  ACCIDENT: priorityCodeType.ACCIDENT,
  [IssueType.LOCKED_OUT_KEY_INSIDE]: priorityCodeType.LOCKOUT,
  [IssueType.LOCKED_OUT]: priorityCodeType.LOCKOUT,
  [IssueType.LOCKED_OUT_KEY_LOST]: priorityCodeType.LOCKOUT,
  [IssueType.LOCKED_OUT_KEY_NO_TURN]: priorityCodeType.LOCKOUT,
  [IssueType.LOCKED_OUT_KEY_NO_WORK]: priorityCodeType.LOCKOUT,
  [IssueType.LOCKED_OUT_UNSURE]: priorityCodeType.LOCKOUT,
}

