import { createAction, props } from '@ngrx/store'

import { createAsyncConstants } from '../../shared/utils'
import { TowLocation } from '../location/location.types'
import {
  CallCancelParams,
  CallCancelSuccess,
  CallSubmission,
  RequestFailure,
} from './calls.types'

export const CALL_RESET = 'CALL_RESET'
export const SET_TOWING = 'SET_TOWING'
export const ADD_CALL = createAsyncConstants('ADD_CALL')
export const ADD_PARTIAL_CALL = createAsyncConstants('ADD_PARTIAL_CALL')
export const CALL_CANCEL = createAsyncConstants('CALL_CANCEL')
export const CALL_STATUS_ERROR = 'CALL_STATUS_ERROR'
export const RESET_CALL_STATUS_ERROR = 'RESET_CALL_STATUS_ERROR'
export const RESET_ACTIVE_CALLS_CALLED = 'RESET_ACTIVE_CALLS_CALLED'

export const resetCall = createAction(CALL_RESET)

export const addCallRequest = createAction(ADD_CALL.REQUEST)

export const addCallSuccess = createAction(
  ADD_CALL.SUCCESS,
  props<{ payload: CallSubmission }>()
)

export const notifyCallFailure = createAction(
  ADD_CALL.FAILURE,
  props<{ payload: RequestFailure }>()
)

export const addPartialCallRequest = createAction(ADD_PARTIAL_CALL.REQUEST)

export const addPartialCallSuccess = createAction(ADD_PARTIAL_CALL.SUCCESS)

export const notifyPartialCallFailure = createAction(ADD_PARTIAL_CALL.FAILURE)

export const callCancelRequest = createAction(
  CALL_CANCEL.REQUEST,
  props<{ payload: CallCancelParams }>()
)

export const callCancelSuccess = createAction(
  CALL_CANCEL.SUCCESS,
  props<{ payload: CallCancelSuccess }>()
)

export const notifyCallCancelFailure = createAction(CALL_CANCEL.FAILURE)

export const setCallTowing = createAction(
  SET_TOWING,
  props<{ payload: TowLocation }>()
)

export const setCallStatusError = createAction(CALL_STATUS_ERROR)

export const resetCallStatusError = createAction(RESET_CALL_STATUS_ERROR)

export const resetActiveCallsCalled = createAction(RESET_ACTIVE_CALLS_CALLED)
