import { Injectable } from '@angular/core'
import { IndexedCollection } from '../../../shared/types'
import { indexCollection } from '../../../shared/utils'
import { XHRService } from '../../../shared/services/xhr.service';
import { AARData, EvStationDetailsResponse, TowDestinationResponse } from './aar.types';
import { EvStationResponseSchema } from './aar.model';
import { AARDataAdapter } from '../../../shared/adapters/AARDataAdapter';

const RADIUS_EV_STATIONS = 10

@Injectable({
  providedIn: 'root',
})
export class EvStationService {

  constructor(
    private xhr: XHRService,
    private aarDataAdapter: AARDataAdapter,
  ) {
  }

  async search(latitude, longitude, limit?, radius = RADIUS_EV_STATIONS): Promise<IndexedCollection<AARData>> {
    try {
      const response = await this.xhr.authRequest<TowDestinationResponse<EvStationDetailsResponse>>({
        url: '/bff/tow-destinations/ev-stations',
        params: {
          latitude,
          longitude,
          radius,
          ...(limit ? { limit } : {}),
        },
      })
      await EvStationResponseSchema.validate(response)
      const aarsAdapted: AARData[] = response.results.map(aar => this.aarDataAdapter.adaptTowDestination<EvStationDetailsResponse>(aar))

      return indexCollection<AARData, 'id'>(aarsAdapted, 'id')
    } catch (error) {
      throw error
    }
  }
}
