import { object, string, array, number } from 'yup'
import { Vehicle, VehicleImageLinks, VehicleMakes, VehicleModels, VehicleYears, VehicleImages, VehicleImagesResponse } from './vehicle.types'

export const VehicleYearsSchema = object<VehicleYears>().shape({
  years: array().of(string()).required(),
})

export const VehicleMakesSchema = object<VehicleMakes>().shape({
  makes: array().of(string()),
})

export const VehicleModelsSchema = object<VehicleModels>().shape({
  models: array().of(string()),
})

export const VehicleSchema = object<Vehicle>().shape({
  vin: string(),
  year: number(),
  make: string(),
  model: string(),
  tag: string(),
  state: string(),
  trim: string(),
  fuelType: string(),
  driveType: string(),
  vehicleType: string(),
})

export const PlateToVinResponseSchema = object({
  vehicles: array().of(VehicleSchema).defined(),
})

export const VehicleImageLinksSchema = object<VehicleImageLinks>().shape({
  full: string(),
  thumbnail: string(),
})
export const VehicleImagesSchema = object<VehicleImages>().shape({
  front: VehicleImageLinksSchema,
})

export const VehicleImagesResponseSchema = object<VehicleImagesResponse>().shape({
  vehicleImages: VehicleImagesSchema.defined(),
})

