<div class="wrapper">
  <div id="map" class="w-full h-full"></div>
  <div class="map-overlays">
    <app-custom-marker-aws
      *ngIf="showGpsLocation && userLocation?.lat && userLocation?.lng && map"
      [location]="userLocation"
      [map]="map"
      [data]="defaultUserLocationData"
      [tabIndex]="2"
    >
      <app-img alt="user gps location" i18n-alt src="assets/drrweb-lib/images/gps-dot.png"></app-img>
    </app-custom-marker-aws>

    <ng-container *ngFor="let customMarker of customMarkers">
      <app-custom-marker-aws
        #customMarkerComponents
        [id]="customMarker.id"
        [location]="customMarker.location"
        [map]="map"
        (markerTouchStart)="handleCustomMarkerClick(customMarker)"
        [data]="customMarker.markerDetails"
        [tabIndex]="customMarker.tabIndex">
        <app-map-icon alt="user location pin" i18n-alt [markerDetails]="customMarker.markerDetails"></app-map-icon>
      </app-custom-marker-aws>
    </ng-container>

    <ng-container *ngIf="centerMarker">
      <app-map-icon alt="user location pin" i18n-alt class="center-marker" [markerDetails]="centerMarker"></app-map-icon>
    </ng-container>
  </div>

  <div class="right-side-slot">
    <div *ngIf="displayZoomAction">
      <aaa-button
        size="medium"
        class="zoom-in drr-map-button ion-float-right"
        variant="on-map"
        i18n-aria-label
        aria-label="zoom in"
        (click)="zoomIn($event)"
        [tabIndex]="tabIndexPosition + 5">
        <aaa-icon aria-hidden="true" name="add-outline"></aaa-icon>
      </aaa-button>
      <aaa-button
        size="medium"
        class="zoom-out drr-map-button ion-float-right ion-margin-8"
        variant="on-map"
        i18n-aria-label
        aria-label="zoom out"
        (click)="zoomOut($event)"
        [tabIndex]="tabIndexPosition + 5">
        <aaa-icon aria-hidden="true" name="remove-outline"></aaa-icon>
      </aaa-button>
    </div>
    <div *ngIf="displayLocateMeAction">
      <aaa-button
        id="locate-user-button"
        size="medium"
        class="drr-map-button ion-float-right ion-margin-top"
        variant="primary"
        i18n-aria-label
        aria-label="locate user"
        (click)="locateUser($event)"
        [tabIndex]="tabIndexPosition + 5">
        <aaa-icon aria-hidden="true" name="navigate-outline"></aaa-icon>
      </aaa-button>
    </div>
  </div>

  <div class="top-slot">
    <ng-content select="[topSlot]"></ng-content>
  </div>

  <div class="bottom-slot">
    <ng-content select="[bottomSlot]"></ng-content>
  </div>

  <div class="top-actions" [class.top-80]="isValidBreakdownLocation" *ngIf="showActionButtons">
    <app-button
      class="ion-margin-8"
      (click)="adjustLocationClick.emit()"
      fill="solid"
      color="primary"
      size="small">
      <span class="left-icon-button">
        <aaa-icon name="drr-edit" color="white"></aaa-icon>
      </span>
      <span i18n>Adjust Location</span>
    </app-button>
  </div>

  <div class="bottom-actions" [class.bottom-actions-horizontal]="!isValidBreakdownLocation" [class.bottom-actions-vertical]="isValidBreakdownLocation" *ngIf="showActionButtons">
    <app-button
      *ngIf="!isValidBreakdownLocation"
      class="ion-margin-8"
      (click)="searchAreaClick.emit()"
      fill="solid"
      color="primary"
      size="small">
      <span i18n>Search this area</span>
    </app-button>

    <app-button
      *ngIf="isValidBreakdownLocation"
      class="ion-margin-8"
      (click)="adjustPinClick.emit()"
      fill="solid"
      color="primary"
      size="small">
      <span i18n>Adjust Pin</span>
    </app-button>

    <app-button
      *ngIf="isValidBreakdownLocation"
      class="adjust-pin"
      (click)="useCurrentLocation.emit()"
      fill="clear"
      color="primary"
      size="small">
      <span i18n>Use Current Location</span>
    </app-button>
  </div>
</div>
