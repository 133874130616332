import { Injectable } from '@angular/core'
import { combineLatest, Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { map, withLatestFrom } from 'rxjs/operators'
import { closeDialog } from '../../../../modules/ui/ui.actions'
import { Dialog } from '../../../../modules/ui/ui.types'
import { selectServicingClubRoadServicePhoneNumber } from '../../../../modules/servicing-club/servicing-club.selectors'
import { selectCallText } from '../../../../modules/auth/auth.selectors'
import { selectActiveDialogsByTime } from '../../../../modules/ui/ui.selectors'
import { libNotificationMap } from './lib-notifications'
import { DRRNotification } from './lib-notification.model'

@Injectable({
  providedIn: 'root',
})
export class LibNotificationService {
  private activeDialogs$: Observable<Array<Dialog>> = this.store$.select(
    selectActiveDialogsByTime
  )

  private callAAA$: Observable<string[]> = combineLatest([
    this.store$.select(selectServicingClubRoadServicePhoneNumber),
    this.store$.select(selectCallText),
  ])

  public notifications$: Observable<DRRNotification[]> =
    this.activeDialogs$.pipe(
      withLatestFrom(this.callAAA$),
      map(([dialogs, [roadServicePhoneNumber, callText]]) =>
        dialogs
          .map((dialog) => {
            const notification = libNotificationMap[dialog.type]
            return (
              notification &&
              notification({
                ...dialog,
                params: {
                  callText,
                  roadServicePhoneNumber,
                },
                close: () => this.onClose(dialog),
              })
            )
          })
          .filter((dialog) => !!dialog)
      )
    )

  constructor(private store$: Store) {}

  private onClose(dialog: Dialog) {
    this.store$.dispatch(closeDialog({ payload: dialog }))
  }
}
