import {
  ASSIGN_EXISTING_VEHICLE,
  CLEAR_ACTIVE_VEHICLE,
  MEMBER_BASIC_INFO,
  MEMBER_INFO,
  MEMBERS_SEARCH,
  MEMBERS_SEARCH_RESET,
  REMOVE_VEHICLE,
  SET_ELIGIBILITY_VEHICLE,
  SET_VEHICLE,
  VEHICLE_ADD,
  VEHICLE_DELETE,
  VEHICLE_LOAD,
  VEHICLE_UPDATE,
} from './member.actions'
import { MemberInfo, Vehicle, } from './member.types'
import { PayloadedAction } from '../../shared/types'
import { CANCEL_EDITING_REQUEST } from '../../shared/actions/shared.actions'

export interface SearchMembersResults {
  searchId: string
  members: any[]
}

export interface MemberState {
  vehicles: VehicleGroup
  activeVehicle: Vehicle | null
  data: MemberInfo | null
  searchResults: SearchMembersResults | null
}

export interface VehicleGroup {
  data: Array<Vehicle>
}

export const initialState: MemberState = {
  vehicles: {
    data: [],
  },
  activeVehicle: null,
  data: null,
  searchResults: null,
}

export function vehicleReducer(
  state: VehicleGroup = initialState.vehicles,
  action: PayloadedAction
) {
  switch (action.type) {
    case VEHICLE_LOAD.SUCCESS:
      return {
        ...state,
        data: [...action.payload.vehicles],
      }
    case VEHICLE_ADD.SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
      }
    case VEHICLE_UPDATE.SUCCESS:
      const currentVehicles = state.data
      const index = currentVehicles.findIndex(
        (vehicle) => vehicle.id === action.payload.id
      )
      // Keep same item position, instead of moving the item to the of the list
      if (index !== -1) {
        currentVehicles[index] = {
          ...currentVehicles[index],
          ...action.payload,
        }
      }

      return {
        ...state,
        data: [...currentVehicles],
      }
    case VEHICLE_DELETE.SUCCESS:
      return {
        ...state,
        data: state.data.filter((vehicle) => vehicle.id !== action.payload),
      }
    default:
      return state
  }
}

export function reducer(
  state: MemberState | undefined = initialState,
  action: PayloadedAction
): MemberState {
  switch (action.type) {
    case MEMBER_INFO.SUCCESS:
      return {
        ...state,
        data: { ...action.payload },
      }
    case MEMBER_BASIC_INFO.SUCCESS:
      return {
        ...state,
        data: { ...action.payload },
      }
    case SET_VEHICLE:
    case SET_ELIGIBILITY_VEHICLE:
    case ASSIGN_EXISTING_VEHICLE:
      return {
        ...state,
        activeVehicle: { ...action.payload },
      }
    case CLEAR_ACTIVE_VEHICLE:
      return {
        ...state,
        activeVehicle: null,
      }
    /**
     * This will grow eventually, move
     * CallVehicleRequest to a private VehicleReducer
     */
    case VEHICLE_LOAD.SUCCESS:
      return {
        ...state,
        vehicles: vehicleReducer(state.vehicles, action),
      }
    case VEHICLE_ADD.SUCCESS:
      return {
        ...state,
        activeVehicle: {
          ...state.activeVehicle,
          id: action.payload.id,
        },
        vehicles: vehicleReducer(state.vehicles, action),
      }
    case REMOVE_VEHICLE: {
      return {
        ...state,
        vehicles: vehicleReducer(state.vehicles, action),
      }
    }
    case VEHICLE_UPDATE.SUCCESS:
      return {
        ...state,
        vehicles: vehicleReducer(state.vehicles, action),
      }
    case VEHICLE_DELETE.SUCCESS:
      return {
        ...state,
        vehicles: vehicleReducer(state.vehicles, action),
      }
    case MEMBERS_SEARCH.SUCCESS:
      return {
        ...state,
        searchResults: { ...action.payload },
      }
    case MEMBERS_SEARCH_RESET:
      return {
        ...state,
        searchResults: null,
      }
    case CANCEL_EDITING_REQUEST:
      return {
        ...state,
        activeVehicle: null,
      }
    default:
      return state
  }
}
