import { Pipe, PipeTransform } from '@angular/core'

const PHONE_FORMAT =
  /([A-Z0-9])\W?([A-Z0-9]{3})\W?([[A-Z0-9]{3})\W?([[A-Z0-9]{4})/
const PHONE_MASK = /[^A-Z0-9]/g
const PHONE_LENGTH = 11

const PHONE_CONVERTER_2 = /[A-C]/g
const PHONE_CONVERTER_3 = /[D-F]/g
const PHONE_CONVERTER_4 = /[G-I]/g
const PHONE_CONVERTER_5 = /[J-L]/g
const PHONE_CONVERTER_6 = /[M-O]/g
const PHONE_CONVERTER_7 = /[P-S]/g
const PHONE_CONVERTER_8 = /[T-V]/g
const PHONE_CONVERTER_9 = /[W-Z]/g
const PHONE_CONVERTER_0 = /[\\+]/g

export enum ConversionType {
  HREF = 'href',
  LABEL = 'label',
  NUMERIC = 'numeric',
}

@Pipe({ name: 'phoneConverter' })
export class PhoneConverterPipe implements PipeTransform {
  transform(value: any, conversionType: string) {
    // extract the phone number without mask
    let clearAlphaNumber = (value || '')
      .toString()
      .toUpperCase()
      .replace(PHONE_MASK, '')
    // if the number length is one char less, prepend the US prefix(1)
    // else if the length is greater or lower log an error and set to the default
    if (clearAlphaNumber.length === PHONE_LENGTH - 1) {
      clearAlphaNumber = '1' + clearAlphaNumber // if no country prefix is informed set the US
    } else if (
      clearAlphaNumber.length < PHONE_LENGTH - 1 ||
      clearAlphaNumber.length > PHONE_LENGTH
    ) {
      // if the number length is not supported set to the default
      console.error(
        `Wrong phone number format for ${value}, setting to default AAA number.`
      )
      clearAlphaNumber = '800AAAHELP'
    }
    if (
      ConversionType.HREF === conversionType ||
      ConversionType.NUMERIC === conversionType
    ) {
      // convert alphanumeric number to numeric
      clearAlphaNumber = clearAlphaNumber
        .replace(PHONE_CONVERTER_2, '2')
        .replace(PHONE_CONVERTER_3, '3')
        .replace(PHONE_CONVERTER_4, '4')
        .replace(PHONE_CONVERTER_5, '5')
        .replace(PHONE_CONVERTER_6, '6')
        .replace(PHONE_CONVERTER_7, '7')
        .replace(PHONE_CONVERTER_8, '8')
        .replace(PHONE_CONVERTER_9, '9')
        .replace(PHONE_CONVERTER_0, '0')
      if (ConversionType.HREF === conversionType) {
        return `tel:+${clearAlphaNumber}`
      } else {
        const numberParts = clearAlphaNumber.match(PHONE_FORMAT)
        return `${numberParts[1]}-${numberParts[2]}-${numberParts[3]}-${numberParts[4]}`
      }
    } else if (ConversionType.LABEL === conversionType) {
      // apply the mask A-BCD-EFG-HIJL to the number
      const numberParts = clearAlphaNumber.match(PHONE_FORMAT)
      return `${numberParts[1]}-${numberParts[2]}-${numberParts[3]}-${numberParts[4]}`
    }
  }
}
