<ng-container *ngIf="(problemTooltip$ | async) as paceSetterSituation">
  <ion-card
    [@slideInAnimation]>
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col>
          <aaa-text size="subheadline" weight="semibold" color="subtle" align="left">
            {{ (problemTooltip$ | async).label }}
          </aaa-text>
        </ion-col>
        <ion-col size="auto">
          <aaa-button variant="ghost" size="small" class="ion-float-end close-alignment" (click)="clearTooltip()">
            <aaa-icon name="close-outline" size="small"></aaa-icon>
          </aaa-button>
        </ion-col>
      </ion-row>
      <ion-row class="ion-margin-vertical">
        <ion-col>
          <aaa-text size="footnote" color="dark" align="left">
            {{ (problemTooltip$ | async).description }}
          </aaa-text>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <aaa-button
            class="ion-float-end start-request-button"
            size="small"
            variant="primary"
            *ngIf="paceSetterSituation.enabled !== undefined ? paceSetterSituation.enabled : true"
            appCtaClickEvent="Tooltip Issue Selected"
            [issueType]="paceSetterSituation.name"
            (click)="select(paceSetterSituation)">
            <ng-container i18n>Start Request</ng-container>
            <aaa-icon name="chevron-forward-outline" size="small"></aaa-icon>
          </aaa-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card>
</ng-container>
