<ion-card class="ion-no-margin ion-margin-vertical">
  <div class="ion-padding card-container">
    @if(adjustRequired) {
      <div adjust-location>
        <aaa-text size="title3" color="dark" align="center" i18n>Adjust your location</aaa-text>
        <aaa-text size="caption" color="tertiary" weight="semibold" align="center" i18n>Help us find you</aaa-text>
        <aaa-text size="caption" weight="regular" class="margin-bottom-8" color="dark" align="center" i18n>Drag the map to your precise breakdown location or enter a full address</aaa-text>
      </div>
    } @else {
      <div set-location>
        <aaa-text size="title3" color="dark" align="center" i18n>Set your location</aaa-text>
        <aaa-text size="caption" weight="regular" class="margin-bottom-8" color="dark" align="center" i18n>Drag map to move pin</aaa-text>
      </div>
    }
    <ion-item lines="none" class="ion-no-padding">
      <app-location-address [address]="address"></app-location-address>
      <aaa-button aria-label="clear address" i18n-aria-label class="close-button" variant="ghost" size="small" (click)="handleClearAddress()" [disabled]="loading" *ngIf="onClearAddress.observers.length > 0">
        <aaa-icon aria-hidden="true" name="close" slot="end" color="dark" size="small"></aaa-icon>
      </aaa-button>
    </ion-item>
    <drr-location-highway-exit />
    <aaa-button
      appCtaClickEvent="Confirm Location Card"
      class="confirm-button margin-top-8"
      expand="block"
      i18n
      (click)="handleConfirm()"
      aria-label="confirm location"
      i18n-aria-label
      [disabled]="adjustRequired"
      [loading]="loading"
    >
      Confirm Location
    </aaa-button>
  </div>
</ion-card>
