import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { from, Observable, of } from 'rxjs'
import { catchError, map, switchMap, take, timeout } from 'rxjs/operators'
import { AAAStore } from '../../../store/root-reducer'
import { ConfigService } from '../../config/config.service'
import { setABTestParam } from '../../ui/ui.actions'
import { ABTestKey, ABTestParams, AdobeTargetContent, AdobeTargetResponse } from '../../ui/ui.types'
import { WindowObject } from '../../../shared/utils/window-object';
import { RapService } from '../../rap/rap.service';

@Injectable({
  providedIn: 'root'
})
export class AdobeTargetService {
  constructor(
    private store$: Store<AAAStore>,
    private configService: ConfigService,
    private windowObject: WindowObject,
    private rapService: RapService,
  ) {}

  getTargetProposition(abTest: ABTestKey): Observable<AdobeTargetContent> {
    if (this.rapService.isRapUser()) {
      return of(null)
    }
    return from(this.getAlloy()).pipe(
      switchMap(alloy =>
        from((alloy as any)('sendEvent', {
          decisionScopes: [abTest],
          renderDecisions: true,
          data: {
            proposition: [abTest],
          }
        })).pipe(
          timeout(3000),
          take(1),
          map(response => this.validateAndApplyProposition(response, abTest)),
          catchError(error => {
            this.logMessage('Error fetching Target Proposition:', error)
            return of(null)
          })
        )
      ),
      catchError(error => {
        this.logMessage('Error while loading alloy:', error)
        return of(null)
      })
    )
  }

  private validateAndApplyProposition(response: AdobeTargetResponse, abTestKey: ABTestKey): AdobeTargetContent {
    const prop = response?.propositions?.find(p => p.scope === abTestKey )
    if (!prop) {
      this.logMessage(`No Propositions available for ${abTestKey}`)
      return null
    }
    this.logMessage(`Propositions available for ${abTestKey}: `, prop)
    const content = prop?.items?.[0].data?.content
    const abTestFlag = this.getAbTestParam(content?.variation)

    this.windowObject.addWindowPropositions(prop)
    if (!abTestFlag) {
      this.logMessage(`Proposition details are not available for ${content?.variation} or it is the default variation`)
    } else {
      this.store$.dispatch(setABTestParam({ payload: [abTestFlag] }))
      this.logMessage(`Proposition applied successfully for variation ${abTestFlag}`)
    }
    return content
  }

  // Todo: Create a shared console.log
  logMessage(...message) {
    if(this.configService.getConfig().configTools){
      console.log('Adobe Target Service:', ...message)
    }
  }

  private getAbTestParam(value: string): ABTestParams | undefined {
    return Object.values(ABTestParams).includes(value as ABTestParams) ? (value as ABTestParams) : undefined;
  };

  getAlloy() {
    return new Promise((resolve, reject) => {
      let attempts = 0
      const interval = 100 // Check every 100ms
      const maxAttempts = 3000 / interval // Give up after 3 seconds

      const checkAlloy = setInterval(() => {
        attempts++
        if ((window as any).alloy_aaa_national !== undefined) {
          clearInterval(checkAlloy)
          resolve((window as any).alloy_aaa_national)
        } else if (attempts >= maxAttempts) {
          clearInterval(checkAlloy)
          reject(new Error('Alloy is not defined within 3 seconds.'))
        }
      }, interval)
    })
  }
}
