import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LocationAddress } from '../breakdown-location.types';
import { LastSearchLocation } from '../../../location/location.types';
import { LocationSearchComponent } from '../location-search/location-search.component';

@Component({
  selector: 'app-location-search-modal',
  templateUrl: './location-search-modal.component.html',
  styleUrls: ['./location-search-modal.component.scss']
})
export class LocationSearchModalComponent {
  @ViewChild(LocationSearchComponent) locationSearch: LocationSearchComponent;

  @Input() isOpen = false;
  @Input() selectedAddress: string;
  @Input() gpsAvailable = false;
  @Input() lastSearchLocation: LastSearchLocation | undefined;
  @Input() location: any;

  @Output() onClose = new EventEmitter<void>();
  @Output() locationSelected = new EventEmitter<LocationAddress>();
  @Output() useCurrentLocation = new EventEmitter<void>();

  ionicMode = 'ios'

  ngOnChanges() {
    if (this.isOpen && this.locationSearch) {
      this.locationSearch.focus();
    }
  }

  handleClose() {
    this.onClose.emit();
  }

  handleLocationSelected(location: LocationAddress) {
    this.locationSelected.emit(location);
  }

  handleUseCurrentLocation() {
    this.useCurrentLocation.emit();
  }
}
