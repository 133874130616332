import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { IndexedCollection } from '../../shared/types'
import { indexCollection } from '../../shared/utils'
import { getOemBrandingId } from '../../shared/utils/branding'
import { WindowObject } from '../../shared/utils/window-object'
import { AAAStore } from '../../store/root-reducer'
import { XHRService } from '../../shared/services/xhr.service'
import { selectMaxTowMileLimit, selectModeConfiguration } from '../auth/auth.selectors'
import { VendorConfigurationMode } from '../auth/mode-configuration.types'
import { TowDestinationSchema } from '../location/aar/aar.model'
import { AARData, AarDetailsResponse, TowDestinationResponse } from '../location/aar/aar.types'
import { ENTITLEMENT_TYPES, PACE_SETTER_SITUATION_CODE_ENTITLEMENT_TYPE, PaceSetterCode } from '../issue/issue.types';
import { Eligibility, Entitlement } from '../auth/eligibility.types';
import { PACE_SETTER_OVERRIDE_OPTIONS } from '../servicing-club/servicing-club.types';
import { getPacesetterCodeByValue } from '../issue/issue.utils';
import { AARDataAdapter } from "../../shared/adapters/AARDataAdapter";


@Injectable({
  providedIn: 'root',
})
export class RapService {

  modeConfiguration: VendorConfigurationMode
  maxTowMileLimit: number

  constructor(
    private store$: Store<AAAStore>,
    private xhr: XHRService,
    private window: WindowObject,
    private adapter: AARDataAdapter,
  ) {
    this.store$.pipe(select(selectModeConfiguration)).subscribe(modeConfiguration => this.modeConfiguration = modeConfiguration)
    this.store$.pipe(select(selectMaxTowMileLimit)).subscribe(maxTowMileLimit => this.maxTowMileLimit = maxTowMileLimit)
  }

  isRapUser(): boolean {
    const rapAppId = this.getRapAppId();
    return rapAppId !== undefined || this.modeConfiguration !== null;
  }

  getLimitWithinRadius(): number {
    return this.modeConfiguration?.limitWithinRadius;
  }

  getRapAppId(): string {
    return getOemBrandingId(this.window.getHref());
  }

  async getAarsFromTowDestination(aarRequestParams): Promise<IndexedCollection<AARData>> {
    try {
      const response = await this.xhr.authRequest<TowDestinationResponse<AarDetailsResponse>>({
        url: 'bff/tow-destinations/dealerships',
        params: {
          ...aarRequestParams,
          limit_beyond_radius: this.modeConfiguration?.limitBeyondRadius,
          limit_within_radius: this.modeConfiguration?.limitWithinRadius,
          ...(this.maxTowMileLimit > 0 ? { radius: this.maxTowMileLimit } : {}),
        },
      })
      const dealerships = await Promise.all(
        response.results.map(async (dealership) => await TowDestinationSchema.isValid(dealership) ? dealership : null)
      );
      const aarData = dealerships
        .filter((dealership) => dealership !== null)
        .map((dealerships) =>
          this.adapter.adaptTowDestination<AarDetailsResponse>(dealerships)
        );

      return indexCollection<AARData, 'id'>(aarData, 'id')
    } catch (error) {
      throw error
    }
  }

  isRapNotEligible = (activePaceSetterCode: PaceSetterCode, eligibility: Eligibility): boolean => {
    if(!eligibility?.entitlement?.length || !activePaceSetterCode) {
      return false
    }
    let paceSetterCode = activePaceSetterCode.paceSetterCode
    const override = PACE_SETTER_OVERRIDE_OPTIONS[activePaceSetterCode.overrideType]
    if (override) {
      paceSetterCode = getPacesetterCodeByValue(override.parentCode)
    }

    const entitlementType = activePaceSetterCode.requiresTowing
      ? ENTITLEMENT_TYPES.TOW
      : PACE_SETTER_SITUATION_CODE_ENTITLEMENT_TYPE[paceSetterCode];

    const entitlement = eligibility.entitlement
      .filter(e => e.type === entitlementType || entitlementType.includes(ENTITLEMENT_TYPES[e.type]))
    const entitlementAll = eligibility.entitlement.filter(e => e.type === ENTITLEMENT_TYPES.ALL)
    let rapEligible = false
    if (entitlement.length) {
      rapEligible = this.isEntitlementEligible(entitlement)
    } else if (entitlementAll.length) {
      rapEligible = this.isEntitlementEligible(entitlementAll)
    }
    return !rapEligible
  }

  isEntitlementEligible = (entitlements: Entitlement[]) =>
    Boolean(entitlements.find(e => e.isEligible !== false && (e?.remainingIncidents === undefined || e.remainingIncidents > 0)))
}
