<app-header-redesign>
  <app-back-button (aaaClick)="handleBack()" slot="start" />
  <app-title i18n>Vehicle Make</app-title>
</app-header-redesign>

<ion-content>
  <ion-grid class="ion-margin ion-no-padding">
    <aaa-text
      size="subheadline"
      weight="regular"
      color="subtle"
      i18n-aria-label
      class="ion-margin-bottom"
      aria-label="Select your vehicle's model"
      role="heading"
      aria-level="1"
      aria-live="polite">
      <ng-container i18n>Select your vehicle's make</ng-container>
    </aaa-text>

    <app-vehicle-header-step></app-vehicle-header-step>

    <ion-searchbar
      id="searchbar"
      type="text"
      class="custom"
      (ionFocus)="setIsSearching(true)"
      (ionBlur)="setIsSearching(false)"
      (ionInput)="onSearch($event)"
    />

    <div class="popular-makes" [class.hidden]="isSearching || searchInput">
      <div class="ion-padding-top ion-no-margin">
        <aaa-text i18n size="body" weight="bold" color="subtle">Popular Makes</aaa-text>
      </div>

      <app-vehicle-make-tiles
        [isLoading]="isLoading$ | async"
        [makes]="commonMakes"
        [selectedMake]="selectedMake"
        (select)="makeChange($event)"
      />
    </div>
  </ion-grid>

  <app-alphabet-scroll-list
    class="ion-margin-top"
    [isLoading]="isLoading$ | async"
    [items]="filteredMakes$ | async"
    [tabindex]="400 + (commonMakes.length + 2)"
    [selected]="selectedMake"
    [isHideAlphabet]="!!searchInput"
    (itemSelected)="makeChange($event)">
  </app-alphabet-scroll-list>
</ion-content>
