import { TowLocationState } from './tow-location.reducer'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { concatAddress, concatAddressLine1, concatAddressLine2 } from '../../../shared/utils/concatAddress'
import { NON_AAR_TOWING_NAMES } from './tow-location.actions'
import {
  GenericCoordinates,
  GoogleLocationMarker,
  LastSearchLocation,
  LocationWithMarker,
  SimpleLocation,
  TowLocation,
} from '../location.types'
import { selectModeConfiguration } from '../../auth/auth.selectors'
import { selectActivePaceSetterCode } from '../../issue/issue.selectors'
import { VendorConfigurationMode } from '../../auth/mode-configuration.types'
import { PACE_SETTER_SITUATION_CODES, PaceSetterCode } from '../../issue/issue.types'
import { isAddressComplete } from '../location.utils';
import { Option } from '../../ui/ui.types'

const stateKey = 'towLocation'

export const selectTowLocationState = createFeatureSelector<TowLocationState>(stateKey)

export const selectTowLocationAddress = createSelector(
  selectTowLocationState,
  (towLocationState): TowLocation => ({
    ...towLocationState,
    address: concatAddress(
      towLocationState,
      towLocationState.landmark ||
        towLocationState.name ||
        towLocationState.facility ||
        towLocationState.location ||
        ''
    ),
    isAar: towLocationState.hasOwnProperty('isAar')
      ? towLocationState.isAar
      : towLocationState.name !== NON_AAR_TOWING_NAMES.CUSTOM &&
        towLocationState.name !== NON_AAR_TOWING_NAMES.HOME,
  })
)

export const selectIsTowLocationValid = createSelector(
  selectTowLocationState,
  (towLocation: TowLocationState): boolean =>
    Boolean(towLocation.latitude) && Boolean(towLocation.longitude)
)

export const selectTowLocation = createSelector(
  selectTowLocationState,
  selectIsTowLocationValid,
  (towLocationState, isValid): TowLocation | null =>
    isValid ? towLocationState : null
)

export const selectTowLocationMarker = createSelector(
  selectTowLocation,
  (towLocation): GoogleLocationMarker | null =>
    towLocation !== null
      ? {
        address: concatAddress(
          towLocation,
          towLocation.name !== NON_AAR_TOWING_NAMES.CUSTOM
            ? towLocation.name
            : ''
        ),
        name: towLocation.name,
        isAar: towLocation.isAar,
        aarId: towLocation.id,
        lat: Number(towLocation.latitude),
        lng: Number(towLocation.longitude),
      }
      : null
)

export const selectTowLocationPreview = createSelector(
  selectTowLocationState,
  (towLocationState): LocationWithMarker | null => towLocationState?.preview
)

export const selectIsCustomTowLocationPreview = createSelector(
  selectTowLocationState,
  (towLocationState): boolean =>
    towLocationState?.preview?.location?.name === NON_AAR_TOWING_NAMES.CUSTOM
)

export const selectTowLocationCoordinates = createSelector(
  selectTowLocationPreview,
  (locationMarker: LocationWithMarker): GenericCoordinates => locationMarker ? {
    latitude: locationMarker.marker.lat,
    longitude: locationMarker.marker.lng,
  } : null
)

export const selectIsTowLocationPreviewValid = createSelector(
  selectTowLocationPreview,
  (towLocationPreview): boolean =>
    Boolean(towLocationPreview?.location?.latitude) &&
    Boolean(towLocationPreview?.location?.longitude)
)

export const selectTowLocationPreviewAddress = createSelector(
  selectTowLocationPreview,
  (preview): TowLocation | null =>
    preview?.location
      ? ({
        ...preview.location,
        address: concatAddress(preview.location, preview.location.name || ''),
        isAar:
            preview.location.isAar ||
            (preview.location.name !== NON_AAR_TOWING_NAMES.CUSTOM &&
              preview.location.name !== NON_AAR_TOWING_NAMES.HOME),
      } as TowLocation)
      : null
)

export const selectIsTowLocationPreviewAddressComplete = createSelector(
  selectTowLocationPreviewAddress,
  (preview): boolean => isAddressComplete(preview)
)

export const selectTowLocationPreviewMarker = createSelector(
  selectTowLocationPreview,
  (preview): GoogleLocationMarker | null =>
    preview?.marker
      ? {
        ...preview.marker,
        address:
            preview.marker.name !== NON_AAR_TOWING_NAMES.CUSTOM
              ? `${preview.marker.name} - ${preview.marker.address}`
              : preview.marker.address,
      }
      : null
)

export const selectAllowCustomTowDestination = createSelector(
  selectModeConfiguration,
  selectActivePaceSetterCode,
  (config: VendorConfigurationMode, paceSetterCode: PaceSetterCode): boolean => {
    if (!config) {
      return true
    }

    const isEv = paceSetterCode?.paceSetterCode === PACE_SETTER_SITUATION_CODES.T483
    return isEv ? config.allowEVChargeCustomTowDestination !== false : config.allowCustomTowDestination !== false
  }
)

export const selectLastTowToSearchLocation = createSelector(
  selectTowLocationState,
  (state: TowLocationState): LastSearchLocation => state.lastSearchLocation
)

export const selectTowingStep = createSelector(
  selectTowLocationState,
  (state: TowLocationState) => state.step
)

export const selectIsMapStep = createSelector(
  selectTowingStep,
  (step: string): boolean => step === 'tow-map' || step === 'passengers'
)

export const selectPassengersOption = createSelector(
  selectTowLocationState,
  (state: TowLocationState): Option => state.passengers
)

export const selectTowToSimpleLocation = createSelector(
  selectTowLocationState,
  (towLocationState): SimpleLocation => {
    const landmark = towLocationState.landmark || towLocationState.name || towLocationState.location
    const isStreetNumberName = towLocationState.streetNumber && towLocationState.streetName
    return towLocationState.address || towLocationState.streetAddress || towLocationState.fullAddress
      ? ({
        addressLine1: isStreetNumberName
          ? concatAddressLine1(towLocationState.streetNumber, towLocationState.streetName)
          : towLocationState.streetAddress,
        addressLine2: concatAddressLine2(towLocationState.city, towLocationState.state, towLocationState.postalCode, true),
        ...(landmark ? { landmark } : {})
      })
      : null
  }
)

export const selectIsTowSelectionConfirmed = createSelector(
  selectTowLocationState,
  (towLocationState: TowLocationState): boolean => towLocationState.towSelectionConfirmed
)
