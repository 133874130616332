import { NavigationExtras } from '@angular/router'
import { Action } from '@ngrx/store'
import { Observable } from 'rxjs'

export interface BasicAddressInfo {
  streetNumber?: string
  streetName: string
  city: string
  state: string
  postalCode?: string
}

export interface UserInfo {
  firstName: string
  lastName: string
}

export interface BasicContactInfo extends UserInfo {
  phone: string
}

export interface Collection<K, T> {
  [K: string]: T[]
}

export interface IndexedCollection<T> {
  [ID: string]: T
}

export interface AddressState {
  code: string
}

export interface Country {
  value: string
  code: string
}

export interface Address {
  addressLine: string
  cityName: string
  stateProv: AddressState
  postalCode: string
  countryName: Country
  type: string
}

export interface Phone {
  value?: string
  type?: string
}

export interface OperatingDay {
  day: string
  open: string
  close: string
}

export interface Shift {
  open?: string
  close?: string
}

export interface OperatingDayV2 {
  shifts?: Shift[]
  day?: string
}

export interface PayloadedAction<T = any> extends Action {
  payload?: T
}

export interface FSA<T = any, M = any> extends PayloadedAction<T> {
  meta?: M
}

export interface ActionRequestParams {
  retry?: boolean
  timeout?: number
  addCallFailure?: boolean
  eligible?: boolean
  ersAbuser?: boolean
}

export interface AsyncActionConstants {
  REQUEST: string
  FAILURE: string
  SUCCESS: string
  ACTION: string
}

export interface DraggerTarget {
  nativeElement: HTMLElement
}

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean>
}

// Default delay to dispatch the list of actions
// alowing the out animation to take place
export const TRANSITION_EVENT_DISPATCH_DELAY = 200
export interface TransitionEvent {
  dispatch?: Action[]
  delay?: number
}

export interface NavigationEvent {
  to: any[]
  extras?: NavigationExtras
}

export interface ErrorReportingMeta {
  callId?: string | number
}

export interface Adapter<T> {
  adapt(data: any): T
}

export enum PlusIndicatorValues {
  B = 'Basic/Classic',
  P = 'Plus',
  E = 'Premier',
  R = 'RV',
  V = 'Premier RV',
}

export interface ChargingLevelStyle {
  backgroundColor: string
  textColor: string
}

export enum EvStationVolts {
  V120 = 120,
  V240 = 240,
  V480 = 480,
}

export enum DestinationType {
  AAR = "AAR",
  TOW_DESTINATION = "ADDRESS",
  EV_STATION = "EV_STATION",
  DEALER = "DEALER"
}

export interface SelectorFactory<T> {
  memoized: (params: any) => T
  reset?: () => void
  setResult?: (result?: any) => void
  clearResult?: () => void
}

export interface ValidationFormComponent {
  isSecure$: Observable<boolean>
}

export enum DEFAULT_CONTAINER_ID_PER_PAGE {
  auth = '#credentials-entry',
  steps = '#wizard-content',
  dashboard = '#dashboard-content',
}
