import {
  Component,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { US_STATES } from './input-us-state.constants';
import { select, Store } from '@ngrx/store';
import { AAAStore } from '../../../store/root-reducer';
import { FormGroupState } from 'ngrx-forms';
import { PlateToVinFormState } from '../../../modules/ui/forms/forms.reducer';
import { selectPlateToVinForm } from '../../../modules/ui/forms/forms.selectors';

const US_STATES_OPTIONS = Object.keys(US_STATES);

@Component({
  selector: 'app-input-us-state',
  templateUrl: './input-us-state.component.html',
  styleUrls: ['./input-us-state.component.scss'],
})
export class InputUsStateComponent {
  usStatesOptions$ = of(US_STATES_OPTIONS)
  selectedUsState: string
  isModalOpen = false

  form$: Observable<FormGroupState<PlateToVinFormState>> =
    this.store$.pipe(select(selectPlateToVinForm))

  @Input() disabled = false
  @Input() error = false

  @Input()
  get selection() {
    return this.selectedUsState
  }
  set selection(value) {
    Object.entries(US_STATES).forEach(([key, val]) => {
      if (val === value) {
        this.selectedUsState = key
      }
    })
  }

  @Output() selectedUsStateChange: EventEmitter<string> =
    new EventEmitter<string>()

  constructor(private store$: Store<AAAStore>) {}

  setOpen(isOpen: boolean): void {
    this.isModalOpen = isOpen;
  }

  selectState(state: string): void {
    this.handleChange(state)
    this.setOpen(false)
  }

  handleChange(value: string) {
    if (value !== this.selectedUsState) {
      this.selectedUsState = value
      this.store$.dispatch({
        controlId: 'form.plateToVin.state',
        value: this.selectedUsState,
        type: 'ngrx/forms/SET_VALUE',
      })
      this.selectedUsStateChange.emit(this.selectedUsState)
    }
  }
}
