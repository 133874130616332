<ion-card class="ion-no-margin first-card">
  <ion-grid>
    <ion-row>
      <ion-col size="6">
        <ion-list lines="none" class="ion-no-padding">
          <ion-list-header color="dark" class="ion-no-padding">
            <ion-label i18n>AAA Shops provide:</ion-label>
          </ion-list-header>
          <ion-item color="dark" class="ion-no-margin ion-no-padding" *ngFor="let item of shopBenefits()">
            <aaa-icon aria-hidden="true" name="check" slot="start"></aaa-icon>
            <ion-label>{{ item }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
      <ion-col size="6">
        <app-img i18n-alt
          src="assets/drrweb-lib/images/tow-truck_x2.png"
          alt="AAA Tow Truck">
        </app-img>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-card>

<ion-card class="ion-no-margin second-card">
  <ion-grid>
    <ion-row>
      <ion-col size="6">
        <aaa-text weight="regular" color="light" i18n>Up to</aaa-text>
        <aaa-text weight="bold" i18n color="light" display="inline" size="title">$75 </aaa-text>
        <aaa-text weight="bold" i18n color="light" display="inline" size="title2">off labor</aaa-text>
        <aaa-text weight="regular" color="light" i18n>AAA Members receive 10% off labor (up to $75) at AAA Repair facilities.</aaa-text>
      </ion-col>
      <ion-col offset="1"></ion-col>
      <ion-col size="5" class="logo-container">
        <div class="logo">
          <icon-aaa-logo primary="#FFA59E" tertiary="#FFA59E" class="logo"></icon-aaa-logo>
        </div>
        <ng-container *ngIf="!(isCouponApplied$ | async) else iconTemplate">
          <aaa-button [loading]="isLoading" id="open-toast" (click)="applyCoupon()" size="small" i18n>{{ isLoading ? '' : 'Apply Coupon' }}</aaa-button>
        </ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-card>

<ng-template #iconTemplate>
  <aaa-button [disabled]="true" variant="on-map" size="small">
    <aaa-icon aria-hidden="true" name="check" color="tertiary" slot="start"></aaa-icon>
  </aaa-button>
</ng-template>

<ion-toast class="tow-to-coupon-toast" [isOpen]="isToastOpen" i18n-message message="Coupon applied! Select a AAA Shop to continue." icon="checkmark-circle-outline" duration="3000"></ion-toast>
