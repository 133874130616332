<div class="white-label-credentials">
  <form
    id="signon-form"
    (ngSubmit)="checkFieldsAndSubmit()"
    class="white-label-credentials_form"
    [ngrxFormState]="formState"
  >

    <ng-template formMode></ng-template>
    <div>
      <ng-container *ngIf="!(captchaModalOpen$ | async) && !hideCatchaInPage">
        <ng-container *ngIf="captchaVersion === 'V2'">
          <re-captcha
            *ngIf="!(isOdometerConfirmation$ | async)"
            #captchaElement
            [siteKey]="siteKey"
            (resolved)="handleCaptchaSuccess($event, false)">
          </re-captcha>
        </ng-container>
      </ng-container>
      <app-form-helper
        i18n
        *ngIf="captchaError"
      >
        You must confirm you are not a robot.
      </app-form-helper>
      <aaa-button
        i18n
        id="btn-confirm-default"
        type="submit"
        [disabled]="(isSigningIn$ | async)"
        [loading]="(isSigningIn$ | async) === true"
        expand="block"
        variant="primary"
        [tabIndex]="(12 + tabIndexPosition)"
      >
        Confirm
      </aaa-button>
    </div>
  </form>

  <ion-row class="ion-margin">
    <ion-col class="ion-margin-bottom ion-text-center">
      <aaa-text
        i18n
        class="minimal-credentials_terms ion-margin-bottom"
        align="center"
        color="dark"
        size="caption"
        weight="regular"
        display="block"
      >
        By selecting Confirm, you accept the
      </aaa-text>
      <aaa-text
        class="minimal-credentials_terms"
        color="danger"
        align="center"
        size="caption"
        weight="bold"
        display="inline"
        role="navigation"
        aria-labelledby="terms"
      >
        <aria-link-button
          i18n
          (clickLink)="openTerms()"
          [tabIndex]="(24 + tabIndexPosition)"
          class="terms-and-conditions__link"
        >
          Terms and Conditions
        </aria-link-button>
      </aaa-text>
      <ng-container *ngIf="!(isSecure$ | async) && isWebApp()">
        <aaa-text
          i18n
          color="dark"
          size="caption"
          weight="light"
          display="inline"
        > and
        </aaa-text>
        <aaa-text
          class="privacy-policy"
          id="privacy-policy"
          color="danger"
          size="caption"
          weight="bold"
          display="inline"
          aria-labelledby="privacy-policy"
          role="navigation"
        >
          <aria-link-button
            i18n
            (clickLink)="openPrivacyPolicy()"
            class="privacy-policy__link"
            [tabIndex]="(25 + tabIndexPosition)"
          >
            Privacy Policy
          </aria-link-button>
        </aaa-text>
      </ng-container>
      <ng-container *ngIf="captchaVersion === 'V3'">
        <drr-captcha-terms></drr-captcha-terms>
      </ng-container>
    </ion-col>
  </ion-row>
  <drr-captcha-modal [isOpen]="(captchaModalOpen$ | async)" (onDismiss)="handleCaptchaModalDismiss()">
    <div captcha>
      <ng-container *ngIf="captchaVersion === 'V2'">
        <re-captcha
          *ngIf="!(isOdometerConfirmation$ | async)"
          #captchaElement
          [siteKey]="siteKey"
          (resolved)="handleCaptchaSuccess($event, true)">
        </re-captcha>
      </ng-container>
    </div>
  </drr-captcha-modal>
</div>
