<aaa-modal
  contentMode="fit"
  i18n-title
  [title]="'Verification Required'"
  i18n-subtitle
  [subtitle]="'To ensure the security of our platform, please verify that you are not a robot by completing the CAPTCHA below.'"
  [isOpen]="isOpen"
  [initialBreakpoint]="1"
  (aaaModalDidDismiss)="handleDismiss($event)"
  [breakpoints]="[0, 1]"
>
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col class="captcha">
        <ng-content select="[captcha]"></ng-content>
      </ion-col>
    </ion-row>
  </ion-grid>
</aaa-modal>
