import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Observable } from 'rxjs'
import { FormGroupState } from 'ngrx-forms'
import { PlateToVinFormState } from '../../../modules/ui/forms/forms.reducer'
import { select, Store } from '@ngrx/store'
import { selectPlateToVinForm } from '../../../modules/ui/forms/forms.selectors'
import { AAAStore } from '../../../store/root-reducer'

@Component({
  selector: 'app-input-license-plate',
  templateUrl: './input-license-plate.component.html',
  styleUrls: ['./input-license-plate.component.scss'],
})
export class InputLicensePlateComponent {
  licensePlate: string = ''
  private previousLicensePlate: string = ''

  form$: Observable<FormGroupState<PlateToVinFormState>> =
    this.store$.pipe(select(selectPlateToVinForm))

  @Input() disabled: boolean
  @Input() error: boolean
  @Output() licensePlateChange: EventEmitter<string> = new EventEmitter<string>()

  constructor(private store$: Store<AAAStore>) {}

  sanitizeInput(value: string): string {
    return value.replace(/[^a-zA-Z0-9]|\s{2,}/g, '').toUpperCase()
  }

  updateLicensePlate(newValue: string): void {
    const sanitizedValue = this.sanitizeInput(newValue)
    if (sanitizedValue !== this.previousLicensePlate && sanitizedValue.length >= 6) {
      this.licensePlate = sanitizedValue
      this.previousLicensePlate = sanitizedValue
      this.licensePlateChange.emit(this.licensePlate)
    }
  }

  handleBlurText(): void {
    this.updateLicensePlate(this.licensePlate)
  }

  onKeyup(event: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement
    const cursorPosition = inputElement.selectionStart || 0
    const sanitizedValue = this.sanitizeInput(inputElement.value)

    // Update the input value in place
    inputElement.value = sanitizedValue
    inputElement.setSelectionRange(cursorPosition, cursorPosition)

    // Update the local licensePlate value
    this.licensePlate = sanitizedValue
  }
}
