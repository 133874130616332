<div class="input-license-plate">
  <aaa-input
    [disabled]="disabled"
    autoComplete="on"
    [variant]="error || ((form$ | async).controls.licensePlate.errors?.pattern || (form$ | async).controls.licensePlate.errors?.required) && (form$ | async).controls.licensePlate.isTouched ? 'danger' : 'secondary'"
    id="licensePlate"
    [ngrxFormControlState]="(form$ | async).controls.licensePlate"
    i18n-label
    label="License Plate"
    [tabIndex]="1"
    (aaaBlur)="handleBlurText()"
    (keyup)="onKeyup($event)"
    mask="[/[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/, /[0-9a-zA-Z]/]"
  />
</div>
