import { Eligibility } from './eligibility.types';

export interface ConfigAuthParams {
  isSecure?: boolean
  method?: AuthMethods
}

export interface BaseAuthRequestParams extends ConfigAuthParams {
  captchaToken?: string
  captchaVersion?: string
  firstName?: string
  lastName?: string
  memberNumber?: string
  isMembershipNumberInNames?: boolean
}

export interface AuthBypassRequestParams {
  token: string
  club: string
}

export interface SecureAuthRequestParams {
  memId: string
  ets: string
  vendor?: string
}

export interface MembershipNumberAuthRequestParams {
  memberNumber: string
}

export interface MemberNumberAuthRequestParams {
  memberNumber: string
  zipCode: string
  rememberMe?: boolean
}

export interface VasAuthRequestParams {
  subscriber: string | number
  referenceId: string
}
export interface RentalAuthRequestParams {
  refNumber: string
  referenceId: string
}
export interface OemAuthRequestParams extends BaseAuthRequestParams {
  vin: string
  mileage: number
  manufacturer: string
  referenceId: string
}

export type AuthRequestParams = BaseAuthRequestParams &
  (
    | MemberNumberAuthRequestParams
    | NameAuthRequestParams
    | SecureAuthRequestParams
    | VasAuthRequestParams
    | RentalAuthRequestParams
    | OemAuthRequestParams
    | SearchIdPhoneNumberParams
  )

export interface SetAuthParams {
  club: string
  access_token: string
  no_routing?: boolean
}

export interface SetRapAuthParams extends ConfigAuthParams {
  access_token: string,
  odometer?: number
}

export interface ConfirmOdometerParams extends SetRapAuthParams {
  odometer?: number
}

export interface NameAuthRequestParams {
  firstName: string
  lastName: string
  zipCode: string
}

export interface AuthResponse extends ConfigAuthParams {
  club?: string
  expires_in: number
  access_token: string
  scope: string
  latitude?: number
  longitude?: number
}

export interface RedirectParams {
  commands: string[]
  extras: any
}

export interface AuthNoRoutingResponse extends ConfigAuthParams {
  success: boolean
  redirectParams: RedirectParams
  message?: string
}

export interface RapAuthResponse extends AuthResponse {
  eligibility: Eligibility
}

export enum AuthMethods {
  AAA_NATIONAL = 'AAA_NATIONAL',
  AAA_TOKEN = 'AAA_TOKEN',
  MEMBERSHIP_NUMBER = 'MEMBERSHIP_NUMBER',
  MEMBER_NAME = 'MEMBER_NAME',
  ENCRYPTED_MEMBER_NUMBER = 'ENCRYPTED_MEMBER_NUMBER',
  OEM = 'OEM',
  RENTAL = 'RENTAL',
  VAS = 'VAS',
  RAP_TOKEN = 'RAP_TOKEN',
}

export interface SearchSigninParams {
  resultId: string
  searchId: string
}

export interface SearchInfoSigninParams {
  searchId: string,
  firstName?: string,
  lastName?: string,
  streetNumber?: string
}

export interface SearchIdPhoneNumberParams {
  searchId: string,
  phoneNumber: string,
}

export enum AppId {
  IOSMOBILE = 'IOSMOBILE',
  ANDROIDMOBILE = 'ANDROIDMOBILE',
  ACEAPP = 'ACEAPP',
  ACEAPP_IOS = 'ACEAPP-IOS',
  ACEAPP_ANDROID = 'ACEAPP-ANDROID',
  AGENT = 'AGENT',
  BQ = 'BQ',
  SEO = 'SEO',
  _800SMS = '800SMS',
  IVR = 'IVR',
  TTP = 'TTP',
  MONITOR = 'MONITOR',
  WALLET = 'WALLET',
  MWGSMS = 'MWGSMS',
  BLUESKYDRR = 'BLUESKYDRR',
}

export enum AppWhiteLabelId {
  HYUNDAI = 'HYUNDAI',
  GENESIS = 'GENESIS',
  HONDA = 'HONDA',
  ACURA = 'ACURA',
  TOYOTA = 'TOYOTA',
  HERTZ = 'HERTZ',
  CUSTOM = 'CUSTOM',
  BLUESKYNM = 'BLUESKYNM',
  GMC = 'GMC',
  BUICK = 'BUICK',
  ONSTAR = 'ONSTAR',
  CHEVROLET = 'CHEVROLET',
  CADILLAC = 'CADILLAC',
  MAZDA = 'MAZDA',
  INFINITI = 'INFINITI',
  NISSAN = 'NISSAN',
}

export enum OEMBranding {
  HYUNDAI = 'HYUNDAI',
  GENESIS = 'GENESIS',
  HONDA = 'HONDA',
  ACURA = 'ACURA',
  BLUESKYNM = 'BLUESKYNM',
  GMC = 'GMC',
  BUICK = 'BUICK',
  ONSTAR = 'ONSTAR',
  CHEVROLET = 'CHEVROLET',
  CADILLAC = 'CADILLAC',
  MAZDA = 'MAZDA',
  INFINITI = 'INFINITI',
  NISSAN = 'NISSAN',
}

export enum AgentAppIds {
  BLUESKYNM = 'BLUESKYNM',
  BLUESKYDRR = 'BLUESKYDRR',
}

export interface LoginFailAssistantError {
  message: string,
  cta: string,
  link: string,
  secondLink: string,
  event?: () => void,
  secondEvent?: () => void,
  href?: string,
  icon: string,
  cssClass: string,
}

export interface AgentSettingsParams {
  agentFirstName?: string
  agentLastName?: string
}

export enum RapAuthFailure {
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  GENERIC = 'GENERIC',
  OEM_SERVER_ERROR = 'OEM_SERVER_ERROR',
  RESTRICT_MODEL = 'RESTRICT_MODEL',
}
