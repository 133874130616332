import { Inject, Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { XHRService } from '../../shared/services/xhr.service';
import { AAACallDataAdapter } from '../../shared/adapters/AAACallDataAdapter';
import { selectIsEligible } from '../member/member.selectors';
import { RapService } from '../rap/rap.service';
import { openMessageDialog } from '../ui/ui.actions';
import { MessageDialogTypes, StepTypes } from '../ui/ui.types';
import { CallDetailParams } from './calls-statuses/call-status.types';
import { CallResponseSchema, ClubSupportSchema } from './calls.model';
import { AAACallData, CallResponse, ClubSupport } from './calls.types';
import { selectCreateCallTimeout, selectGetActiveCallsTimeout } from '../ui/timeout/timeout.selectors'
import { DRR_BASE_HREF } from '../../shared/shared.config';
import { RouteTypes } from '../main-router.module';


@Injectable({
  providedIn: 'root',
})
export class CallsService {

  isEligible$: Observable<boolean> = this.store$.pipe(
    select(selectIsEligible)
  )
  eligible = false;

  getActiveCallsTimeout$: Observable<number> = this.store$.pipe(
    select(selectGetActiveCallsTimeout)
  )
  getActiveCallsTimeout;

  createCallTimeout$: Observable<number> = this.store$.pipe(
    select(selectCreateCallTimeout)
  )
  createCallTimeout;

  constructor(
    private xhr: XHRService,
    private store$: Store,
    private callAdapter: AAACallDataAdapter,
    private router: Router,
    private rapService: RapService,
    @Inject(DRR_BASE_HREF) private drrBaseHref: string
  ) {
    this.isEligible$.subscribe(eligible => {
      this.eligible = eligible
    })
    this.getActiveCallsTimeout$.subscribe(timeout => {
      this.getActiveCallsTimeout = timeout
    })
    this.createCallTimeout$.subscribe(timeout => {
      this.createCallTimeout = timeout
    })
  }

  async addCall(callData: AAACallData): Promise<Pick<CallResponse, 'callKey'> | void> {
    try {
      const data = this.callAdapter.adapt(callData)

      let response = await this.xhr.clubRequest<Pick<CallResponse, 'callKey'>>({
        url: 'bff/roadservice/calls',
        method: 'POST',
        data,
        timeout: this.createCallTimeout
      })
      response = await CallResponseSchema.validate(response)
      return this.callAdapter.adaptResponse(response)
    } catch (error) {
      if (
        (error?.response?.status === 400 || error?.response?.status === 409) &&
        error?.response?.data?.error === 'Duplicate Call'
      ) {
        this.store$.dispatch(
          openMessageDialog({
            payload: { type: MessageDialogTypes.CALL_DUPLICATE },
          })
        )
      }

      throw error
    }
  }

  async addPartialCall(callData: AAACallData): Promise<void> {
    try {
      const data = this.callAdapter.adapt(callData)

      await this.xhr.clubRequest<void>({
        url: 'bff/roadservice/calls/partial',
        method: 'POST',
        data,
      },
      { retryAll: false })
    } catch (error) {
      throw error
    }
  }

  async getClubSupport(club) {
    try {
      const response = await this.xhr.clubRequest<ClubSupport>({
        url: `v2/roadservice/tracking/support/${club}`,
      })
      await ClubSupportSchema.validate(response)
      return response
    } catch (error) {
      throw error
    }
  }

  async cancelCall(callKey: string): Promise<void> {
    try {
      await this.xhr.clubRequest({
        url: `bff/roadservice/calls/${callKey}`,
        method: 'DELETE',
      })
    } catch (error) {
      throw error
    }
  }

  async getActiveCalls(
    timeout: number = this.getActiveCallsTimeout,
    addCallFailure: boolean = false
  ) {
    try {
      const response = await this.getOrRedirectToLocationWithinTimeout('v2/roadservice/calls/active', timeout, addCallFailure)
      return response
    } catch (error) {
      throw error
    }
  }

  async getOrRedirectToLocationWithinTimeout(url, timeout, addCallFailure = false) {
    let response
    let isTimeout
    const redirectTo = () => {
      if (timeout && timeout > 0 && !response && !addCallFailure) {
        if (this.eligible) {
          this.router.navigate([this.drrBaseHref, RouteTypes.STEPS], {
            queryParams: { step: StepTypes.BREAKDOWN_LOCATION },
          })
        } else {
          this.router.navigate([
            this.drrBaseHref,
            ...RouteTypes.SIGNIN.split('/'),
          ])
        }
      }
    }
    [isTimeout, response] = await Promise.all([
      setTimeout(() => {
        redirectTo()
      }, timeout),
      this.xhr.clubRequest<any>(
        {
          url
        },
        {
          retryAll: false,
        }
      ),
    ])
    return response
  }

  async getCallDetails(
    data: CallDetailParams,
    retry: boolean
  ) {
    try {
      const response = await this.xhr.clubRequest<any>({
        url: 'v2/roadservice/call/detail',
        method: 'POST',
        data
      }, {
        retryAll: retry
      })
      // TODO validate response schema
      // await CallDetails.validate(response)
      return response
    } catch (error) {
      throw error
    }
  }
}
