export const TITLE_SIGN_IN = () => $localize`Sign In`
export const TITLE_SUBMIT = () => $localize`Review and submit`
export const TITLE_SERVICE_TRACKING = () => $localize`Service Tracking`
export const MESSAGE_WE_ARE_SORRY = () => $localize`We're Sorry!`
export const CLOSED_LABEL = () => $localize`Closed`
export const MAX_INTEGER = 2147483647
export const CA_COUNTRY = 'CA'
export const CAA_ASSOCIATION = 'CAA'
export const CHARACTER_LIMIT_FIRST_LAST_NAME = 15
export const CHECKBOX_RIPPLE_DURATION = 225
