<ion-grid>
  <ion-row>
    @if (!isLoading) {
      <ion-col *ngFor="let make of makes; let index = index" size="1">
        <button
          [attr.aria-label]="vehicleMakeTilesLabel + ' - ' +  make.label"
          type="button"
          class="tile"
          [tabindex]="400 + index"
          [ngClass]="{
          'first-focusable-element': index === 0,
          'selected': make.label === selectedMake
        }"
          (click)="onMakeSelect(make)"
        >
          <app-vehicle-make-logo [make]="make"></app-vehicle-make-logo>

          <div class="checkmark" *ngIf="make.label === selectedMake">
            <aaa-icon name="checkmark-outline" size="small"></aaa-icon>
          </div>
        </button>
      </ion-col>
    } @else {
      <ion-col *ngFor="let index of [].constructor(15); let i = index" size="1">
        <ion-thumbnail class="tile">
          <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        </ion-thumbnail>
      </ion-col>
    }
  </ion-row>
</ion-grid>
