import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalComponent } from '@aaa-mobile/shared-components'
import { IonicModule } from '@ionic/angular'

@Component({
  selector: 'drr-captcha-modal',
  templateUrl: './captcha-modal.component.html',
  styleUrls: ['./captcha-modal.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    ModalComponent,
  ]
})
export class CaptchaModalComponent {
  @Input() isOpen: boolean;
  @Output() onDismiss: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  handleDismiss(event: CustomEvent) {
    if (['backdrop', 'gesture'].includes(event.detail.role)) {
      this.onDismiss.emit();
    }
  }
}
