import { Injectable } from '@angular/core'
import { Adapter, DestinationType } from '../types'
import { AAACallData, AAACallDataAdapted, CallResponse, CallType } from '../../modules/dashboard/calls.types'
import { getCookie } from '../utils/cookies'
import { CHARACTER_LIMIT_COMMENTS, CHARACTER_LIMIT_COMMENTS_RAP } from '../../modules/dashboard/calls.constants'
import { RapService } from '../../modules/rap/rap.service'
import { VehicleAdapter } from "./VehicleAdapter";
import { IssueType } from '../../modules/issue/issue.types'
import { unmaskNumericField } from '../../modules/ui/ui.utils'

@Injectable({
  providedIn: 'root',
})
export class AAACallDataAdapter implements Adapter<AAACallDataAdapted> {

  constructor(
    private rapService: RapService,
    private vehicleAdapter: VehicleAdapter,
  ) {}

  adapt(data: AAACallData): AAACallDataAdapted {
    const isRapUser = this.rapService.isRapUser()
    const appId = getCookie('AAA_AppId');
    const callType = isRapUser ? CallType.RAP : CallType.MEMBER
    const characterLimitComments = isRapUser
      ? CHARACTER_LIMIT_COMMENTS_RAP
      : CHARACTER_LIMIT_COMMENTS;

    const comments = [
      `DRRWeb 2.0`,
      appId,
      data.channel || '',
      data.comments || '',
    ]
      .join(' ')
      .replace(/  +/g, ' ')
      .trim()
      .substring(0, characterLimitComments);

    const breakDownStAddress = `${ data?.breakdownLocation?.street || '' }`.trim();
    const towingStAddress = `${ data?.towing?.streetName || '' }`.trim();
    const countryFormatted = data.towing?.country === 'USA' ? 'US' : data.towing?.country
    const towId = (data.towing?.aarId || data.towing?.id) ? `${data.towing.aarId || data.towing.id}` : null

    const towType = (
      data.situationCodes.issueType === IssueType.FUEL_ELECTRIC
        ? DestinationType.EV_STATION
        : isRapUser
          ? DestinationType.DEALER
          : data.towing?.aarId
            ? DestinationType.AAR
            : DestinationType.TOW_DESTINATION
    )

    return {
      callType: callType,
      ...( data.contactInfo.phone
        ? {
          contact: {
            firstName: data.contactInfo.firstName,
            lastName: data.contactInfo.lastName,
            contactType: "CUSTOMER",
            phones: [
              {
                phoneNumber: unmaskNumericField(data.contactInfo.phone),
                preferred: true,
                phoneType: "CELLULAR",
                smsOptIn: data.contactInfo.enableSMS,
              },
            ],
          }
        } : null ),
      ...( data.vehicle.model ? { vehicle: this.vehicleAdapter.adaptCallVehicle(data.vehicle) } : null ),
      ...( data.situationCodes.issueType
        ? {
          service: {
            ...data.situationCodes,
            issueType: data.situationCodes.issueType,
            ...( data?.priorityCode ? { priorityCode: data?.priorityCode } : null),
            ...( data?.situationCodes.priorityCodeType ? { priorityCodeType: data?.situationCodes.priorityCodeType } : null),
            ...( data?.numberOfPassengers ? { numberOfPassengers: data.numberOfPassengers } : null),
            collision: data.situationCodes.issueType === IssueType.ACCIDENT,
            ...( data?.towing ? { flatbedRequested:  data.vehicle.driveType === "4WD" || data.vehicle.driveType === "AWD" } : null)
          }
        } : null ),
      breakdownLocation: {
        ...data.breakdownLocation,
        streetNumber: data.breakdownLocation.streetNumber,
        streetName: breakDownStAddress,
        city: data.breakdownLocation.city.toUpperCase(),
        state: data.breakdownLocation.state,
        postalCode: data.breakdownLocation.zip,
        country: data.breakdownLocation.country,
        latitude: data.breakdownLocation.latitude,
        longitude: data.breakdownLocation.longitude,
      },
      ...( data?.towing
        ? {
          towDestination: {
            ...(towId ? { id: towId } : null),
            ...(towId ? { name: data.towing?.facility || data.towing?.name } : null),
            ...( data.towing.serviceProviderCode ? { serviceProviderCode: data.towing?.serviceProviderCode } : null),
            streetNumber: data.towing?.streetNumber,
            streetName: towingStAddress,
            city: data.towing.city.toUpperCase(),
            state: data.towing.state,
            latitude: Number(data.towing.latitude || data.towing.lat),
            longitude: Number(data.towing.longitude || data.towing.lng),
            postalCode: data.towing.zip || data.towing.postalCode,
            country: countryFormatted,
            emailAddress: data.towing?.emailAddress,
            type: towType,
          }
        }
        : null ),
      ...(isRapUser && data?.eligibility ? { eligibility: data.eligibility } : null ),
      comments: [
        {
          text: comments,
        },
      ],
    };
  }

  adaptResponse(data: Pick<CallResponse, 'callKey'>): CallResponse {
    if (data.callKey) {
      const callKeyParts = data.callKey?.split('-');
      const servicingClub = callKeyParts[0];
      const callDate = callKeyParts[1];
      const callId = callKeyParts[2];
      return {
        ...data,
        callDate,
        callId,
        servicingClub,
      }
    } else {
      throw new Error('No payload response from call create')
    }
  }
}
