import {
  AUTH,
  AUTH_NAME_SEARCH,
  AUTH_OEM,
  AUTH_RENTAL,
  AUTH_RESET,
  AUTH_VAS,
  INCREMENT_ERROR_COUNT,
  SET_AGENT_FIRST_NAME,
  SET_AGENT_LAST_NAME,
  SET_AUTH,
  SET_AUTH_METHOD,
  SET_CLICKED_USE_ANOTHER_AUTH_FORM,
  SET_ENCRYPTED_MEMBER_NUMBER,
  SET_LAST_SEARCH_ID,
  SET_RAP_AUTH,
  SET_RAP_CONFIRM_ODOMETER,
  SET_SECURE_PARAMS,
} from './auth.actions'
import { PayloadedAction } from '../../shared/types'
import { AgentSettingsParams, AuthMethods } from './auth.types'
import { LOAD_MODE_CONFIGURATION } from './mode-configuration.actions'
import { VendorConfigurationMode } from './mode-configuration.types'
import { Eligibility } from './eligibility.types'

export interface AuthState {
  club: string
  accessToken: string
  noRouting?: boolean
  expiresIn: number
  isSecure: boolean
  method: AuthMethods
  memberNumber?: string
  memId?: string
  encryptedMemberNumber?: string
  ets?: string
  odometer?: number
  agentSettings?: AgentSettingsParams
  modeConfig: VendorConfigurationMode
  eligibility?: Eligibility
  isRapTokenAccess: boolean
  errorCount: number
  isUseAnotherFormClickedAfterError: boolean
  lastSearchId: string
}

export const initialState: AuthState = {
  club: '',
  accessToken: '',
  noRouting: false,
  expiresIn: 0,
  isSecure: false,
  agentSettings: null,
  method: AuthMethods.MEMBER_NAME,
  modeConfig: null,
  isRapTokenAccess: false,
  errorCount: 0,
  isUseAnotherFormClickedAfterError: false,
  lastSearchId: null,
}

export function reducer(
  state: AuthState | undefined = initialState,
  action: PayloadedAction
): AuthState {
  switch (action.type) {
    case SET_SECURE_PARAMS:
      return {
        ...state,
        memId: action.payload.memId,
        ets: action.payload.ets,
        isSecure: true,
        method: AuthMethods.AAA_NATIONAL,
      }
    case SET_ENCRYPTED_MEMBER_NUMBER:
      return {
        ...state,
        encryptedMemberNumber: action.payload.memberNumber,
        isSecure: true,
        method: AuthMethods.ENCRYPTED_MEMBER_NUMBER,
      }
    case SET_AUTH:
      return {
        ...state,
        club: action.payload.club,
        accessToken: action.payload.access_token,
        isSecure: true,
        method: AuthMethods.AAA_TOKEN,
        ...(action.payload.no_routing !== undefined ? { noRouting: action.payload.no_routing } : {}),
      }
    case SET_RAP_AUTH.REQUEST:
      return {
        ...state,
        accessToken: action.payload.access_token,
        isSecure: action.payload.isSecure,
        method: action.payload.method,
        isRapTokenAccess: !action.payload.odometer,
      }
    case SET_RAP_CONFIRM_ODOMETER:
      return {
        ...state,
        accessToken: action.payload.access_token,
        isSecure: action.payload.isSecure,
        method: action.payload.method,
        odometer: action.payload.odometer,
        isRapTokenAccess: false,
      }
    case SET_AUTH_METHOD:
      return {
        ...state,
        method: action.payload,
      }

    case LOAD_MODE_CONFIGURATION.SUCCESS:
      return {
        ...state,
        modeConfig: action.payload,
      }

    case AUTH_OEM.SUCCESS:
    case AUTH_RENTAL.SUCCESS:
    case AUTH_VAS.SUCCESS:
      return {
        ...state,
        accessToken: action.payload.access_token,
        expiresIn: action.payload.expires_in,
        isSecure: action.payload.isSecure,
        method: action.payload.method || state.method,
        eligibility: action.payload.eligibility,
        club: action.payload.club,
      }

    case AUTH.SUCCESS:
    case AUTH_NAME_SEARCH.SUCCESS:
      return {
        ...state,
        club: action.payload.club,
        accessToken: action.payload.access_token,
        expiresIn: action.payload.expires_in,
        isSecure: action.payload.isSecure,
        method: action.payload.method || state.method,
      }
    case AUTH.REQUEST:
      return {
        ...state,
        isSecure: action.payload.isSecure,
        memberNumber: action.payload.memberNumber,
        method: action.payload.method || state.method,
      }
    case AUTH.FAILURE:
      return {
        ...state,
        isSecure: false,
      }
    case AUTH_RESET:
      return {
        ...initialState,
        modeConfig: state.modeConfig,
        method: state.method,
        isRapTokenAccess: state.isRapTokenAccess,
        errorCount: state.errorCount,
        isUseAnotherFormClickedAfterError:
          state.isUseAnotherFormClickedAfterError,
        noRouting: state.noRouting,
      }
    case INCREMENT_ERROR_COUNT:
      return {
        ...state,
        errorCount: state.errorCount + 1,
      }
    case SET_CLICKED_USE_ANOTHER_AUTH_FORM:
      return {
        ...state,
        isUseAnotherFormClickedAfterError: state.errorCount > 0,
      }
    case SET_AGENT_FIRST_NAME:
      return {
        ...state,
        agentSettings: {
          ...state.agentSettings,
          agentFirstName: action.payload,
        },
      }
    case SET_AGENT_LAST_NAME:
      return {
        ...state,
        agentSettings: {
          ...state.agentSettings,
          agentLastName: action.payload,
        },
      }
    case SET_LAST_SEARCH_ID:
      return {
        ...state,
        lastSearchId: action.payload,
      }
    default:
      return state
  }
}
