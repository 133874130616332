import { createFeatureSelector, createSelector } from '@ngrx/store'

import { AAACallState } from './calls.reducer'
import { BasicAddressInfo, UserInfo } from '../../shared/types'
import { BreakdownLocation, TowLocation, } from '../location/location.types'
import { AAACallData } from './calls.types'
import { selectAuthClub, selectEligibility } from '../auth/auth.selectors'

import { selectMemberActiveVehicle, selectMemberActiveVehicleNotes, selectMemberData, } from '../member/member.selectors'
import { concatAddress } from '../../shared/utils'
import {
  selectActiveAdditionalRequirement,
  selectActivePaceSetterCode,
  selectPaceSetterNotesAndComments,
} from '../issue/issue.selectors'
import { selectTowLocation } from '../location/tow-location/tow-location.selectors'
import {
  selectBreakdownLocationDetails,
  selectBreakdownLocationParams,
  selectHighwayExit,
} from '../location/location.selectors'
import { selectCommentSummary, selectMergedPassengersComments, selectUserContactInfo, } from '../submit/submit.selectors'
import { CHARACTER_LIMIT_FACILITY, CHARACTER_LIMIT_VEHICLE_COLOR, } from '../location/location.constants'
import { VehicleDriveTypes } from '../vehicle/vehicle.types'
import { Vehicle } from '../member/member.types'
import { getCurrentLocale } from '../../shared/i18n/i18n.utils'
import { mapPaceSetterToIssueType, mappingPriorityCodeType } from './calls.utils'
import { priorityCodeType } from '../issue/issue.types'

const stateKey = 'call'

export const selectCallState = createFeatureSelector<AAACallState>(stateKey)

export const selectCallBreakdownLocation = createSelector(
  selectCallState,
  (state: AAACallState): BreakdownLocation => state.breakdownLocation
)

export const selectCallBreakdownLocationAddress = createSelector(
  selectCallState,
  (state: AAACallState): string => state.breakdownLocationAddress
)

export const selectCallTowing = createSelector(
  selectCallState,
  (state: AAACallState): TowLocation => state.towing
)

export const selectTowingDescription = createSelector(
  selectCallTowing,
  (towing: TowLocation): string => {
    if (!towing) {
      return ''
    }

    return concatAddress(towing as BasicAddressInfo, towing.name, true)
  }
)

const selectMergedComments = createSelector(
  selectPaceSetterNotesAndComments,
  selectBreakdownLocationDetails,
  selectMemberActiveVehicleNotes,
  selectMergedPassengersComments,
  selectHighwayExit,
  selectCommentSummary,
  (
    paceSetterComments,
    locationDetails,
    vehicleNotes,
    passengerComments,
    highwayExit,
    commentSummary
  ): string => {
    const result = []

    // first place pace setter comments, if it exists
    if (paceSetterComments) {
      result.push(paceSetterComments)
    }

    // second place breakdown location name, if it exists
    result.push(...locationDetails.options.map((detail) => detail.name))

    // third place any breakdown location notes, if it exists
    if (locationDetails.notes) {
      result.push(locationDetails.notes)
    }

    // fourth place any vehicles notes, if it exists
    if (vehicleNotes) {
      result.push(vehicleNotes)
    }

    // fifth place any vehicles comments, if it exists
    if (passengerComments) {
      result.push(passengerComments)
    }

    // Sixth highway exit information
    if (highwayExit) {
      result.push(`LOCATION: ${highwayExit}`)
    }
    // Seventh comment summary, if it exists
    if (commentSummary) {
      result.push(commentSummary)
    }

    return result.join(' ')
  }
)

export const selectUserInfo = createSelector(
  selectMemberData,
  selectUserContactInfo,
  selectEligibility,
  (
    memberData,
    userContactInfo,
    eligibility,
  ): UserInfo => {
    if (eligibility) {
      return ({
        ...memberData,
        ...userContactInfo,
      })
    }
    return ({
      ...memberData
    })
  }
)

export const selectVehicleCallData = createSelector(
  selectMemberActiveVehicle,
  (activeMemberVehicle): Vehicle => {
    let vehicleColor = activeMemberVehicle?.color;
    if (!vehicleColor) {
      vehicleColor = 'Unknown'
    }
    const color = vehicleColor.substring(0, CHARACTER_LIMIT_VEHICLE_COLOR)
    let driveType = activeMemberVehicle?.driveType
      // TODO: The API only recognizes either AWD or 4WD, despite UI/UX decision to combine them
      ? VehicleDriveTypes.ALL_WHEEL_DRIVE
      : null
    return {
      ...activeMemberVehicle,
      color,
      driveType
    }
  }
)

export const selectCallData = createSelector(
  selectUserInfo,
  selectVehicleCallData,
  selectActivePaceSetterCode,
  selectUserContactInfo,
  selectMergedComments,
  selectBreakdownLocationParams,
  selectTowLocation,
  selectAuthClub,
  selectActiveAdditionalRequirement,
  (
    userInfo,
    vehicle,
    activePaceSetter,
    userContactInfo,
    mergedComments,
    breakdownLocationParams,
    towLocation,
    club,
    additionalRequirement
  ): AAACallData => {
    const mappedIssueType = mapPaceSetterToIssueType[activePaceSetter?.name]

    const language = getCurrentLocale().locale.split('-')[0].toUpperCase()
    const address = towLocation ? concatAddress(towLocation) : ''

    const priorityCodeTypePayload = additionalRequirement?.name
      ?? (mergedComments.includes("Highway")
        ? priorityCodeType.HIGHWAY
        : mappedIssueType)
    const mappedPriorityCodeType = mappingPriorityCodeType[priorityCodeTypePayload]

    return {
      club,
      contactInfo: {
        enableEmail: false,
        enablePushNotifications: false,
        enableSMS: !!userContactInfo.shouldTextUpdates,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        phone: userContactInfo.contactNumber,
        languagePreference: language,
      },
      vehicle,
      situationCodes: {
        pacesetterCode: activePaceSetter?.paceSetterCode,
        issueType: mappedIssueType,
        ...( mappedPriorityCodeType ? { priorityCodeType: mappedPriorityCodeType } : null)
      },
      comments: mergedComments,
      breakdownLocation: breakdownLocationParams,
      towing: towLocation
        ? {
          facility: (towLocation.landmark ? towLocation.landmark : towLocation.name).substring(0, CHARACTER_LIMIT_FACILITY),
          serviceProviderCode: towLocation.serviceProviderCode,
          address,
          name: towLocation.name.substring(0, CHARACTER_LIMIT_FACILITY),
          streetNumber: towLocation.streetNumber,
          streetName: towLocation.streetName,
          city: towLocation.city,
          state: towLocation.state,
          latitude: towLocation.latitude,
          longitude: towLocation.longitude,
          aarId: towLocation.aarId,
          landmark: towLocation.landmark,
          ...( towLocation.country ? { country: towLocation.country } : {} ),
          ...( towLocation.postalCode ? { zip: towLocation.postalCode } : {} ),
          ...( towLocation.emailAddress ? { emailAddress: towLocation.emailAddress } : {} ),
        }
        : null,
    }
  }
)

export const __TEST__ = {
  selectMergedComments,
}
