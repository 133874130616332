<ion-card class="ion-no-margin ion-margin-vertical">
  <div class="ion-padding card-container">
    <ion-item lines="none" class="ion-no-padding">
      <app-location-address [address]="address" iconSize="x-large" iconColor="secondary-contrast"></app-location-address>
      <aaa-button appCtaClickEvent="Custom Destination Card Close" aria-label="clear location" i18n-aria-label variant="ghost" size="small" (click)="handleClearLocation()" class="clear-location-btn">
        <aaa-icon aria-hidden="true" name="close" slot="end" color="dark" size="small"></aaa-icon>
      </aaa-button>
    </ion-item>
    <aaa-button
      class="ion-margin-top use-this-location-button"
      expand="block"
      i18n
      [loading]="loading"
      aria-label="use this location"
      i18n-aria-label
      (click)="handleUseThisLocation()"
    >
      Use This Location
    </aaa-button>
  </div>
</ion-card>
