import { MemoizedProjection, select, Store } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Inject, Injectable } from '@angular/core'
import { from, iif, of } from 'rxjs'
import { catchError, concatMap, filter, map, switchMap, tap, withLatestFrom, } from 'rxjs/operators'

import { AAACallStatus, BreakdownLocationParams, CALL_STATUS_CODES } from '../calls.types'
import { AAAStore } from '../../../store/root-reducer'
import { notifyCallFailure, resetCallStatusError, setCallStatusError } from '../calls.actions';
import {
  ACTIVE_CALL_STATUS,
  activeCallStatusRequest,
  activeCallStatusSuccess,
  CALL_STATUS,
  callStatusRequest,
  callStatusSuccess,
  notifyActiveCallStatusFailure,
  notifyCallStatusFailure,
  setActiveCallStatus
} from './call-status.actions';
import { CallsService } from '../calls.service'
import { IndexedCollection, PayloadedAction } from '../../../shared/types'
import { MessageDialogTypes, StepTypes } from '../../ui/ui.types'
import {
  createSelectDefaultActiveCallStatusId,
  selectActiveCallsCalled,
  selectActiveCallStatusId,
  selectCallsStatusesData,
  selectCallTowLocation,
  selectCanceledCallStatus
} from './call-status.selectors';
import { TaggingService } from '../../tagging/tagging.service'
import { AutomatedEventMeta, CallStatusTaggingParams } from '../../tagging/tagging.actions'
import { concatAddress, dispatchErrorAction } from '../../../shared/utils'
import { Title } from '@angular/platform-browser'
import { buildTitle } from '../../../shared/utils/title'
import { createSelectIsCallStatusTagged, createSelectIsTaggedCallStatusNew } from '../../tagging/tagging.selectors'
import { ErrorReportingService } from '../../../shared/services/error-reporting.service'
import { ConfigService } from '../../config/config.service'
import { CallStatusService } from './call-status.service'
import {
  setBreakdownLocationSuccess,
  setLocationClubSuccess,
  setSpecialAssistance,
} from '../../location/location.actions'
import { BreakdownLocation } from '../../location/location.types'
import { assignExistingVehicle, memberEligibilityResult } from '../../member/member.actions'
import { completeSetTowDestination, setPassengers } from '../../location/tow-location/tow-location.actions'
import { setPaceSetterSituation } from '../../issue/issue.actions'
import { getPaceSetterNameByCode } from '../../issue/issue.utils'
import { generateCallId, indexCalls } from '../calls.utils'
import { RouteGuardService } from '../../../shared/route-guard/route-guard.service'
import { advisoriesRequest } from '../../advisories/advisories.actions'
import { RouteTypes } from '../../main-router.module'
import { selectCurrentUrl } from '../../wizard/wizard.selectors'
import { selectActiveHasTracking } from '../clubs/clubs.selectors'
import { selectMemberData } from '../../member/member.selectors'
import events from '../../tagging/events'
import { selectEligibility, selectNoRouting } from '../../auth/auth.selectors'
import { RapService } from '../../rap/rap.service'
import { TITLE_SERVICE_TRACKING } from '../../constants/shared.constants';
import { AdobeEventTypes } from '../../tagging/tagging.types'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { authNoRouting } from '../../auth/auth.actions'
import { DRR_BASE_HREF } from '../../../shared/shared.config'
import { HEARING_IMPAIRED } from "../../ui/dialog/prompts/special-assistance/special-assistance.component";
import { PASSENGERS_OPTIONS } from "../../location/passengers/passengers.component";

const MAX_CONFIRMATION_RETRIES = 3

function tagEvent(
  tagging: TaggingService,
  meta: AutomatedEventMeta,
  isCallStatusNewTagged: boolean,
  adobeEventService: AdobeEventService
) {
  switch (meta.callStatus) {
    case CALL_STATUS_CODES.RE:
      sendTaggingEvent({
        googleEventValue: isCallStatusNewTagged
          ? events.dashboard.CALL_STATUS_RECEIVED_SUCCESS
          : events.dashboard.CALL_STATUS_RECEIVED_SUCCESS_DUPLICATED,
        adobeEventValue: isCallStatusNewTagged
          ? events.dashboard.CALL_STATUS_RECEIVED
          : events.dashboard.CALL_STATUS_RECEIVED_DUPLICATED,
        meta,
        tagging,
        adobeEventService
      })
      break

    case CALL_STATUS_CODES.ER:
      sendTaggingEvent({
        googleEventValue: events.dashboard.CALL_STATUS_ENROUTE_SUCCESS,
        adobeEventValue: events.dashboard.CALL_STATUS_ENROUTE,
        meta,
        tagging,
        adobeEventService
      })
      break

    case CALL_STATUS_CODES.OL:
      sendTaggingEvent({
        googleEventValue: events.dashboard.CALL_STATUS_ONLOCATION_SUCCESS,
        adobeEventValue: events.dashboard.CALL_STATUS_ARRIVED,
        meta,
        tagging,
        adobeEventService
      })
      break
    case CALL_STATUS_CODES.TW:
      sendTaggingEvent({
        googleEventValue: events.dashboard.CALL_STATUS_TOWING_SUCCESS,
        adobeEventValue: events.dashboard.CALL_STATUS_TOW,
        meta,
        tagging,
        adobeEventService
      })
      break
  }
}

function sendTaggingEvent({
  googleEventValue,
  adobeEventValue,
  meta,
  tagging,
  adobeEventService
}: CallStatusTaggingParams) {
  tagging.setAutomatedEvent(
    googleEventValue,
    events.dashboard.CALL_STATUS_PAGE_TYPE,
    meta
  )
  adobeEventService.sendEvent({
    eventName: AdobeEventTypes.SYSTEM,
    eventValue: events.dashboard.CALL_REQUEST_STATUS
  }, null, {
    call_status: adobeEventValue
  })
}

function isCallCancelled(
  cancelledCalls: Array<string>,
  call: AAACallStatus
): boolean {
  return (
    cancelledCalls.indexOf(generateCallId(call.callId, call.callDate)) !== -1 ||
    call.callStatus === CALL_STATUS_CODES.CA ||
    call.callStatus === CALL_STATUS_CODES.CP ||
    call.callStatus === CALL_STATUS_CODES.HD ||
    call.callStatus === CALL_STATUS_CODES.CL ||
    call.callStatus === CALL_STATUS_CODES.XX
  )
}

function validateCallStatus(titleService, _callStatusService, maxConfirmationRetries, rapService) {
  return function([
    callStatusesRemote,
    callStatusesLocal,
    selectDefaultActiveCallStatusId,
  ]: [
    IndexedCollection<AAACallStatus>,
    IndexedCollection<AAACallStatus>,
    MemoizedProjection,
    any,
    any,
    any
  ]) {
    const activeCallStatusId =
      selectDefaultActiveCallStatusId.memoized(callStatusesRemote)

    Object.keys(callStatusesLocal).forEach((callId) => {
      if (callStatusesLocal[callId].callStatus !== CALL_STATUS_CODES.NEW) {
        // TODO: Move. It's here due to out of sync issues when present in component (title should render before component)
        titleService.setTitle(buildTitle(TITLE_SERVICE_TRACKING(), rapService.isRapUser()))

        // get the active call status from the remote status, if not present (already canceled or completed) map it from the local status
        const activeCallStatus =
          callStatusesRemote[activeCallStatusId]?.callStatus ||
          callStatusesRemote[callId]?.callStatus

        switch (activeCallStatus) {
          case CALL_STATUS_CODES.CP:
          case CALL_STATUS_CODES.HD:
          case CALL_STATUS_CODES.CL:
            _callStatusService.resetCallStatus(
              MessageDialogTypes.CALL_COMPLETED
            )
            break
          case CALL_STATUS_CODES.CA:
            _callStatusService.resetCallStatus(
              MessageDialogTypes.CALL_CANCELLED
            )
            break
        }
      } else if (
        callStatusesLocal[callId].callStatus === CALL_STATUS_CODES.NEW &&
        !callStatusesRemote[callId]
      ) {
        if (!callStatusesLocal[callId]._retries) {
          callStatusesLocal[callId]._retries = 0
        }
        callStatusesLocal[callId]._retries++

        if (callStatusesLocal[callId]._retries >= maxConfirmationRetries) {
          throw new Error(
            `Could not verify call status for call id: ${callId} after ${maxConfirmationRetries} attempts.`
          )
        }
      }
    })

    return Object.values(callStatusesRemote).length > 0
  }
}

function syncCallStatus(
  taggingService: TaggingService,
  adobeEventService: AdobeEventService
) {
  return function([
    callStatusesRemote,
    callStatusesLocal,
    selectDefaultActiveCallStatusId,
    cancelledCalls,
    selectIsCallStatusTagged,
    selectIsCallStatusNewTagged,
  ]: [
    IndexedCollection<AAACallStatus>,
    IndexedCollection<AAACallStatus>,
    MemoizedProjection,
    string[],
    any,
    any
  ]): [IndexedCollection<AAACallStatus>, string] {
    Object.values(callStatusesRemote).forEach((call) => {
      const callIdentifier = generateCallId(call.callId, call.callDate)
      const isTagged = selectIsCallStatusTagged(callIdentifier, call.callStatus)
      const isCallStatusNewTagged = selectIsCallStatusNewTagged(callIdentifier)
      if (!isTagged) {
        tagEvent(taggingService, {
          callIdentifier,
          callStatus: call.callStatus,
        },
        isCallStatusNewTagged,
        adobeEventService
        )

      }
    })

    const callStatuses = [...Object.values(callStatusesRemote)]
    Object.values(callStatusesLocal).forEach((callStatusLocal) => {
      const callIdentifierLocal = generateCallId(
        callStatusLocal.callId,
        callStatusLocal.callDate
      )
      if (
        callStatusLocal.callStatus === CALL_STATUS_CODES.NEW &&
        !callStatusesRemote[callIdentifierLocal]
      ) {
        callStatuses.push(callStatusLocal)
      }
    })

    const filteredCallStatuses = indexCalls(
      callStatuses.filter((call) => !isCallCancelled(cancelledCalls, call))
    )
    const activeCallStatusId =
      selectDefaultActiveCallStatusId.memoized(filteredCallStatuses)
    return [filteredCallStatuses, activeCallStatusId]
  }
}

function handleActiveCallStatus(configService: ConfigService) {
  return function([indexedCallStatuses, activeCallStatusId]: [
    IndexedCollection<AAACallStatus>,
    string
  ]) {
    const callStatus = indexedCallStatuses?.[activeCallStatusId]
    const breakdownLocation = callStatus.breakdownLocation as BreakdownLocation
    const dispatchedActions: Array<PayloadedAction> = [
      setActiveCallStatus({
        payload: { id: activeCallStatusId },
      }),
      setLocationClubSuccess({
        payload: {
          association: configService.getConfig().association,
          club: callStatus.servicingClub,
          zipcode: (callStatus.breakdownLocation as BreakdownLocationParams).zip ||
            (breakdownLocation).postalCode
        },
      }),
      setPaceSetterSituation({
        payload: {
          name: getPaceSetterNameByCode(callStatus.pacesetterCode),
          label: getPaceSetterNameByCode(callStatus.pacesetterCode) + ' LABEL',
          // TODO refactor
          icon: '',
          iconName: '',
        },
      }),
      setBreakdownLocationSuccess({
        payload: {
          ...breakdownLocation,
          address: concatAddress(breakdownLocation),
        },
      }),
      assignExistingVehicle({
        payload: callStatus.vehicle,
      }),
    ]

    if (callStatus.towDestination) {
      dispatchedActions.push(
        completeSetTowDestination({
          payload: callStatus.towDestination,
        })
      )
      const numberOfPassengers = callStatus.numberOfPassengers || 0
      const passengerOption = PASSENGERS_OPTIONS
        .find(opt => opt.value.charAt(0) === numberOfPassengers.toString())
      dispatchedActions.push(setPassengers({
        payload: passengerOption
      }))
    }
    if (breakdownLocation.driverDirections?.toUpperCase().includes(HEARING_IMPAIRED().toUpperCase())) {
      dispatchedActions.push(setSpecialAssistance({
        payload: HEARING_IMPAIRED()
      }))
    }

    return dispatchedActions
  }
}

@Injectable()
export class CallsStatusesEffect {
  constructor(
    private actions$: Actions,
    private _callsService: CallsService,
    private _callStatusService: CallStatusService,
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private adobeEventService: AdobeEventService,
    private errorReportingService: ErrorReportingService,
    private configService: ConfigService,
    private routeGuardService: RouteGuardService,
    protected titleService: Title,
    private rapService: RapService,
    @Inject(DRR_BASE_HREF) private drrBaseHref: string
  ) {}
  maxConfirmationRetries = MAX_CONFIRMATION_RETRIES

  handleCallsStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof callStatusRequest>>(CALL_STATUS.REQUEST),
      withLatestFrom(
        this.store$.select(selectMemberData),
        this.store$.select(selectEligibility),
      ),
      filter(
        ([action, memberData, eligibility]) => {
          if (!action.payload.eligible || action.payload.ersAbuser) {
            return true // see DRRWEB-305
          }
          const checkCallStatusRap = eligibility?.eligible
          const checkCallStatusMember = memberData?.eligible && !memberData.ersAbuser
          return checkCallStatusMember || checkCallStatusRap
        }),
      switchMap(([action]) => [activeCallStatusRequest({ payload: action.payload })])
    )
  )

  handleActiveCallStatusRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof activeCallStatusRequest>>(ACTIVE_CALL_STATUS.REQUEST),
      withLatestFrom(
        this.store$.pipe(select(selectActiveCallsCalled))
      ),
      switchMap(([action, activeCallCalled]: [PayloadedAction, boolean]) =>
        from(activeCallCalled ? Promise.resolve([])
          : this._callsService.getActiveCalls(action.payload.timeout, action.payload.addCallFailure)).pipe(
          withLatestFrom(
            this.store$.pipe(select(selectCallsStatusesData))
          ),
          map(
            ([response, localCalls]): any => {
              const calls = response.filter(Boolean)
              if (action.payload.addCallFailure && calls.length === 0) {
                this.adobeEventService.sendEvent({
                  eventName: AdobeEventTypes.SYSTEM,
                  eventValue: events.submit.SUMMARY_CALL_REQUEST_FAILED
                })
                this.taggingService.setAutomatedEvent(
                  events.submit.SUBMIT_FAILURE,
                  events.submit.SUBMIT_PAGE_TYPE
                )
                return notifyCallFailure({
                  payload: {
                    ignoreFailure: false
                  }
                });
              } else if (!action.payload.retry && (!action.payload.eligible || action.payload.ersAbuser) && calls.length === 0) {
                return memberEligibilityResult({ payload: action.payload })
              }
              const remoteCallIds = response.map((call) => generateCallId(call.callId, call.callDate))
              Object.keys(localCalls).forEach((localCallId) => {
                if (!remoteCallIds.includes(localCallId)) {
                  calls.push(localCalls[localCallId])
                }
              })
              return activeCallStatusSuccess({
                payload: {
                  data: calls,
                  params: action.payload
                },
              })
            }
          ),
          catchError((error) => {
            if (action.payload.addCallFailure) {
              this.adobeEventService.sendEvent({
                eventName: AdobeEventTypes.SYSTEM,
                eventValue: events.submit.SUMMARY_CALL_REQUEST_FAILED,
              })
              this.taggingService.setAutomatedEvent(
                events.submit.SUBMIT_FAILURE,
                events.submit.SUBMIT_PAGE_TYPE
              )
              return this.errorReportingService.notifyErrorObservable(
                error,
                notifyCallFailure
              )
            } else {
              return this.errorReportingService.notifyErrorObservable(
                error,
                notifyActiveCallStatusFailure
              )
            }
          }
          )
        )
      )
    )
  )

  handleActiveCallStatusSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof activeCallStatusSuccess>>(ACTIVE_CALL_STATUS.SUCCESS),
      switchMap((action: PayloadedAction) =>
        from(Promise.all(action.payload.data
          .map(activeCall => this._callsService.getCallDetails(activeCall, action.payload.params.retry))))
          .pipe(
            map((activeCalls) => indexCalls(activeCalls)),
            withLatestFrom(
              this.store$.pipe(select(selectCallsStatusesData)),
              this.store$.pipe(select(createSelectDefaultActiveCallStatusId)),
              this.store$.pipe(select(selectCanceledCallStatus)),
              this.store$.pipe(select(createSelectIsCallStatusTagged)),
              this.store$.pipe(select(createSelectIsTaggedCallStatusNew)),
              this.store$.pipe(select(selectActiveHasTracking)),
              this.store$.pipe(select(selectCallTowLocation)),
            ),
            switchMap(
              ([
                callsStatusesRemote,
                callsStatusesLocal,
                selectDefaultActiveCallStatusId,
                cancelledCalls,
                selectIsCallStatusTagged,
                selectIsCallStatusNewTagged,
                hasTracking,
                callTowLocation,
              ]) =>
                iif(
                  () =>
                    Object.keys(callsStatusesLocal).length === 0 &&
                    Object.keys(callsStatusesRemote).length === 0,
                  of(callStatusSuccess({ payload: { data: {} } })).pipe(
                    tap(() => {
                      if (!(action.payload?.params?.retry)) {
                        this._callStatusService.stopCallStatusesUpdater();
                      }
                    })
                  ),
                  of([
                    callsStatusesRemote,
                    callsStatusesLocal,
                    selectDefaultActiveCallStatusId,
                    cancelledCalls,
                    selectIsCallStatusTagged,
                    selectIsCallStatusNewTagged,
                  ]).pipe(
                    tap(() => {
                      if (hasTracking) {
                        this._callStatusService.startCallStatusesUpdater({
                          retry: true
                        });
                      } else {
                        this._callStatusService.stopCallStatusesUpdater();
                      }
                      // reset call status error
                      this.store$.dispatch(resetCallStatusError());
                    }),
                    filter(
                      validateCallStatus(
                        this.titleService,
                        this._callStatusService,
                        this.maxConfirmationRetries,
                        this.rapService,
                      )
                    ),
                    map(syncCallStatus(this.taggingService, this.adobeEventService)),
                    concatMap(([indexedCallStatuses, activeCallStatusId]) => {
                      const stream = [];

                      if (
                        activeCallStatusId &&
                        indexedCallStatuses[activeCallStatusId]
                      ) {
                        const towDestination = indexedCallStatuses[activeCallStatusId].towDestination
                        if (towDestination) {
                          const hasCoordinates = towDestination.latitude && towDestination.longitude
                          if (!hasCoordinates && callTowLocation) {
                            towDestination.latitude = callTowLocation.latitude
                            towDestination.longitude = callTowLocation.longitude
                          }
                        }

                        stream.push(
                          advisoriesRequest({
                            payload: {
                              association:
                              this.configService.getConfig().association,
                              club: indexedCallStatuses[activeCallStatusId]
                                .servicingClub
                            }
                          })
                        );
                      }

                      return [
                        ...stream,
                        callStatusSuccess({
                          payload: { data: indexedCallStatuses }
                        }),
                        notifyCallFailure({
                          payload: {
                            ignoreFailure: true
                          }
                        })
                      ];
                    }),
                    catchError((error) =>
                      this.errorReportingService.notifyErrorObservable(
                        error,
                        [
                          setCallStatusError(),
                          dispatchErrorAction(notifyCallStatusFailure, error)
                        ],
                        { callId: selectActiveCallStatusId }
                      )
                    )
                  )
                )
            ),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(
                error,
                [
                  setCallStatusError(),
                  dispatchErrorAction(notifyCallStatusFailure, error)
                ],
                { callId: selectActiveCallStatusId }
              )
            )
          )
      )
    )
  )

  handleActiveCallStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof callStatusSuccess>>(CALL_STATUS.SUCCESS),
      map((action) => action.payload.data),
      filter(
        (indexedCallStatuses) => Object.keys(indexedCallStatuses).length > 0
      ),
      withLatestFrom(
        this.store$.pipe(select(createSelectDefaultActiveCallStatusId))
      ),
      concatMap(([indexedCallStatuses, selectDefaultActiveCallStatusId]) => {
        const activeCallStatusId =
          selectDefaultActiveCallStatusId.memoized(indexedCallStatuses)

        return handleActiveCallStatus(this.configService)([
          indexedCallStatuses,
          activeCallStatusId,
        ])
      })
    )
  )

  handleNoActiveCallNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<
          | ReturnType<typeof callStatusSuccess>
          | ReturnType<typeof notifyCallStatusFailure>
        >(CALL_STATUS.SUCCESS, CALL_STATUS.FAILURE),
        withLatestFrom(
          this.store$.select(selectCurrentUrl),
          this.store$.select(selectNoRouting)
        ),
        map(([action, url, noRouting]) => [
          (action as ReturnType<typeof callStatusSuccess>).payload?.data,
          url,
          noRouting
        ]),
        filter(
          ([indexedCallStatuses, url, noRouting]: [
            IndexedCollection<AAACallStatus>,
            string,
            boolean
          ]) =>
            (!indexedCallStatuses && url.includes(`/${RouteTypes.SIGNIN}`)) ||
            (indexedCallStatuses &&
              Object.keys(indexedCallStatuses).length === 0)
        ),
        tap(() => this._callStatusService.stopCallStatusesUpdater()),
        map(([_, __, noRouting]) => {
          if (noRouting) {
            return this.store$.dispatch(authNoRouting({
              payload: {
                success: true,
                redirectParams: {
                  commands: [this.drrBaseHref, RouteTypes.STEPS],
                  extras: {
                    queryParams: { step: StepTypes.BREAKDOWN_LOCATION },
                    replaceUrl: true,
                  }
                }
              }
            }))
          } else {
            return this.routeGuardService.redirectToFirstStep()
          }
        })
      ),
    { dispatch: false }
  )
}

