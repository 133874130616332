<ng-container *ngIf="displayEta$ | async">
  <ion-badge>
    <aaa-text size="footnote" color="text" weight="medium">
      <ion-grid class="ion-no-padding">
        <ion-row class="ion-align-items-center">
          <ion-col>
            <aaa-icon class="clock-icon" size="small" color="text" name="clock-hour2"></aaa-icon>
          </ion-col>
          <ion-col class="eta-col">
            {{ displayArrivalTime$ | async | timeRemaining }} <ng-container i18n>until arrival</ng-container> *
          </ion-col>
        </ion-row>
      </ion-grid>
    </aaa-text>
  </ion-badge>
</ng-container>
