<ng-container *ngIf="(isActiveBatteryCall$ | async) && !isHidden && !(isRapUser$ | async)">
  <ion-grid class="ion-padding-horizontal">
    <ion-row class="ion-align-items-center">
      <ng-container *ngIf="(hasBreakdownLocationPostalCode$ | async) && (hasBatteryQuotes || (!hasAttemptedRequest || (isLoading$ | async))); else noQuotes">
        <ion-col size="auto">
          <app-img src="assets/drrweb-lib/images/aaa-battery.png"
                   width="35"
                   alt="Battery Quotes"
                   i18n-alt></app-img>
        </ion-col>
        <ion-col class="ion-padding-start">
          <aaa-text i18n size="footnote" color="text" weight="bold">AAA Battery Quotes</aaa-text>
        </ion-col>
        <ion-col size="auto">
          <aaa-button
            class="get-quotes-btn"
            size="medium"
            variant="primary"
            [loading]="isLoading$ | async"
            (click)="showBatteryQuotes()"
            i18n>Get Quotes</aaa-button>
        </ion-col>
      </ng-container>
      <ng-template #noQuotes>
        <ion-col size="auto">
          <app-img src="assets/drrweb-lib/images/aaa-battery.png"
                   width="35"
                   alt="No battery quotes"
                   i18n-alt></app-img>
        </ion-col>
        <ion-col class="ion-padding-start">
          <aaa-text i18n size="footnote" color="text">No quotes available for your vehicle</aaa-text>
        </ion-col>
        <ion-col size="auto">
          <aaa-button
            size="medium"
            variant="outline"
            (click)="hide()"
            i18n>Close</aaa-button>
        </ion-col>
      </ng-template>
    </ion-row>
  </ion-grid>
</ng-container>
