<app-header-redesign>
  <app-back-button slot="start" (click)="handleBack()"/>
  <ion-title>
    <app-title i18n>Vehicle Details</app-title>
  </ion-title>
  <app-closable-header slot="end"/>

  <app-stepper-redesign/>
</app-header-redesign>
<ion-content>
  <ion-grid class="ion-padding-horizontal full-height">
    <ion-col class="ion-justify-content-start">
      <ion-row class="ion-justify-content-center ion-padding">
        <app-vehicle-make-logo [make]="buildMake(vehicle.make)"></app-vehicle-make-logo>
      </ion-row>

      <ion-row class="ion-justify-content-center vehicle-tags">
        <ion-label class="ion-no-margin">
          <aaa-text size="headline" weight="medium" align="center">
            {{ vehicle.year }} - {{ vehicle.make }}
          </aaa-text>
          <aaa-text size="headline" weight="medium" align="center">
            {{ vehicle.model }} - {{ vehicle.color | titlecase }}
          </aaa-text>
        </ion-label>
      </ion-row>

      <ion-row class="ion-padding-vertical">
        <aaa-text i18n role="heading" aria-level="2" size="caption" color="faint" class="vehicle-info-label">
          VEHICLE INFORMATION
        </aaa-text>
        <drr-vehicle-information-card
          (colorClick)="handleChangeColor()"
          [vehicle]="vehicle">
        </drr-vehicle-information-card>
      </ion-row>
    </ion-col>

    <ion-col class="ion-justify-content-end" size="auto">
      <ion-row>
        <ion-col>
          <aaa-button
            i18n
            id="confirm-btn"
            expand="block"
            [tabIndex]="5"
            (click)="handleConfirmVehicle()"
          >
            Confirm Vehicle
          </aaa-button>
        </ion-col>
      </ion-row>
      <ion-row class="ion-padding-vertical">
        <ion-col>
          <aaa-button
            i18n
            id="not-my-btn"
            expand="block"
            variant="outline"
            [tabIndex]="5"
            (click)="handleVehicleNotListed()"
          >
            This is Not My Vehicle
          </aaa-button>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-grid>

  <drr-add-vehicle-options
    [isOpen]="isAddVehicleOptionsOpen"
    (isOpenChange)="isAddVehicleOptionsOpen = $event"
  ></drr-add-vehicle-options>
  <drr-input-color
    [isModalOpen]="isColorPickerOpen"
    (onIsModalOpen)="setModalOpen($event)"
    [showInput]="false"
    (selectedColorChange)="setColor($event)"
    [selectedColor]="vehicle.color"
    [tabIndex]="6"
  ></drr-input-color>
</ion-content>

