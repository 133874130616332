<ion-grid>
  <ion-row class="info-section">
    <ion-col class="icon-column" size="auto">
      <icon-charging-level alt="Station charging level" i18n-alt size="49" volts="{{ volts }}"></icon-charging-level>
    </ion-col>
    <ion-col class="info-column">
      <ion-row>
        <aaa-text class="title" i18n>{{ title }}</aaa-text>
      </ion-row>
      <ion-row>
        <aaa-text class="subtitle description" display="flex" align="left" i18n>{{ description }}</aaa-text>
      </ion-row>
    </ion-col>
    <ion-col class="info-column" size="auto">
      <ion-row>
        <aaa-text class="title" i18n>EVSE Ports</aaa-text>
      </ion-row>
      <ion-row class="ion-justify-content-end">
        <aaa-text class="evse-ports" size="caption2" display="flex" color="faint" weight="semibold">{{ numberOfPorts }}</aaa-text>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>
