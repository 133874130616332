import { Dialog, MessageDialogTypes } from '../../../../modules/ui/ui.types'
import { ConversionType, PhoneConverterPipe } from '../../../pipes'
import {
  DRRNotificationDialogTypes,
  NotificationMap,
} from './lib-notification.model'

const phoneConverter: PhoneConverterPipe = new PhoneConverterPipe()
const getAAACallButton = (data) => {
  return {
    action: () => data.close(),
    ariaLabel: data.params.callText,
    label: phoneConverter.transform(
      data.params.roadServicePhoneNumber,
      ConversionType.NUMERIC
    ),
    href: phoneConverter.transform(
      data.params.roadServicePhoneNumber,
      ConversionType.HREF
    ),
  }
}

export const libNotificationMap: NotificationMap = {
  [MessageDialogTypes.CALL_COMPLETED]: (data: Dialog) => ({
    type: DRRNotificationDialogTypes.CALL_COMPLETED,
    close: data.close,
    title: $localize`Call Completed `,
    message: $localize` Your call was completed. If you need additional assistance, please call: `,
    submit: getAAACallButton(data),
  }),
  [MessageDialogTypes.CALL_CANCELLED]: (data: Dialog) => ({
    type: DRRNotificationDialogTypes.CALL_CANCELLED,
    close: data.close,
    title: $localize`Call Cancelled `,
    message: $localize` Your call was cancelled. If you need additional assistance, please call: `,
    submit: getAAACallButton(data),
  }),
}
