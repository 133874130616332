<ion-list mode="ios" role="radiogroup">
  <ion-item-group>
    <ion-radio-group mode="ios" [value]="this.color!">
      <ion-item-divider>
        <aaa-text i18n size="headline" weight="medium">
          Neutral
        </aaa-text>
      </ion-item-divider>
      @for (neutralColor of neutralColorList; track neutralColor) {
        <ion-item detail>
          <ion-radio
            color="drr-primary"
            justify="start"
            [value]="neutralColor.name"
            (click)="selectColor(neutralColor.name)"
          >
            <ion-row class="ion-align-items-center">
              <ion-col>
                <aaa-vehicle-color-circle [color]="neutralColor" [colorString]="neutralColor.name" />
              </ion-col>
              <ion-col>
                <aaa-text
                  class="ion-padding-start"
                  size="subheadline"
                  weight="regular"
                  color="subtle">
                  {{ neutralColor.name | titlecase }}
                </aaa-text>
              </ion-col>
            </ion-row>
          </ion-radio>
        </ion-item>
      }
      <ion-item-divider class="ion-margin-top">
        <aaa-text i18n size="headline" weight="medium">
          Color
        </aaa-text>
      </ion-item-divider>
      @for (colorItem of colorList; track colorItem) {
        <ion-item detail (click)="handleOtherColorClick($event)">
          @if (showOtherColorInput && colorItem.name === 'Other') {
            <ion-row class="ion-align-items-center">
              <ion-col size="auto">
                <aaa-vehicle-color-circle [color]="colorItem" [colorString]="colorItem.name" />
              </ion-col>
              <ion-col>
                <ion-row>
                  <aaa-input
                    i18n-label
                    label="Other Color"
                    variant="secondary"
                    id="other-color-input"
                    class="ion-padding-start"
                    enterKeyHint="done"
                    (keyup.enter)="handleOtherColorClick($event)"
                    [value]="otherColor"
                    [maxLength]="maxChars"
                    (aaaChange)="setOtherColor($event)"
                    required>
                    <ion-button color="drr-primary" size="small" (click)="selectOtherColor()" rightAction fill="solid" slot="end">
                      <aaa-icon name="check"></aaa-icon>
                    </ion-button>
                  </aaa-input>
                </ion-row>
              </ion-col>
            </ion-row>
          } @else {
            <ion-radio color="drr-primary" justify="start" [value]="getValue(colorItem.name)" (click)="selectColor(colorItem.name)">
              <ion-row class="ion-align-items-center">
                <ion-col size="auto">
                  <aaa-vehicle-color-circle [color]="colorItem" [colorString]="colorItem.name" />
                </ion-col>
                <ion-col>
                  <aaa-text
                    class="ion-padding-start"
                    size="subheadline"
                    weight="regular"
                    color="subtle">
                    @if (colorItem.name === 'Other') {
                      {{otherColor | titlecase}}
                    } @else {
                      {{colorItem.name | titlecase}}
                    }
                  </aaa-text>
                </ion-col>
              </ion-row>
            </ion-radio>
          }
        </ion-item>
      }
    </ion-radio-group>
  </ion-item-group>
</ion-list>
