import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core'
import * as maplibregl from 'maplibre-gl'
import { GoogleCoordinates } from '../../location/google-geocode/types'
import { MarkerDetails } from '../../ui/ui.types'

@Component({
  selector: 'app-custom-marker-aws',
  templateUrl: './custom-marker-aws.component.html',
  styleUrls: ['./custom-marker-aws.component.scss']
})
export class CustomMarkerAwsComponent implements AfterViewInit, OnChanges {
  @ViewChild('marker', { static: true }) markerEl: ElementRef

  @Input() location: GoogleCoordinates
  @Input() map: maplibregl.Map
  @Input() data: MarkerDetails = {
    draggable: false,
    active: false,
  }
  @Input() tabIndex: number
  @Input() type: string = 'car'
  @Input() panOnPlace: boolean
  @Input() panOnMove: boolean

  @Output() markerClicked: EventEmitter<any> = new EventEmitter<any>()
  @Output() markerTouchStart: EventEmitter<any> = new EventEmitter<any>()
  @Output() dragEnd: EventEmitter<any> = new EventEmitter<any>()

  private marker: maplibregl.Marker
  styleClass = 'default-marker'
  display = true
  private accuracyCircleId: string | null = null

  ngAfterViewInit() {
    this.initialize()
  }

  ngOnDestroy() {
    this.removeMarker()
    this.removeAccuracyCircle()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['location'] && !changes['location'].firstChange) {
      const isGpsDot = this.markerEl?.nativeElement.querySelector('img[alt="user gps location"]')

      if (isGpsDot && this.location?.accuracy) {
        if (this.marker) {
          this.marker.setLngLat([this.location.lng, this.location.lat])
        }

        if (this.map?.loaded()) {
          this.updateAccuracyCircle()
        } else {
          this.map?.once('load', () => this.updateAccuracyCircle())
        }
      }
    }
  }

  handleMarkerClicked($event: Event) {
    if ($event) {
      $event.preventDefault()
      $event.stopPropagation()
    }
    this.markerClicked.emit()
  }

  handleMarkerTouchStart($event: Event) {
    if (this.data?.draggable) {
      return
    }

    if ($event) {
      $event.stopPropagation()
      $event.preventDefault()
    }

    this.markerTouchStart.emit($event)
  }

  refreshMarker() {
    this.removeMarker()
    this.initialize()
  }

  private removeMarker() {
    if (this.marker) {
      this.marker.remove()
    }
  }

  private createAccuracyCircle(center: [number, number], radiusInMeters: number, points = 64) {
    const coords = {
      latitude: center[1],
      longitude: center[0]
    }

    // Convert meters to degrees more accurately
    const lat = coords.latitude * Math.PI / 180

    // Calculate the length of a degree at this latitude
    const metersPerDegreeLat = 111320 // Approximate meters per degree latitude
    const metersPerDegreeLng = 111320 * Math.cos(lat) // Adjust for latitude

    // Convert radius from meters to degrees
    const latRadius = radiusInMeters / metersPerDegreeLat
    const lngRadius = radiusInMeters / metersPerDegreeLng

    const ret = []
    for(let i = 0; i < points; i++) {
      const theta = (i/points)*(2*Math.PI)
      const x = lngRadius * Math.cos(theta)
      const y = latRadius * Math.sin(theta)
      ret.push([coords.longitude + x, coords.latitude + y])
    }
    ret.push(ret[0]) // Close the circle

    return {
      type: "Feature" as const,
      properties: {},
      geometry: {
        type: "Polygon" as const,
        coordinates: [ret]
      }
    }
  }

  private updateAccuracyCircle() {
    if (!this.map || !this.location?.accuracy) return

    this.removeAccuracyCircle()
    this.accuracyCircleId = `accuracy-circle-${Date.now()}`

    const circleData = this.createAccuracyCircle(
      [this.location.lng, this.location.lat],
      Math.max(this.location.accuracy, 50)
    )

    this.map.addSource(this.accuracyCircleId, {
      type: "geojson",
      data: circleData
    })

    const layers = this.map.getStyle().layers
    const firstSymbolId = layers.find(layer => layer.type === 'symbol')?.id || layers[0].id

    this.map.addLayer({
      id: `${this.accuracyCircleId}-fill`,
      type: "fill",
      source: this.accuracyCircleId,
      paint: {
        "fill-color": "#4B89F4",
        "fill-opacity": 0.2
      }
    }, firstSymbolId)

    this.map.addLayer({
      id: `${this.accuracyCircleId}-border`,
      type: "line",
      source: this.accuracyCircleId,
      paint: {
        "line-color": "#4B89F4",
        "line-width": 2,
        "line-opacity": 0.4
      }
    }, firstSymbolId)
  }

  private removeAccuracyCircle() {
    if (this.accuracyCircleId && this.map) {
      if (this.map.getLayer(`${this.accuracyCircleId}-fill`)) {
        this.map.removeLayer(`${this.accuracyCircleId}-fill`)
      }
      if (this.map.getLayer(`${this.accuracyCircleId}-border`)) {
        this.map.removeLayer(`${this.accuracyCircleId}-border`)
      }
      if (this.map.getSource(this.accuracyCircleId)) {
        this.map.removeSource(this.accuracyCircleId)
      }
      this.accuracyCircleId = null
    }
  }

  private initialize() {
    if (!this.map || !this.location?.lat || !this.location?.lng || !this.markerEl?.nativeElement) {
      return
    }

    try {
      const isGpsDot = this.markerEl.nativeElement.querySelector('img[alt="user gps location"]')

      this.marker = new maplibregl.Marker({
        element: this.markerEl.nativeElement,
        anchor: isGpsDot ? 'center' : 'bottom',
        offset: [0, 0],
        draggable: this.data?.draggable || false
      })
        .setLngLat([this.location.lng, this.location.lat])
        .addTo(this.map)

      if (isGpsDot && this.location.accuracy) {
        setTimeout(() => {
          if (this.map.loaded()) {
            this.updateAccuracyCircle()
          } else {
            this.map.once('load', () => this.updateAccuracyCircle())
          }
        }, 0)
      }

      if (this.data?.draggable) {
        this.marker.on('dragend', () => {
          const lngLat = this.marker.getLngLat()
          this.dragEnd.emit({
            lat: lngLat.lat,
            lng: lngLat.lng
          })
        })
      }
    } catch (error) {
      console.warn('Error creating AWS marker:', error)
    }
  }
}
