import { Component } from '@angular/core';

@Component({
  selector: 'drr-captcha-terms',
  templateUrl: './captcha-terms.component.html',
  styleUrls: ['./captcha-terms.component.scss'],
})
export class CaptchaTermsComponent {

  constructor() {}

  openPrivacy() {
    this.openLink('https://policies.google.com/privacy')
  }

  openTerms() {
    this.openLink('https://policies.google.com/terms')
  }

  openLink(url: string): void {
    const features = 'noopener,noreferrer';
    window.open(url, '_blank', features);
  }

}
