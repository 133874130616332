import { Injectable } from '@angular/core'
import { VehicleCallData, VehicleCallDataAdapted } from "../../modules/dashboard/calls.types";
import { VehicleData, VehicleMemberData } from "../../modules/member/member.types";

@Injectable({
  providedIn: 'root',
})
export class VehicleAdapter {

  constructor() {}

  adaptMemberVehicle = (vehicle: VehicleData): VehicleMemberData => ({
    ...vehicle,
    year: Number(vehicle.year),
    licensePlate: vehicle.tag,
  })

  adaptCallVehicle = (vehicle: VehicleCallData): VehicleCallDataAdapted => ({
    year: Number(vehicle.year),
    make: vehicle.make,
    model: vehicle.model,
    ...(vehicle.color ? { color: vehicle.color } : null),
    ...(vehicle.vin ? { vin: vehicle.vin } : null),
    ...(vehicle.fuelType ? { fuelType: vehicle.fuelType } : null),
    ...(vehicle.driveType ? { driveType: vehicle.driveType } : null),
    ...(vehicle.vehicleType ? { vehicleType: vehicle.vehicleType } : null),
    ...(vehicle.odometer ? { odometer: vehicle.odometer } : null),
    ...(vehicle.tag ? { licensePlate: vehicle.tag } : null),
    ...(vehicle.state ? { licensePlateState: vehicle.state } : null),
    ...(vehicle.trim ? { trim: vehicle.trim } : null),

  })
}
