import { createFeatureSelector, createSelector, defaultMemoize } from '@ngrx/store'

import { getCookie } from '../../shared/utils/cookies'
import { IndexedCollection } from '../../shared/types'
import { AuthState } from '../auth/auth.reducer'
import { selectAuthState } from '../auth/auth.selectors'
import { AppId, AuthMethods, AuthNoRoutingResponse } from '../auth/auth.types'
import { PaceSetterSituation } from '../issue/issue.types'
import { GoogleAddress } from '../location/google-geocode/types'
import { CaptchaVersion, Vehicle } from '../member/member.types'
import { ABTestsState, UIState } from './ui.reducer'
import { ABTestParams, Dialog, PageNavigationEvent, Section, Step, StepStatus, StepTypes } from './ui.types'
import { isAgentAppId } from './ui.utils';
import { DISTANCE_UNIT } from '../../shared/i18n/i18n.types'
import { getCurrentLocale } from '../../shared/i18n/i18n.utils'
import { selectRouterSection } from '../../store/router.selectors'

const stateKey = 'ui'

export const selectUIState = createFeatureSelector<UIState>(stateKey)

export const selectSteps = createSelector(
  selectUIState,
  (state: UIState): IndexedCollection<Step> => state?.steps // TODO find out why unit test are failing intermittently here
)

export const selectIterableSteps = createSelector(
  selectSteps,
  (steps: IndexedCollection<Step>): Step[] => Object.values(steps)
)

export const selectEditingStep = createSelector(
  selectIterableSteps,
  (iterableSteps: Step[]): Step =>
    iterableSteps.find((step) => step.status === StepStatus.EDITING)
)

export const selectNextStep = createSelector(
  selectIterableSteps,
  selectEditingStep,
  (iterableSteps: Step[], editingStep: Step): Step => {
    const indexCurrentStep = iterableSteps.indexOf(editingStep)
    const nextIndex = indexCurrentStep + 1
    if (nextIndex >= iterableSteps.length) {
      return null
    }
    return iterableSteps[nextIndex]
  }
)

export const createGetStepByUrl = createSelector(
  selectIterableSteps,
  (iterableSteps: Step[]) =>
    defaultMemoize(
      (url: string): Step => iterableSteps.find((step) => step.url === url)
    )
)

export const createGetPreviousStepByUrl = createSelector(
  selectIterableSteps,
  (iterableSteps: Step[]) =>
    defaultMemoize((url: string): Step => {
      const currentStep = iterableSteps.find((step) => step.url === url)

      return iterableSteps[iterableSteps.indexOf(currentStep) - 1]
    })
)

export const selectIsCouponApplied = createSelector(
  selectUIState,
  (state: UIState) => state.isCouponApplied
)

export const selectSplashscreenIsVisible = createSelector(
  selectUIState,
  (state: UIState) => state.splashscreen.visible
)

export const selectSplashscreenStep = createSelector(
  selectUIState,
  (state: UIState) => state.splashscreen.step
)

export const selectSplashscreenIsAnimatingOut = createSelector(
  selectUIState,
  (state: UIState) => state.splashscreen.animating
)

export const selectMockCaptcha = createSelector(
  selectUIState,
  (state: UIState): boolean => state.mockCaptcha
)

export const selectIsCaptchaRequired = createSelector(
  selectAuthState,
  (state: AuthState): boolean =>
    !(
      state.isSecure ||
      (state.method === AuthMethods.MEMBERSHIP_NUMBER &&
        (getCookie('AAA_AppId') === AppId.AGENT
          || getCookie('AAA_AppId') === AppId.MONITOR
          || isAgentAppId()))
    )
)

export const selectCaptchaModalOpen = createSelector(
  selectUIState,
  (state: UIState): boolean => state.captchaModalOpen
)

export const selectCaptchaVersion = createSelector(
  selectUIState,
  (state: UIState): CaptchaVersion => state.captchaVersion
)

export const selectIsTransitioning = createSelector(
  selectUIState,
  (state: UIState) => !!state.transitioning
)

export const selectUserDefaultCoords = createSelector(
  selectUIState,
  (state: UIState) => state.defaultUserCoords
)

export const selectHasUserDefaultCoords = createSelector(
  selectUserDefaultCoords,
  (userCoords: GoogleAddress) =>
    Boolean(userCoords) && Boolean(userCoords.lat) && Boolean(userCoords.lng)
)

export const selectUserDefaultCoordsLoading = createSelector(
  selectUIState,
  (state: UIState) => state.defaultUserCoordsLoading
)

export const selectActiveDialogs = createSelector(
  selectUIState,
  (state: UIState): IndexedCollection<Dialog> => state.dialog
)

export const selectActiveDialogsByTime = createSelector(
  selectActiveDialogs,
  (dialogs: IndexedCollection<Dialog>): Array<Dialog> =>
    Object.values(dialogs).sort((a, b) => {
      if (!a.displayedAt) {
        return Number.MAX_SAFE_INTEGER
      }
      if (!b.displayedAt) {
        return Number.MIN_SAFE_INTEGER
      }
      return a.displayedAt.getTime() - b.displayedAt.getTime()
    })
)

export const selectIsAnyDialogActive = createSelector(
  selectActiveDialogs,
  (dialogs: IndexedCollection<Dialog>): boolean => Object.keys(dialogs).length > 0
)

export const selectPreProdEnv = createSelector(
  selectUIState,
  (state: UIState): boolean => state.preProdEnv
)

export const selectDefaultZipCode = createSelector(
  selectUIState,
  (state: UIState): string => state.defaultZipCode || null
)

export const selectTelematics = createSelector(
  selectUIState,
  (state: UIState): string[] => state.telematics
)

export const selectCountry = createSelector(
  selectUIState,
  (state: UIState): string => state.country
)

export const selectQueryParamsVehicleData = createSelector(
  selectUIState,
  (state: UIState): Vehicle => state.queryParamsVehicle
)

export const selectIsFirstTimeAccess = createSelector(
  selectUIState,
  (state: UIState): boolean => state.isFirstTimeAccess
)

export const selectChannel = createSelector(
  selectUIState,
  (state: UIState): string => state.channel
)

export const selectPreviousPageNavigation = createSelector(
  selectUIState,
  (state: UIState): PageNavigationEvent => ({
    url: state.previousUrl,
    title: state.previousTitle
  })
)

export const selectAuthParams = createSelector(
  selectUIState,
  (state: UIState): AuthNoRoutingResponse => state.authResponse
)

export const selectProblemTooltip = createSelector(
  selectUIState,
  (state: UIState): PaceSetterSituation => state.problemTooltip
)

export const selectWatchRecaptchaPosition = createSelector(
  selectUIState,
  (state: UIState): boolean => state.watchRecaptchaPosition
)

export const selectFullMapBreakdownLocation = createSelector(
  selectUIState,
  (state: UIState): boolean => state.showFullMapBreakdownLocation
)

export const selectAbTestState = createSelector(
  selectUIState,
  (state: UIState): ABTestsState => state.abTestsState
)

export const selectIsAbTestParam = createSelector(
  selectAbTestState,
  (abTestsState: ABTestsState, abTestKey: ABTestParams): boolean => abTestsState[abTestKey]
)

export const selectIsPlateToVinBTestParam = createSelector(
  selectUIState,
  (state: UIState): boolean => state.abTestsState[ABTestParams.LICENSE_PLATE_TO_VIN_B]
)

export const selectIsPlateToVinCTestParam = createSelector(
  selectUIState,
  (state: UIState): boolean => state.abTestsState[ABTestParams.LICENSE_PLATE_TO_VIN_C]
)

export const selectIsBreakdownLocationImprovementTest = createSelector(
  selectUIState,
  (state: UIState): boolean => state.abTestsState[ABTestParams.BREAKDOWN_LOCATION_IMPROVEMENT]
)

export const selectDistanceUnit = createSelector(
  selectUIState,
  (state: UIState): DISTANCE_UNIT => state.distanceUnit
)

export const selectMileageLabel = createSelector(
  selectDistanceUnit,
  (unit: DISTANCE_UNIT): string => {
    const currentUnit = unit ? unit : getCurrentLocale().defaultDistanceUnit
    switch (currentUnit) {
      case DISTANCE_UNIT.KILOMETERS:
        return $localize`Mileage (In Kilometers)`
      case DISTANCE_UNIT.MILES:
        return $localize`Mileage (In Miles)`
    }
  }
)

export const selectCurrentStep = createSelector(
  selectIterableSteps,
  (iterableSteps: Step[]): Step => ({
    ...iterableSteps.find((step) => step.status === StepStatus.EDITING)
  })
)

export const selectIsBgContrast = createSelector(
  selectCurrentStep,
  selectRouterSection,
  (step: Step, section: Section): boolean => {
    switch (step.url) {
      case StepTypes.TOWING:
      case StepTypes.SUBMIT:
        return true;
      case StepTypes.VEHICLE:
        return !!section;
      default:
        return false;
    }
  }
)
