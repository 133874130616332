<div
  #marker
  [class]="styleClass"
  [attr.tabindex]="tabIndex"
  (click)="handleMarkerClicked($event)"
  (touchstart)="handleMarkerTouchStart($event)"
  [style.z-index]="tabIndex"
  [attr.aria-label]="(data?.label || '') + ' Map Marker'"
>
  <ng-content></ng-content>
</div>
