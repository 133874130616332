import { Component, OnInit, ViewChild } from '@angular/core'
import { select, Store } from '@ngrx/store';
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service';
import { Title } from '@angular/platform-browser';
import events from '../../tagging/events';
import { Observable } from 'rxjs';
import { ADD_CALL, addCallRequest } from '../../dashboard/calls.actions';
import { FormGroupState } from 'ngrx-forms';
import { UserContactInfo } from '../submit.reducer';
import { AbstractResponsiveComponent } from '../../../shared/abstract-responsive.component';
import { AAAStore } from '../../../store/root-reducer';
import { TaggingService } from '../../tagging/tagging.service';
import { RapService } from '../../rap/rap.service';
import { CHARACTER_LIMIT_FIRST_LAST_NAME, TITLE_SUBMIT } from '../../constants/shared.constants';
import { buildTitle } from '../../../shared/utils/title';
import { Eligibility } from '../../auth/eligibility.types';
import { selectEligibility } from '../../auth/auth.selectors';
import { selectContactForm } from '../submit.selectors';
import { selectIsLoading } from '../../ui/loading/loading.selectors';
import { PaceSetterCode } from '../../issue/issue.types';
import { selectActivePaceSetterCode } from '../../issue/issue.selectors';
import { NumericOnlyValueConverter } from '../../../shared/value-converters/numeric-only.value-converter';
import { openErrorDialog } from '../../ui/ui.actions';
import { ErrorDialogTypes } from '../../ui/ui.types'
import { AdobeEventTypes } from '../../tagging/tagging.types';
import { EditService } from '../../../shared/services/edit.service'
import type { ScrollDetail } from '@ionic/core'
import { MaskitoElementPredicate, MaskitoOptions, maskitoTransform } from '@maskito/core';
import { US_PHONE_NUMBER_MASK } from '../../constants/input-masks.constants';
import { IonInput } from "@ionic/angular";

const SLOW_SUBMIT_THRESHOLD = 20000

@Component({
  selector: 'app-review-and-submit',
  templateUrl: './review-and-submit.component.html',
  styleUrls: ['./review-and-submit.component.scss'],
})
export class ReviewAndSubmitComponent extends AbstractResponsiveComponent implements OnInit {
  @ViewChild('phoneInput') phoneInputEl: IonInput
  @ViewChild('firstNameInput') firstNameInputEl: IonInput
  @ViewChild('lastNameInput') lastNameInputEl: IonInput

  constructor(
    public store$: Store<AAAStore>,
    public tagging: TaggingService,
    private adobeEventService: AdobeEventService,
    private rapService: RapService,
    private titleService: Title,
    private editService: EditService
  ) {
    super()
    this.titleService.setTitle(
      buildTitle(TITLE_SUBMIT(), this.rapService.isRapUser())
    )
    this.tagging.setPageEvent(
      events.submit.SUMMARY_PAGE_PROMPT,
      events.submit.SUMMARY_PAGE_TYPE
    )
    this.tagging.setPageLoadEvent({ pageType: events.submit.PAGE_TYPE, pageName: events.submit.PAGE_NAME_REVIEW })
    this.displayCustomWarning = false
  }
  DEFAULT_TEXT_LENGTH = 250
  displayCustomWarning = false
  maxLengthName = CHARACTER_LIMIT_FIRST_LAST_NAME
  readonly phoneMask: MaskitoOptions = { mask: US_PHONE_NUMBER_MASK }
  readonly maskPredicate: MaskitoElementPredicate = (el) =>
    (el as HTMLIonInputElement).getInputElement();

  eligibility$: Observable<Eligibility> = this.store$.pipe(
    select(selectEligibility)
  )
  eligibility

  showAnchorSubmit = false
  lastScrollTop = 0

  isCallRequesting$: Observable<boolean> = this.store$.pipe(
    select(selectIsLoading(ADD_CALL.ACTION))
  )

  paceSetterCode$: Observable<PaceSetterCode> = this.store$.pipe(
    select(selectActivePaceSetterCode)
  )
  paceSetterCode

  contactForm: FormGroupState<UserContactInfo> = null
  controls: any = null
  phoneValueConverter = new NumericOnlyValueConverter()

  isRapUser: Boolean = false
  phoneNumber = ''

  ngOnInit(): void {
    this.isRapUser = this.rapService.isRapUser()
    this.subscriptions.push(
      this.store$.pipe(select(selectContactForm)).subscribe((form) => {
        this.contactForm = form

        if (!this.controls) {
          this.controls = form.controls
          this.controls.contactNumber.isTouched = false
          this.controls.firstName.isTouched = false
          this.controls.lastName.isTouched = false
        } else {
          this.controls = form.controls
        }
      }),
      this.eligibility$.subscribe((eligibility) => {
        this.eligibility = eligibility
        const eligibilityContact = eligibility?.contractIdentityData?.contact
        if (eligibilityContact) {
          this.controls.contactNumber.value =
            this.controls.contactNumber.value || eligibilityContact.phones?.[0]?.phoneNumber
          this.controls.firstName.value =
            this.controls.firstName.value || eligibilityContact.firstName?.substring(0, 15)
          this.controls.lastName.value =
            this.controls.lastName.value || eligibilityContact.lastName?.substring(0, 15)
        }
        if (!eligibility) {
          this.controls.firstName.value = 'first'
          this.controls.lastName.value = 'last'
        }
      }),
      this.paceSetterCode$.subscribe((paceSetterCode) => this.paceSetterCode = paceSetterCode)
    )
    if (this.controls.contactNumber.value) {
      this.controls.contactNumber.value = maskitoTransform(this.controls.contactNumber?.value, this.phoneMask)
    }
  }

  handleCallSubmit() {
    if(this.rapService.isRapNotEligible(this.paceSetterCode, this.eligibility)) {
      this.store$.dispatch(openErrorDialog({
        payload: {
          type: ErrorDialogTypes.NOT_ELIGIBLE
        }
      }))
      return
    }

    // if form is invalid, avoid to continue
    if (!this.contactForm.isValid) {
      if (!this.controls.contactNumber.isValid) {
        this.controls.contactNumber.isPristine = false
        this.controls.contactNumber.isDirty = true
        this.controls.contactNumber.isTouched = true
        this.phoneInputEl.setFocus()
        return
      }

      if(this.isRapUser) {
        if (!this.controls.firstName.isValid) {
          this.controls.firstName.isPristine = false
          this.controls.firstName.isDirty = true
          this.controls.firstName.isTouched = true
          this.firstNameInputEl.setFocus()
          return
        }

        if (!this.controls.lastName.isValid) {
          this.controls.lastName.isPristine = false
          this.controls.lastName.isDirty = true
          this.controls.lastName.isTouched = true
          this.lastNameInputEl.setFocus()
          return
        }
      }
    }

    this.tagging.setClickEvent(
      events.submit.SUMMARY_SUBMIT_CLICK,
      events.submit.SUMMARY_PAGE_TYPE
    )

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.submit.SUMMARY_CALL_REQUEST_SUBMITTED
    })

    if (this.controls.additionalDetails.value.length > 2) {
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.submit.SUMMARY_OPTIONAL_DETAILS_ENTERED
      })
    }

    this.store$.dispatch(addCallRequest())

    setTimeout(() => {
      this.displayCustomWarning = true
    }, SLOW_SUBMIT_THRESHOLD)
  }

  handleTextUpdate() {
    if (!this.controls.shouldTextUpdates.value) {
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.submit.SUMMARY_TEXT_UPDATES_REMOVED
      })
    }
  }

  navigateBack() {
    this.editService.navigateBack();
  }

  handleScroll(event: CustomEvent<ScrollDetail>) {
    this.showAnchorSubmit = event.detail.scrollTop > 0;
  }
}
