import {
  Component,
  Output,
  EventEmitter,
  Input,
} from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { FormGroupState } from 'ngrx-forms'
import { Observable } from 'rxjs'
import { PlateToVinFormState } from '../../../modules/ui/forms/forms.reducer'
import { selectPlateToVinForm } from '../../../modules/ui/forms/forms.selectors'
import { VEHICLE_UPDATE } from '../../../modules/member/member.actions'
import { selectIsLoading } from '../../../modules/ui/loading/loading.selectors'
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons } from "@ionic/angular/standalone";
import { ButtonComponent, IconComponent, InputComponent, ModalComponent } from '@aaa-mobile/shared-components'
import { VehicleColorListComponent } from '../../../modules/vehicle/vehicle-color-list'
import { AsyncPipe } from '@angular/common'

@Component({
  selector: 'drr-input-color',
  templateUrl: './input-color.component.html',
  styleUrls: ['./input-color.component.scss'],
  standalone: true,
  imports: [
    IonButtons,
    IonTitle,
    ModalComponent,
    IonHeader,
    IonToolbar,
    IonContent,
    ButtonComponent,
    IconComponent,
    VehicleColorListComponent,
    InputComponent,
    AsyncPipe
  ]
})

export class DrrInputColor {
  private color: string
  @Input() isModalOpen = false
  @Input() disabled = false

  form$: Observable<FormGroupState<PlateToVinFormState>> =
    this.store$.pipe(select(selectPlateToVinForm))

  isLoading$: Observable<any> = this.store$.pipe(
    select(selectIsLoading(VEHICLE_UPDATE.ACTION))
  )

  @Input() showInput = true
  @Input() error = false
  @Input() set selectedColor(color) {
    this.color = color
  }
  @Output() selectedColorChange: EventEmitter<string> =
    new EventEmitter<string>()

  @Output() onIsModalOpen: EventEmitter<boolean> =
    new EventEmitter<boolean>()

  get selectedColor() {
    return this.color
  }
  constructor(
    private store$: Store<AAAStore>
  ) { }

  onSelectedColor(color: string) {
    if (color && color !== this.selectedColor) {
      this.selectedColor = color
      this.handleChange()
    }
  }

  handleChange() {
    this.store$.dispatch({
      controlId: 'form.plateToVin.color',
      value: this.selectedColor,
      type: 'ngrx/forms/SET_VALUE',
    })
    this.selectedColorChange.emit(this.selectedColor)
    this.setOpen(false)
  }

  setOpen(isOpen: boolean): void {
    this.isModalOpen = isOpen
    this.onIsModalOpen.emit(isOpen)
  }

}
