import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AuthState } from './auth.reducer'
import { AgentSettingsParams, AuthMethods } from './auth.types'
import { Eligibility } from './eligibility.types'
import { VendorConfigurationMode } from './mode-configuration.types'
import { PaceSetterSituation } from '../issue/issue.types';
import { selectSharedConfig } from '../config/config.selectors';
import { SharedConfig } from '../../shared/shared.config';
import { Vehicle, VehicleData } from '../member/member.types';
import { PACE_SETTER_KEY_TO_TYPE } from '../servicing-club/servicing-club.types';
import { selectMembershipNumber } from '../member/member.selectors'

const CALL_DRR = () => $localize`CALL AAA`
const CALL_RAP = () => $localize`CALL`
const MOBILE_HEADER_RAP = () => $localize`Please enter your details to get started with our 24 hr road service.`
const MOBILE_HEADER_DRR = () => $localize`Please enter your AAA details to get started with our 24 hr road service.`

const stateKey = 'auth'

export const selectAuthState = createFeatureSelector<AuthState>(stateKey)

export const selectAccessToken = createSelector(
  selectAuthState,
  (state: AuthState): string => state.accessToken
)

export const selectNoRouting = createSelector(
  selectAuthState,
  (state: AuthState): boolean => state.noRouting
)

export const selectIsRapTokenAccess = createSelector(
  selectAuthState,
  (state: AuthState): boolean => state.isRapTokenAccess
)

export const selectIsOdometerConfirmation = createSelector(
  selectAuthState,
  (state: AuthState): boolean => state.odometer !== undefined
)

export const selectAuthMethod = createSelector(
  selectAuthState,
  (state: AuthState): AuthMethods => state.method
)

export const selectAuthClub = createSelector(
  selectAuthState,
  selectMembershipNumber,
  (state: AuthState, memberNumber: string): string => !!memberNumber ? memberNumber.substring(3, 6) : state.club
)

export const selectIsSecure = createSelector(
  selectAuthState,
  (state: AuthState): boolean => (state ? state.isSecure : false)
)

export const selectIsSignedIn = createSelector(
  selectAuthState,
  // TODO: Need to handle expiresIn + token renew endpoint strategies.
  (state: AuthState): boolean => Boolean(state.accessToken)
)

export const selectAgentSettings = createSelector(
  selectAuthState,
  (state: AuthState): AgentSettingsParams => state.agentSettings || {}
)

export const selectModeConfiguration = createSelector(
  selectAuthState,
  (state: AuthState): VendorConfigurationMode => (state && state.modeConfig ? state.modeConfig : null)
)

export const selectSmsOptInDefault = createSelector(
  selectModeConfiguration,
  (config: VendorConfigurationMode): boolean => (config ? (config.smsOptInDefault !== undefined ? config.smsOptInDefault : true) : true)
)

export const selectAllowAddVehicle = createSelector(
  selectModeConfiguration,
  selectSharedConfig,
  (config: VendorConfigurationMode, sharedConfig: SharedConfig): boolean =>
    config?.allowAddVehicle !== false && sharedConfig?.allowAddVehicle !== false
)

export const selectAllowEditVehicle = createSelector(
  selectModeConfiguration,
  selectSharedConfig,
  (config: VendorConfigurationMode, sharedConfig: SharedConfig): boolean =>
    config?.allowEditVehicle !== false && sharedConfig?.allowEditVehicle !== false
)

export const selectIsVehicleChangeAllowed = createSelector(
  selectAllowAddVehicle,
  selectAllowEditVehicle,
  (allowAdd: boolean, allowEdit: boolean): boolean => allowAdd || allowEdit
)

export const selectIsIssueChangeAllowed = createSelector(
  selectSharedConfig,
  (sharedConfig: SharedConfig): boolean => !sharedConfig?.defaultIssueType
)

export const selectDefaultIssueType = createSelector(
  selectSharedConfig,
  (config: SharedConfig): PaceSetterSituation => {
    if (config?.defaultIssueType) {
      const paceSetterName = PACE_SETTER_KEY_TO_TYPE[config.defaultIssueType]
      return {
        name: paceSetterName,
        label: paceSetterName + ' LABEL',
      } as PaceSetterSituation
    }

    return null
  }
)

export const selectDefaultVehicle = createSelector(
  selectSharedConfig,
  (config: SharedConfig): Vehicle | VehicleData => config?.defaultVehicle ?? null
)

export const selectCallCenterTowMileage = createSelector(
  selectModeConfiguration,
  (config: VendorConfigurationMode): number => (config && config.callCenterTowMileage ? config.callCenterTowMileage : null)
)

export const selectEligibility = createSelector(
  selectAuthState,
  (state: AuthState): Eligibility => (state ? state.eligibility : null)
)

export const selectIsRapEligible = createSelector(
  selectEligibility,
  (eligibility: Eligibility): boolean => eligibility ? eligibility.eligible : false
)

export const selectMaxTowMileLimit = createSelector(
  selectEligibility,
  (eligibility: Eligibility): number => getMaxTowMileLimit(eligibility, 'TOW')
    || getMaxTowMileLimit(eligibility, 'ALL')
    || 0
)

const getMaxTowMileLimit = (eligibility: Eligibility, entitlementType: string): number => {
  const policies = eligibility
    ?.entitlement
    ?.filter(e => e.type === entitlementType)
    ?.map(e => e.policyInformation)
  const value = [].concat(...(policies || []))
    ?.find(pi => pi?.key === 'MAX_TOW_MILE_LIMIT')
    ?.value
  const maxTowMileLimit = Number(value)
  return isNaN(maxTowMileLimit) ? undefined : maxTowMileLimit
}

/**
 * Select button call text.
 *
 * @returns 'call' when RAP user and 'CALL AAA' when member
 */
export const selectCallText = createSelector(
  selectAuthState,
  (state: AuthState): string => state?.modeConfig ? CALL_RAP() : CALL_DRR()
)

export const selectIsRapUser = createSelector(
  selectModeConfiguration,
  (config: VendorConfigurationMode): boolean => !!config
)

export const selectMobileHeaderText = createSelector(
  selectIsRapUser,
  (isRapUser: boolean): string => isRapUser ? MOBILE_HEADER_RAP() : MOBILE_HEADER_DRR())

export const selectAuthErrorCount = createSelector(
  selectAuthState,
  (state: AuthState): number => state.errorCount
)

export const selectIsUseAnotherFormClickedAfterError = createSelector(
  selectAuthState,
  (state: AuthState): boolean => state.isUseAnotherFormClickedAfterError
)

export const selectLastSearchId = createSelector(
  selectAuthState,
  (state: AuthState): string => state.lastSearchId
)
