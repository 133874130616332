<div
  class="letters-section"
  [hidden]="isHideAlphabet || dividers.length === 0"
  (wheel)="$event.preventDefault()"
  (touchmove)="$event.preventDefault()"
>
  <ul class="letter-sidebar" [ngClass]="{'sticky': shouldStick}" #bar>
    <li *ngFor="let letter of letters" (click)="scrollToLetter(letter)" tappable>
      <a>{{ letter }}</a>
    </li>
  </ul>
</div>

<ion-list #itemsList mode="ios" role="radiogroup">
  <ng-container *ngFor="let divider of (!isLoading ? dividers : loadingDividers)">
    <ion-item-group>
      <ion-item-divider [id]="'letter-divider-' + divider.key" class="letter-divider" sticky>
        <aaa-text weight="semibold">{{ divider.key }}</aaa-text>
      </ion-item-divider>
      <ion-list role="radiogroup">
        @if (!isLoading) {
          <ion-radio-group mode="ios" [value]="selected">
            <ion-item *ngFor="let item of divider.items" (click)="selectItem(item)" class="selectable-item" detail>
              <ion-radio
                color="drr-primary"
                justify="start"
                [value]="item"
                [disabled]="isLoadingSelection"
              >
                <ion-row class="ion-align-items-center">
                  <ion-col>
                    <aaa-text color="dark" size="subheadline">{{ item }}</aaa-text>
                  </ion-col>
                  <ion-col class="ion-margin-start" [hidden]="!loading(item)">
                    <ion-spinner></ion-spinner>
                  </ion-col>
                </ion-row>
              </ion-radio>
            </ion-item>
          </ion-radio-group>
        } @else {
          <ion-item *ngFor="let index of [].constructor(5); let i = index" button>
            <ion-skeleton-text [animated]="true"></ion-skeleton-text>
          </ion-item>
        }
      </ion-list>
    </ion-item-group>
  </ng-container>
</ion-list>

