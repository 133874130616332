import { Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation, } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { select, Store } from '@ngrx/store'

import { delay, filter, map, take, withLatestFrom, } from 'rxjs/operators'
import { Observable, ReplaySubject } from 'rxjs'
import { Event, NavigationStart, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'

import { selectSplashscreenIsVisible, selectSplashscreenStep, } from '../modules/ui/ui.selectors'
import { selectUrl } from '../store/router.selectors'
import { DEFAULT_CONTAINER_ID_PER_PAGE } from '../shared/types'
import { AAAStore } from '../store/root-reducer'
import { animateSplashscreenHide, setCurrentUrlAndTitle, showSplashscreen, } from '../modules/ui/ui.actions'
import { EVENT_TYPES } from '../modules/tagging/tagging.types'
import { ConfigService } from '../modules/config/config.service'
import { RouteTypes } from '../modules/main-router.module'
import { AbstractComponent } from '../shared/abstract.component';
import { configureKeyboard } from '../shared/utils/capacitorAccessoryBar'

declare let dT_

const _loadedLibraries: { [url: string]: ReplaySubject<any> } = {}

@Component({
  selector: 'app-drrweb-main',
  templateUrl: './drrweb-main.component.html',
  styleUrls: ['./drrweb-main.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DrrWebMainComponent extends AbstractComponent implements OnInit {
  targetElementId = DEFAULT_CONTAINER_ID_PER_PAGE.auth
  currentPage$ = this.store$.pipe(
    select(selectUrl),
    filter((page) => Boolean(page))
  )

  splashscreenIsHiddenOnBootstrap = this.store$.pipe(
    select(selectSplashscreenIsVisible),
    take(1),
    map((visible) => !visible),
    withLatestFrom(this.store$.pipe(select(selectUrl)))
  )

  isSplashscreenHidden$ = this.store$.pipe(
    select(selectSplashscreenIsVisible),
    map((visible) => !visible)
  )

  step$ = this.store$.pipe(delay(10), select(selectSplashscreenStep))

  private _selector = 'meaningful-paint'

  constructor(
    http: HttpClient,
    private store$: Store<AAAStore>,
    private router: Router,
    private titleService: Title,
    protected configService: ConfigService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    super()
    if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
      dT_.initAngularNg(http, HttpHeaders)
    }
  }

  navigationStartEvent$: Observable<[Event, string]> = this.router.events.pipe(
    filter((e) => e instanceof NavigationStart),
    withLatestFrom(this.store$.select(selectUrl)),
  )

  ngOnInit() {
    configureKeyboard(true)
    const loggerLibrary =
      'assets/drrweb-lib/js/logger' +
      (this.configService.getConfig().clientId.endsWith('prod') ? '.prod.js' : '.js')
    this.subscriptions.push(
      this.splashscreenIsHiddenOnBootstrap.subscribe(
        ([hidden, url]: [boolean, string]) => {
          this.hideMeaningfulPaint()
          if (hidden) {
            this.store$.dispatch(showSplashscreen())
            if (!url || !url.startsWith(`/${RouteTypes.SIGNIN}`)) {
              this.store$.dispatch(animateSplashscreenHide())
            }
          }
        }
      ),
      this.navigationStartEvent$.subscribe((event: [NavigationStart, string]) => {
        this.store$.dispatch(
          setCurrentUrlAndTitle({
            payload: {
              url: event[0].url,
              previousUrl: event[1],
              title: this.titleService.getTitle()
            },
          })
        )
      }),
      this.loadScript(
        'drr-zipcode-services',
        'https://www.aaa.com/jsincludes/zipcodeServices.js'
      ).subscribe(() => {
        ;(<any>window).AAA_NA_DL = {
          eventType: EVENT_TYPES.DRR,
          category: 'Automotive',
          appId: 'DRR',
          subCategory: EVENT_TYPES.DRR,
        } as any
        ;(<any>window).zs.setZip2()
        console.log('zipcodeServices.js loaded')
      }),
      this.currentPage$.subscribe((page) => this.handleTargetElementId(page))
    )
    if (!this.configService.getConfig().nativeAppView) {
      this.subscriptions.push(
        this.loadScript('drr-logger-library', loggerLibrary).subscribe(() => {
          console.log(loggerLibrary + ' loaded')
        })
      )
    }
  }

  private handleTargetElementId(path) {
    // get the first part of the url
    const basePagePath = path
      .replace(/[^a-zA-Z0-9 ]/g, ' ')
      .split(' ')
      .filter((element) => Boolean(element))[0]

    this.targetElementId =
      DEFAULT_CONTAINER_ID_PER_PAGE[basePagePath] ||
      DEFAULT_CONTAINER_ID_PER_PAGE.auth
  }

  private hideMeaningfulPaint() {
    if (this.platformId === 'browser') {
      const elements = document.getElementById(this._selector)
      if (elements) {
        setTimeout(() => {
          elements.style['display'] = 'none'
        }, 200)
      }
    }
  }

  private loadScript(id: string, url: string): Observable<any> {
    if (_loadedLibraries[url]) {
      return _loadedLibraries[url].asObservable()
    }

    _loadedLibraries[url] = new ReplaySubject()

    const existingElement = document.getElementById(id)
    if (existingElement) {
      return _loadedLibraries[url].asObservable()
    }

    const script = window.document.createElement('script')
    script.id = id
    script.type = 'text/javascript'
    script.async = true
    script.src = url
    script.onload = () => {
      _loadedLibraries[url].next()
      _loadedLibraries[url].complete()
    }

    window.document.body.appendChild(script)

    return _loadedLibraries[url].asObservable()
  }
}
