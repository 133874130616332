<div class="issue-button-container">
  <ng-container *ngFor="let problem of problems; let index = index;">
    <app-issue-button [tabIndex]="102 + index"
      class="what-happened-redesign_button what-happened-redesign_primary-button"
      [first]="index === 0"
      [problemType]="problem"
      [overrideProblem]="shouldOverrideProblem(problem)"
      (clickProblem)="problemSelected($event)">
    </app-issue-button>
  </ng-container>
</div>
