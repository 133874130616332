import { Dialog } from '../../../../modules/ui/ui.types'

export enum DRRNotificationDialogTypes {
  CALL_COMPLETED = 'CALL_COMPLETED',
  CALL_CANCELLED = 'CALL_CANCELLED',
}

export type DRRNotificationTypes = DRRNotificationDialogTypes // Add more types as needed

export interface DRRNotification {
  type: DRRNotificationTypes
  title: string
  message: string
  submit?: {
    label: string
    ariaLabel: string
    href: string
    action: Function
  }
  close: Function
}

export interface NotificationMap {
  [key: string]: (data: Dialog) => DRRNotification
}
