<ion-list mode="ios" role="radiogroup">
  @if (!isLoading()) {
    <ion-radio-group [value]="vehicleYear()">
      @for (year of years(); track year) {
        <ion-item detail>
          <ion-radio
            class="year-label"
            justify="start"
            [value]="year"
            color="drr-primary"
            (click)="selectYear(year)"
            [attr.aria-label]="vehicleYearLabel + ' ' + year"
            [title]="vehicleYearLabel + ' ' + year"
            [tabIndex]="tabIndexPosition() + $index"
            [ngClass]="{ 'first-focusable-element': $index === 0 }"
          >
            <aaa-text color="dark">{{ year }}</aaa-text>
          </ion-radio>
        </ion-item>
      }
    </ion-radio-group>
  }
  @else {
    @for (loadingYear of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]; track loadingYear) {
      <div class="loading-year"></div>
    }
  }
</ion-list>
