@if (highwayExit$ | async; as highwayExit) {
<div class="padding-4">
  <ion-row>
    <ion-col size="auto" class="padding-4" size="12">
      <ion-text color="dark" class="title" i18n>Highway Exit</ion-text>
    </ion-col>
  </ion-row>
  <ion-row class="ion-align-items-center">
    <ion-col size="auto" class="padding-4">
      <aaa-icon name="road-sign" [size]="iconSize" [color]="iconColor"></aaa-icon>
    </ion-col>
    <ion-col class="padding-4">
      <ion-row>
        <ion-col>
          <ion-label id="highway-label" class="address" color="medium">{{ highwayExit }} </ion-label>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</div>
}