import { haversine } from '../../../shared/haversine'
import { GenericCoordinates, GoogleLocationMarker, TowLocation } from '../location.types'
import { DestinationType } from '../../../shared/types';
import { MarkerTypes } from '../../ui/ui.types';

export function calculateTowingDistance(
  breakdownLocation: GenericCoordinates,
  towLocation: GoogleLocationMarker,
): number {
  const validCoordinates = breakdownLocation?.latitude && breakdownLocation?.longitude && towLocation?.lat && towLocation?.lng;
  if (!validCoordinates) {
    throw new Error('Invalid coordinates')
  }

  const start = {
    latitude: Number(breakdownLocation.latitude),
    longitude: Number(breakdownLocation.longitude),
  }
  const end = {
    latitude: towLocation.lat,
    longitude: towLocation.lng,
  }
  return haversine(start, end, { unit: 'mile' })
}

export function getMarkerType(towLocation: TowLocation, destinationType: DestinationType): MarkerTypes {
  switch (destinationType) {
    case DestinationType.EV_STATION:
      return MarkerTypes.EV_STATION
    case DestinationType.DEALER:
      return MarkerTypes.AAA_APPROVED // TODO decide PIN we will use for RAP / Dealerships
    default:
      return towLocation.isCOR ? MarkerTypes.AAA_OWNED : MarkerTypes.AAA_APPROVED
  }
}
