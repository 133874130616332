import { Component, Input } from '@angular/core';
import { Size } from '@aaa-mobile/shared-components/lib/icon/icon.interfaces';
import { Color } from '@aaa-mobile/shared-components/interface';
import { IonCol, IonLabel, IonRow, IonText } from "@ionic/angular/standalone";
import { IconComponent } from '@aaa-mobile/shared-components';
import { AAAStore } from '../../../../store/root-reducer';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectHighwayExit } from '../../../location/location.selectors';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'drr-location-highway-exit',
  templateUrl: './location-highway-exit.component.html',
  styleUrls: ['./location-highway-exit.component.scss'],
  standalone: true,
  imports: [
    IonRow,
    IonCol,
    IonText,
    IonCol,
    IonLabel,
    IconComponent,
    AsyncPipe
  ]
})
export class DrrLocationHighwayExit {
  @Input()
  iconSize: Size = 'small'
  @Input()
  iconColor: Color = 'medium'

  highwayExit$: Observable<string> = this.store$.pipe(select(selectHighwayExit))

  constructor(private store$: Store<AAAStore>) { }

}
