import { createFeatureSelector, createSelector, Selector } from '@ngrx/store'

import { AAAStore } from '../../store/root-reducer'
import {
  CdxAuthorizationLevel,
  MemberInfo,
  MembershipLevelsSettings,
  MemberStatusCodes,
  Vehicle,
  VehicleData,
} from './member.types'
import { MemberState } from './member.reducer'

const stateKey = 'member'

export const selectMemberState = createFeatureSelector<MemberState>(stateKey)

export const selectMemberData = createSelector(
  selectMemberState,
  (state: MemberState): MemberInfo => state.data
)

export const selectMemberVehicles = createSelector(
  selectMemberState,
  (state: MemberState): Array<Vehicle> => state.vehicles?.data || []
)

export const selectIsEligible = createSelector(
  selectMemberData,
  (memberData: MemberInfo): boolean =>
    Boolean(
      memberData?.eligible &&
        (memberData.statusCode === MemberStatusCodes.ACTIVE ||
          memberData.statusCode === MemberStatusCodes.PENDING)
    )
)

export const selectMemberActiveVehicle = createSelector(
  selectMemberState,
  (state: MemberState): Vehicle => state.activeVehicle
)

export const selectMemberActiveVehicleNotes: Selector<AAAStore, string> =
  createSelector(
    selectMemberActiveVehicle,
    (activeVehicle: VehicleData) => activeVehicle?.notes
  )

export const selectSearchMembersResults = createSelector(
  selectMemberState,
  (data: MemberState) => {
    if (!data.searchResults) {
      return null
    }

    return {
      members: data.searchResults.members,
      searchId: data.searchResults.searchId,
    }
  }
)

export const selectIsMemberVehicleValid = createSelector(
  selectMemberActiveVehicle,
  (activeVehicle: VehicleData) =>
    activeVehicle !== null &&
    Boolean(activeVehicle.year) &&
    Boolean(activeVehicle.make) &&
    Boolean(activeVehicle.model)
)

export const selectHasMemberData = createSelector(
  selectMemberState,
  (state: MemberState): boolean => Boolean(state.data)
)

export const selectMemberShipLevel = createSelector(
  selectMemberState,
  (state: MemberState): MembershipLevelsSettings => state.data?.membershipLevel
)

export const selectMembershipNumber = createSelector(
  selectMemberState,
  (state: MemberState): string => state.data?.fullMembershipNumber
)

export const selectIsMotorcycleEligible = createSelector(
  selectMemberData,
  (memberData: MemberInfo): boolean =>
    Boolean(
      [CdxAuthorizationLevel.V, CdxAuthorizationLevel.R]
        .includes(CdxAuthorizationLevel[memberData?.cdxAuthorizationLevel]) ||
      (memberData?.motorcycleCoverage?.toUpperCase() === 'Y')
    )
)

export const selectIsMotorhomeEligible = createSelector(
  selectMemberData,
  (memberData: MemberInfo): boolean =>
    Boolean(
      [MembershipLevelsSettings.RV.toLowerCase(), MembershipLevelsSettings.PREMIERRV.toLowerCase()]
        .includes(memberData?.membershipLevel?.toLowerCase()?.replace(' ', ''))
    )
)
