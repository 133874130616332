import { RouterNavigatedAction, RouterNavigationAction, RouterRequestAction } from '@ngrx/router-store'
import { AgentAppIds } from '../auth/auth.types';
import { getCookie } from '../../shared/utils/cookies';

export const getParamsFromEvents = (
  action: RouterRequestAction | RouterNavigatedAction | RouterNavigationAction,
  query
) => {
  const nextStepUrl =
    new RegExp(`${query}=([\\w-]+)`).exec(action.payload.event.url) || []
  return nextStepUrl[1]
}

export const isAgentAppId = () => Object.keys(AgentAppIds).includes(getCookie('AAA_AppId'))

export function unmaskNumericField(text: string): string {
  return text.replace(/\D/g, '');
}